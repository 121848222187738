import styled from "styled-components";
import { Grid, Paper, Typography, Box } from "@mui/material";

export const TableClients = styled(Paper)`
  width: 100%;
  height: auto;
  overflow: hidden;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 2% 2% 0% 2%;
  }
`;

export const GridBtn = styled(Grid)`
  display: flex;
  justify-content: end;

  .buttonAction360 {
    width: 20%;
    height: 15vh;
    align-items: flex-end;
  }
`;

export const TypographyHeader = styled(Typography)`
  color: #035533;
  font-weight: bold;
  margin: 2% 2% 0% 2%;
`;

export const InputHidden = styled.input`
  opacity: 0;
`;

export const ModalActions = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: auto;
  padding-bottom: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 2% 2% 0% 2%;
  }

  @media (max-width: 1300px) {
    width: 40%;
  }
  @media (max-width: 900px) {
    width: 60%;
  }
  @media (max-width: 600px) {
    width: 80%;
  }

`;
