import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

import { Chart as ChartJS, BarElement } from 'chart.js';
import { useAuthContext } from '../../../Contexts/AuthContext';

export default function DiagramBarsAdminEstablishment() {
  const [salesBranch, setSalesBranch] = useState([])
  const { token } = useAuthContext();

  const sales = salesBranch.map(item => item.total_ventas);
  const establecimiento = salesBranch.map(item => item.establecimiento);

  const tokenDec = localStorage.getItem('token');
  const decodedToken = jwtDecode(tokenDec);

  const ResultsSales = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/totalPurchaseByEstablishmentBranch/${decodedToken.NIT}`, {
        headers: {
          authorization: token,
        },
      }
      );
      setSalesBranch(response.data);
    } catch (error) { 
    }
  };

  useEffect(() => {
    ResultsSales()
  }, [])

  const misoptions = {
    responsive: true,
    animation: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {ticks: { color: '#013f58' }},
      x: {ticks: { color: '#013f58' }}
    }
  };

  const midata = {
    labels: establecimiento,
    datasets: [
      {
        label: 'Ingreso diario',
        data: sales,
        backgroundColor: [
          '#0e7a4d',
          '#32faa5',],
        borderColor: [
          '#0e7a4d',
          '#32faa5',
        ]
      }
    ]
  };

  return <Bar data={midata} options={misoptions} />
}

ChartJS.register(BarElement);


