import styled from "styled-components";
import FondoAdmin from "../../../Assets/images/fondos svg/FondoAdmin.svg";
import { Grid } from "@mui/material";

export const ContLogout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 20rem;
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem;
  opacity: 0;
  transition: all .2s ease-in-out;
  * {
    color: #035533;
  }
  svg {
    font-size: 3rem;
  }
  .button {
    width: 100%;
    padding: 1rem 0;
    border-radius: 5px;
    font-size: 1.2rem;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .Confirm {
    background-color: #035533;
    color: #fff;
  }
  .Cancel {
    background: #fff;
    border: 1px solid #035533;
  }
`;

export const FondoLogout = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 100%;
  height: 0;
  transition: all 0.2 ease-in-out;
  background-color: #0000009b;
`;

export const MainDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  margin-top: -2.5rem;
  justify-content: center;
  transition: all .2s ease-in-out;
  overflow: hidden;
  
  ${FondoLogout} {
    transition: all .2s ease-in-out;
    height: ${({ $actLogout }) => $actLogout ? '100%' : 0};
    ${ContLogout} {
      display: ${({ $actLogout }) => $actLogout ? "flex" : "none"};
      opacity: ${({ $actLogout }) => $actLogout ? 1 : 0};
    }
  }

  @media (max-width: 1400px) {
    margin-top: -1.5%;
  }
  @media (max-width: 950px) {
    margin-top: -1.8%;
  }
  @media (max-width: 660px) {
    margin-top: -2.6%;
  }
  @media (max-width: 470px) {
    margin-top: -3.2%;
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: 100vh;
  

  @media (max-width: 900px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  height: 13vh;
  background-color: #035533;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  border-radius: 0rem 0rem 1.5rem 1.5rem;

  .button:hover {
    background-color: #035533;
  }

@media (max-width: 768px) {
    width: 100% !important;
    height: 10%;
    border-radius: 0rem 0rem 1.5rem 1.5rem;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 10%;
    border-radius: 0rem 0rem 0rem 0rem;
  }
`;

export const BodyHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2%;

  .icon {
    font-size: 35px;
    color: #ffffff;
  }
  .Logout {
    outline: none;
    svg {
      color: #fff;
      font-size: 2rem;
    }
      
  }
`;

export const ContainerInfo = styled.div`
  width: 100%;
  height: 32vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${FondoAdmin});
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 105.5%;

  @media (max-width: 769px) {
    width: 100%;
    border-radius: 0rem 0rem 1rem 1rem;
    height: 24vh;
    background-image: url(${FondoAdmin});
    background-size: 110%;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 105.5%;
  }

  @media (max-width: 600px) {
    width: 100%;
    border-radius: 0rem 0rem 1rem 1rem;
    height: 20vh;
    background-image: url(${FondoAdmin});
    background-size: 110%;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 105.5%;
  }

  @media (max-width: 414px) {
    width: 100%;
    border-radius: 0rem 0rem 1rem 1rem;
    height: 160px;
    background-image: url(${FondoAdmin});
    background-size: 110%;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 105.5%;
  }
`;

export const ModuleStore = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;

  .containerModuleStore {
    display: flex;
    flex-direction: column !important;
  }

  @media (max-width: 414px) {
    width: 100%;
  }
`;

export const GridContainerValidation = styled(Grid)`
  margin-top: 25rem;

  @media (max-width: 1496px) and (max-height: 716px) {
    margin-top: 18rem;
  }
`;


export const DivTransactions = styled.div`
  width: 100%;
`;
