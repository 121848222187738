import styled from "styled-components";
import Typography from "@mui/material/Typography";

export const StyledStartLink = styled.a`
  color: #035533;
  font-size: 30px;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    background-color: #035533;
    color: white;
  }
`;

export const StyledTittle = styled(Typography)`
  color: rgba(255, 255, 255, 255);
  text-align: center;
  font-weight: 500 !important;

  @media (max-width: 414px) {
    display: "flex";
   justify-content: center;
  }
`;

export const StyledTypography = styled(Typography)`
  color: #035533;
  text-align: center;
  font-size: 15px;
`;

export const StyledTypographyTitle = styled(Typography)`
  color: #035533;
  text-align: center;
  font-weight: 500 !important;
`;
