import React from 'react';
import { Grid, CssBaseline} from '@mui/material'
import { GridBody, TittleModule } from '../../registrationEstablishment/Styled';
import { GridHeaderII } from '../../moduleDashboard/Styled';
import ProfileMenu from '../../../../Components/menu/Profile';
import { GridCardTable } from '../Styled';
import ListCategories from './tables/ListCategories';
import { createTheme, ThemeProvider } from "@mui/material/styles";


export default function CategoryProduct() {

  return (
    <ThemeProvider theme={createTheme}>
      <CssBaseline />
      <GridBody >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <GridHeaderII container item xl={12} mt={-7} mb={2}>
            <TittleModule >Registro de categorias</TittleModule>
            <ProfileMenu />
          </GridHeaderII>
          <GridCardTable container item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ListCategories />
          </GridCardTable>
        </Grid>
      </GridBody>
    </ThemeProvider>
  );
}
