import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

import { Grid, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import PublicIcon from '@mui/icons-material/Public';
import CardActions from '@mui/material/CardActions';
import { BodyCard, CardPublic, HeaderCard } from './Styled';
import { useAuthContext } from "../../../Contexts/AuthContext";
import { NumericFormatCustom } from '../../../Utilities/NumericFormat';
import { LineDivider } from '../../../components/elements/lines/Styled';
import { StyledInputText2 } from '../../../components/elements/Inputs/Styled';
import ModalUpdatePublication from '../../admin/modules/offertPublication/modals/UpdatePublication';
import MultipleSelect from '../../../components/elements/ComboBox/multiSelect';
import ComboBox360 from '../../../components/elements/ComboBox/comboBox360';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function CardOffertPublication({ card, idPubli, disabled }) {
  const { id } = card;
  const { token } = useAuthContext();
  const [discount, setDiscount] = useState(card.discount);
  const [file, setFile] = useState(card.image);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(card.image);
  const [Price, setPrice] = useState({ numberformat: card.price });
  const [Description, setDescription] = useState(card.description);
  const [inputEnabled, /* setInputEnabled */] = useState(disabled)
  const [selectedPublicationId, setSelectedPublicationId] = useState(null);
  const [categories, setCategories] = useState(null);

  const [subcategories, setSubcategories] = useState([]);
  const tokenDec = localStorage.getItem('token');
  const decodedToken = jwtDecode(tokenDec);

  const handleViewModal = (publicationId) => {
    setSelectedPublicationId(publicationId);
    setIsModalOpen(true);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setFile(file);
    }
  };

  const handleSubcategoriesChange = (selectedSubcategories) => {
    setSubcategories(selectedSubcategories);
  };

  const formData = new FormData();
  formData.append("image", file);
  formData.append("promotion_name", Description);
  formData.append("promotion_price", Price.undefined);
  formData.append("establishment_id", decodedToken.establishment_id);
  formData.append("discount", discount);
  formData.append("subcategory_id", subcategories);

  const CreatePublication = async (event, card) => {
    event.preventDefault();
    if (file && Price.undefined && Description) {
      try {
        await axios.put(`${process.env.REACT_APP_API_URL}/updatePromotion/${card.id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "authorization": token,
          },
        })
        Swal.fire({
          icon: "success",
          title: "Publicación exitosa",
          text: "Se creo exitosamente tu nueva publicidad!",
          confirmButtonColor: '#035533',
        })
        window.localStorage.setItem("setResetPublication", true);
        window.location.reload()
        setTimeout(() => {
          window.localStorage.removeItem("setResetPublication", false);
        }, 12000)
      } catch (error) { }
    } else {

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ocurrió un error al intentar crear la publicidad!",
        confirmButtonColor: '#035533',
      });

    }
  }

  const DeletePublication = async () => {
    Swal.fire({
      title: "¿Quieres eliminar la publicación?",
      text: "Esta acción no se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#035533",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${process.env.REACT_APP_API_URL}/deletePromotion/${card.id}`, {
            headers: {
              "authorization": token,
            },
          });

          Swal.fire({
            title: "¡Publicación eliminada!",
            text: "Publicación elimnada correctamente.",
            icon: "success",
            confirmButton: "#035533",
          });
          window.localStorage.setItem("setResetPublication", true);
          window.location.reload()
          setTimeout(() => {
            window.localStorage.removeItem("setResetPublication", false);
          }, 2000)
        } catch (error) {
        }
      }
    });
  };

  return (
    <CardPublic>
      <HeaderCard
        title={
          <Grid item className='GridHeader' xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className='TypographyHeader'>Publicidad {idPubli + 1}</Typography>
            <LineDivider color="#035533" width="96%" />
          </Grid>
        }
        action={
          <IconButton aria-label="settings" onClick={DeletePublication}>
            <DeleteIcon className='Icon' />
          </IconButton>
        }
      />
      <form onSubmit={CreatePublication}>
        <BodyCard>
          <Grid className='Grid'>
            <label htmlFor={`imageInput-${id}`} className='containerLabel'>
              {selectedImage ? (
                <img src={selectedImage} alt="Selected" className='img' />
              ) : card.src ? (
                <img src={`${process.env.REACT_APP_API_URL}/${card.src}`} alt="Selected" className='img' />
              ) :
                <ImageIcon className='icon' />
              }
              <VisuallyHiddenInput id={`imageInput-${id}`} type="file" onChange={handleImageChange} disabled={inputEnabled} />
            </label>
          </Grid>
          <Grid container item xs={12} sm={8.8} md={8.8} lg={8.8} xl={8.8} gap={1} className='containerFormPublication' mt={1}>
            <Grid container item sx={{ display: "flex", justifyContent: "space-between" }}>
              <Grid width={"40%"}>
                <ComboBox360 label="Categorias" selectedValue={categories} setSelectedValue={setCategories} disabled={inputEnabled} />
              </Grid>
              <Grid width={"55%"} >
                <MultipleSelect label={'Subcategorias'} onChange={handleSubcategoriesChange} value={subcategories} idCategory={categories}  />
              </Grid>
            </Grid>

            <Grid width={"100%"}>
              <StyledInputText2 required={true} disabled={inputEnabled} size='small' label="Nombre producto o servicio" value={Description} onChange={(e) => setDescription(e.target.value)} />
            </Grid>

            <Grid container item sx={{ display: "flex", justifyContent: "space-between" }}>
              <Grid width={"55%"}>
                <StyledInputText2 required disabled={inputEnabled} size='small' label="Precio" value={Price.numberformat} onChange={(e) => setPrice({ ...Price, [e.target.name]: e.target.value })} id="formatted-numberformat-input"
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                />
              </Grid>
              <Grid width={"40%"}>
                <StyledInputText2 required disabled={inputEnabled} size='small' label="Descuento" value={discount || ''} onChange={(e) => setDiscount(e.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </BodyCard>

        <CardActions>
          {card.src || card.description || card.price ? (
            <IconButton aria-label="Edit Publication" onClick={() => { handleViewModal(card.id) }}>
              <EditIcon />
            </IconButton>
          ) : (
            <IconButton aria-label="add to favorites" type="submit" value="postPublication" onClick={(event) => CreatePublication(event, card)}>
              <PublicIcon />
            </IconButton>
          )}
        </CardActions>
      </form>

      {selectedPublicationId !== null && (
        <ModalUpdatePublication isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedPublicationId={selectedPublicationId} initialData={{ image: selectedImage, price: card.price, description: card.description, discount: card.discount }} />
      )}
    </CardPublic>
  );
}
