import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import Select from '@mui/material/Select';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { StyleMultipleSelect } from './StyleComboBox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedValues, theme) {
  return {
    fontWeight:
      selectedValues.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function MultipleSelect({ label, onChange, value, idCategory, disabled }) {
  const { token, role } = useAuthContext();
  const [subcategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const getSubCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getSubcategoriesForCategory/${idCategory}`, {
          headers: {
            authorization: token,
          },
        });
        setSubCategories(response.data);
      } catch (error) {
        console.error('Error fetching subcategories:', error);
      }
    };

    const getCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCategories/${role}`, {
          headers: {
            authorization: token,
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    if (label === "Subcategorias") {
      getSubCategories();
    }
    if (label === "Categorias") {
      getCategories();
    }
  }, [label, token, idCategory]);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(value);
  };

  const options = label === "Categorias" ? categories : subcategories;
  const optionKey = label === "Categorias" ? 'category_id' : 'subcategory_id';
  const optionName = label === "Categorias" ? 'category_name' : 'subcategory_name';

  return (
    <div>
      <FormControl sx={{ width: '100%' }} size="small">
        <InputLabel id="demo-multiple-name-label" sx={{ marginBottom: 2 }}>{label}</InputLabel>
        <StyleMultipleSelect
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={value}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
          onChange={handleSelectChange}
          disabled={disabled}
        >
          {options.map((option) => (
            <MenuItem
              key={option[optionKey]}
              value={option[optionKey]}
              style={getStyles(option[optionName], value, theme)}
            >
              {option[optionName]}
            </MenuItem>
          ))}
        </StyleMultipleSelect>
      </FormControl>
    </div>
  );
}
