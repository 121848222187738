
import React, { useEffect, useState } from 'react';
import validator from 'validator';
import Swal from 'sweetalert2'
import axios from 'axios';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { GridHeaderII } from '../moduleDashboard/Styled';
import ProfileMenu from '../../../Components/menu/Profile';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAuthContext } from '../../../../Contexts/AuthContext';
import { CssBaseline, Grid, Box, Typography, Tooltip } from "@mui/material";
import { LineDivider } from '../../../../components/elements/lines/Styled';
import ComboBox360 from '../../../../components/elements/ComboBox/comboBox360';
import { StyledInputText } from '../../../../components/elements/Inputs/Styled';
import ButtonActiosn360 from '../../../../components/elements/Buttons/ButtonActions360';
import { CardBody, CardBody2, CardBody3, GridBody, GridBtn, TittleModule } from './Styled';
import MultipleSelect from '../../../../components/elements/ComboBox/multiSelect';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
export default function RegistrationEstablishment({ establishmentId, selectedEstablishment, statusModal, showRegistrationComponent, headerVisibility }) {
  const { token } = useAuthContext();
  const [file, setFile] = useState();
  const [errors, setErrors] = useState({});
  const [/* open */, setOpen] = useState(false);
  const [/* selectedImage */, setSelectedImage] = useState("");
  const [selectedCiudad, setSelectedCiudad] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [ubicacion, setUbicacion] = useState({ domicilio: "" });
  const [inputEnabled, /* setInputEnabled */] = useState(false);
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [categories, setCategories] = useState([]);
  const [establishment, setEstablishment] = useState({
    DV: "",
    NIT: "",
    business_name: "",
    email: "",
    phone_number: "",
    additional_info: "",
    contact_position: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    url: ""
  });
  const [direccion, setDireccion] = useState({
    tipoVia: "",
    primerNumeroVia: "",
    segundoNumeroVia: "",
    tercerNumeroVia: "",
  });
  const type = "establecimiento"

  const validateForm = () => {
    const errors = {};
    if (!/^\d+$/.test(establishment.DV)) {
      errors.DV = 'Dv. reque..';
    }
    if (!establishment.email || !validator.isEmail(establishment.email)) {
      errors.email = 'El Correo no es válido.';
    }

    if (!establishment.contact_email || !validator.isEmail(establishment.contact_email)) {
      errors.contact_email = 'El Correo no es válido.';
    }
    if (!selectedDepartamento) {
      errors.selectedDepartamento = 'El campo Departamento es requerido.';
    }
    if (!selectedCiudad) {
      errors.selectedCiudad = 'El campo Ciudad es requerido.';
    }
    if (!direccion.tipoVia || !direccion.tipoVia.trim()) {
      errors.direccionTipoVia = 'Campo requerido.';
    }
    if (!direccion.primerNumeroVia || !direccion.primerNumeroVia.trim()) {
      errors.direccionPrimerNumeroVia = 'Campo requerido.';
    }
    if (!direccion.segundoNumeroVia || !direccion.segundoNumeroVia.trim()) {
      errors.direccionSegundoNumeroVia = 'Campo requerido.';
    }
    if (!direccion.tercerNumeroVia || !direccion.tercerNumeroVia.trim()) {
      errors.direccionTercerNumeroVia = 'Campo requerido.';
    }

    const stringFields = ['business_name', 'contact_position', 'contact_name', 'additional_info', 'url'];
    stringFields.forEach((field) => {
      if (!establishment[field] || !establishment[field].trim()) {
        errors[field] = `Campo requerido.`;
      }
    });

    const numericFields = ['NIT', 'contact_phone', 'phone_number'];
    numericFields.forEach((field) => {
      if (!establishment[field] || !/^\d{1,10}$/.test(establishment[field])) {
        errors[field] = `Lim. 10 digitos`;
      }
    });

    return errors;
  };

  const validateFormUpdate = () => {
    const errors = {};
    if (!establishment.email || !validator.isEmail(establishment.email)) {
      errors.email = 'El Correo no es válido.';
    }
    if (!establishment.contact_email || !validator.isEmail(establishment.contact_email)) {
      errors.contact_email = 'El Correo no es válido.';
    }
    if (!selectedDepartamento) {
      errors.selectedDepartamento = 'El campo Departamento es requerido.';
    }
    if (!selectedCiudad) {
      errors.selectedCiudad = 'El campo Ciudad es requerido.';
    }
    if (!direccion.tipoVia || !direccion.tipoVia.trim()) {
      errors.direccionTipoVia = 'Campo requerido.';
    }
    if (!direccion.primerNumeroVia || !direccion.primerNumeroVia.trim()) {
      errors.direccionPrimerNumeroVia = 'Campo requerido.';
    }
    if (!direccion.segundoNumeroVia || !direccion.segundoNumeroVia.trim()) {
      errors.direccionSegundoNumeroVia = 'Campo requerido.';
    }
    if (!direccion.tercerNumeroVia || !direccion.tercerNumeroVia.trim()) {
      errors.direccionTercerNumeroVia = 'Campo requerido.';
    }

    const stringFields = ['business_name', 'contact_position', 'contact_name', 'additional_info', 'url'];
    stringFields.forEach((field) => {
      if (!establishment[field] || !establishment[field].trim()) {
        errors[field] = `Campo requerido.`;
      }
    });

    const numericFields = ['contact_phone', 'phone_number'];
    numericFields.forEach((field) => {
      if (!establishment[field] || !/^\d{1,10}$/.test(establishment[field])) {
        errors[field] = `Lim. 10 digitos.`;
      }
    });

    return errors;
  };

  const clearEstablishmentFields = () => {
    setEstablishment({
      DV: "",
      NIT: "",
      business_name: "",
      email: "",
      phone_number: "",
      additional_info: "",
      contact_position: "",
      contact_name: "",
      contact_email: "",
      contact_phone: "",
      url: ""
    });

    setDireccion({
      tipoVia: "",
      primerNumeroVia: "",
      segundoNumeroVia: "",
      tercerNumeroVia: "",
    });

    setUbicacion({ domicilio: "" });
    setSelectedDepartamento("");
    setSelectedCiudad(null);
  };

  const updateUbicacion = () => {
    const { tipoVia, primerNumeroVia, segundoNumeroVia, tercerNumeroVia } = direccion;
    const domicilio = `${tipoVia} ${primerNumeroVia} ${segundoNumeroVia} ${tercerNumeroVia}`;
    setUbicacion(domicilio);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setFile(file);
      setSelectedFileName(file.name);
    }
  };

  const handleCategoriesChange = (selectedCategories) => {
    setCategories(selectedCategories);
  };

  const formData = new FormData();
  formData.append("type", type);
  formData.append("NIT", establishment.NIT);
  formData.append("DV", establishment.DV);
  formData.append("business_name", establishment.business_name);
  formData.append("email", establishment.email);
  formData.append("url", establishment.url);
  formData.append("phone_number", establishment.phone_number);
  formData.append("image", file);
  formData.append("department", selectedDepartamento.label);
  formData.append("city", selectedCiudad);
  formData.append("address", ubicacion);
  formData.append("additional_info", establishment.additional_info);
  formData.append("contact_position", establishment.contact_position);
  formData.append("contact_name", establishment.contact_name);
  formData.append("contact_phone", establishment.contact_phone);
  formData.append("contact_email", establishment.contact_email);
  formData.append("category_id", categories);

  const handleCreateEstablishment = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/createEstablishment`, formData, {
          headers: {
            authorization: token,
          },
        });
        setOpen(true);
        setEstablishment(response.data);
        Swal.fire(
          '¡Bien hecho!',
          'Establecimiento creado exitosamente!',
          'success',
        );
        clearEstablishmentFields()
      } catch (error) {
        setOpen(true);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Hubo un error al crear el establecimiento. Verifique los campos y vuelva a intentarlo.',
        confirmButtonColor: '#035533',
      });
      setErrors(validationErrors);
    }
  };

  const handleUpdateEstablishment = async () => {
    const validationErrors = validateFormUpdate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/UpdateEstablishment/${establishmentId}`, formData, {
          headers: {
            authorization: token,
          },
        });
        clearEstablishmentFields();
        Swal.fire(
          'Actualización exitosa!',
          'Establecimiento actualizado exitosamente!',
          'success'
        );
        setOpen(true);
        setEstablishment(response.data);
      } catch (error) {
        setOpen(true);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Hubo un error al actualizar el establecimiento. Verifique los campos y vuelva a intentarlo.',
        confirmButtonColor: '#035533',
      });
      setErrors(validationErrors);
    }
  };

  const calculatorDv = (valor) => {
    let nit = valor.toString();
    nit = nit.replace(/\s/g, ''); // Espacios
    nit = nit.replace(/,/g, ''); // Comas
    nit = nit.replace(/\./g, ''); // Puntos
    nit = nit.replace(/-/g, ''); // Guiones

    if (!Number(nit)) return '';

    let x = 0;
    let i = 0;
    let y = 0;
    const vpri = [1, 3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];
    const z = nit.length;

    for (i; i < z; i += 1) {
      y = Number(nit.substr(i, 1));
      x += y * vpri[z - i];
    }

    y = x % 11;

    let resultado = y > 1 ? 11 - y : y;
    return resultado;
  };

  const handleNITChange = (e) => {
    const nitValue = e.target.value;
    setEstablishment({
      ...establishment,
      NIT: nitValue,
      DV: calculatorDv(nitValue).toString(),
    });
  };

  useEffect(() => {
    if (selectedEstablishment || statusModal === false) {
      setEstablishment(selectedEstablishment);
    }
  }, [selectedEstablishment, statusModal]);

  return (
    <>
      <ThemeProvider theme={createTheme}>
        <CssBaseline />
        <GridBody >
        {headerVisibility !== 'headerVisibility' && (
            <GridHeaderII container xl={12} pb={selectedEstablishment ? 9 : 9} style={{ marginTop: selectedEstablishment ? "-4.5rem" : "-9rem" }}>
              {selectedEstablishment ? <TittleModule >Lista de establecimientos</TittleModule> : <TittleModule >Gestión de establecimientos</TittleModule>}
              <ProfileMenu />
            </GridHeaderII>
          )}


          <CardBody>
            <Grid item className='GridHeader' mt={1}>
              <Typography className='TypographyHeader'>Información general</Typography>
              <LineDivider color="#035533" width="98%" />
            </Grid>
            <Box component="form" noValidate onSubmit={(e) => { e.preventDefault() }} >
              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} mt={0.1}>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={1.2} >
                  <StyledInputText size="small" type="text" disabled={establishmentId ? !inputEnabled : inputEnabled} name="quota" required id="quota" label="NIT"
                    value={establishment.NIT} onChange={handleNITChange} error={errors.NIT} helperText={errors.NIT && errors.NIT} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={0.8} >
                  <StyledInputText size="small" type="number" name="quota" required id="quota" label="DV" value={establishment.DV} onChange={(e) => setEstablishment({ ...establishment, DV: e.target.value })}
                    error={errors.DV ? true : false} disabled helperText={errors.DV ? errors.DV : ''} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2} >
                  <StyledInputText size="small" name="quota" required id="quota" label="Razón social"
                    value={establishment.business_name} onChange={(e) => setEstablishment({ ...establishment, business_name: e.target.value })} error={errors.business_name}
                    helperText={errors.business_name && errors.business_name} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2} >
                  <StyledInputText size="small" name="quota" required id="quota" label="Correo electrónico"
                    value={establishment.email} onChange={(e) => setEstablishment({ ...establishment, email: e.target.value })} error={errors.email ? true : false}
                    helperText={errors.email ? errors.email : ''} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={1.5} >
                  <StyledInputText size="small" type="number" name="quota" required id="quota" label="Teléfono"
                    value={establishment.phone_number} onChange={(e) => setEstablishment({ ...establishment, phone_number: e.target.value })} onBlur={updateUbicacion}
                    error={errors.phone_number} helperText={errors.phone_number && errors.phone_number} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2} >
                  <StyledInputText size="small" name="quota" required id="quota" label="URL / pagina web"
                    value={establishment.url} onChange={(e) => setEstablishment({ ...establishment, url: e.target.value })} onBlur={updateUbicacion}
                    error={errors.url} helperText={errors.url && errors.url} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2} >
                  <MultipleSelect label={'Categorias'} onChange={handleCategoriesChange} value={categories} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2} >
                  <Tooltip title={selectedFileName || "Seleccionar archivo"} arrow>
                    <Button variant="contained" component="label" startIcon={<CloudUploadIcon />} style={{ textTransform: 'none', backgroundColor: '#035533' }}>
                      {selectedFileName ? "Imagen cargada" : "Subir imagen"}
                      <VisuallyHiddenInput type="file" onChange={handleImageChange} />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </CardBody>

          <CardBody2>
            <Grid item className='GridHeader' mt={1}>
              <Typography className='TypographyHeader'>Domicilio</Typography>
              <LineDivider color="#035533" width="98%" />
            </Grid>
            <Box component="form" noValidate onSubmit={(e) => { e.preventDefault() }}>
              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} ml={0.1} spacing={2} mt={0.1}>
                <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.6} >
                  <ComboBox360 label="Departamento" selectedValue={selectedDepartamento} setSelectedValue={setSelectedDepartamento} onBlur={updateUbicacion}
                    error={errors.selectedDepartamento} helperText={errors.selectedDepartamento && errors.selectedDepartamento} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.8} xl={1.5} >
                  <ComboBox360 label="Ciudad" departamento={selectedDepartamento} selectedValue={selectedCiudad} setSelectedValue={setSelectedCiudad} onBlur={updateUbicacion}
                    error={errors.selectedCiudad} helperText={errors.selectedCiudad && errors.selectedCiudad} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.4}>
                  <StyledInputText size='small' name="tipoVia" required id="tipoVia" label="Tipo de vía"
                    value={direccion.tipoVia} onChange={(e) => setDireccion({ ...direccion, tipoVia: e.target.value })} onBlur={updateUbicacion}
                    error={errors.direccionTipoVia} helperText={errors.direccionTipoVia && errors.direccionTipoVia} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.8} xl={1.4}>
                  <StyledInputText size='small' name="primerNumeroVia" required id="primerNumeroVia" label="Primer número de vía"
                    value={direccion.primerNumeroVia} onChange={(e) => setDireccion({ ...direccion, primerNumeroVia: e.target.value })} onBlur={updateUbicacion}
                    error={errors.direccionPrimerNumeroVia} helperText={errors.direccionPrimerNumeroVia && errors.direccionPrimerNumeroVia} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.4}>
                  <StyledInputText size='small' name="segundoNumeroVia" required id="segundoNumeroVia" label="Segundo número de vía"
                    value={direccion.segundoNumeroVia} onChange={(e) => setDireccion({ ...direccion, segundoNumeroVia: e.target.value })} onBlur={updateUbicacion}
                    error={errors.direccionSegundoNumeroVia} helperText={errors.direccionSegundoNumeroVia && errors.direccionSegundoNumeroVia} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.4}>
                  <StyledInputText size='small' name="tercerNumeroVia" required id="tercerNumeroVia" label="Tercer número de vía"
                    value={direccion.tercerNumeroVia} onChange={(e) => setDireccion({ ...direccion, tercerNumeroVia: e.target.value })} onBlur={updateUbicacion}
                    error={errors.direccionTercerNumeroVia} helperText={errors.direccionTercerNumeroVia && errors.direccionTercerNumeroVia} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={2} >
                  <StyledInputText size='small' name="quota" required id="quota" label="Información adicional"
                    value={establishment.additional_info} onChange={(e) => setEstablishment({ ...establishment, additional_info: e.target.value })}
                    error={errors.additional_info} helperText={errors.additional_info && errors.additional_info} />
                </Grid>
              </Grid>
            </Box>
          </CardBody2>

          <CardBody3 >
            <Grid item className='GridHeader' mt={1}>
              <Typography className='TypographyHeader'>Contactos establecimiento</Typography>
              <LineDivider color="#035533" width="98%" />
            </Grid>
            <Box component="form" noValidate onSubmit={(e) => { e.preventDefault() }} mt={1.5}>
              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} ml={0.3} spacing={2}>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2.9} >
                  <StyledInputText size="small" name="quota" required id="quota" label="Cargo" variant="outlined" value={establishment.contact_position}
                    onChange={(e) => setEstablishment({ ...establishment, contact_position: e.target.value })} error={errors.contact_position}
                    helperText={errors.contact_position && errors.contact_position} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2.9} >
                  <StyledInputText size="small" name="quota" required id="quota" label="Nombre completo" variant="outlined" value={establishment.contact_name}
                    onChange={(e) => setEstablishment({ ...establishment, contact_name: e.target.value })} error={errors.contact_name}
                    helperText={errors.contact_name && errors.contact_name} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2.9} >
                  <StyledInputText size="small" type="number" name="quota" required id="quota" label="Número de teléfono" variant="outlined" value={establishment.contact_phone}
                    onChange={(e) => setEstablishment({ ...establishment, contact_phone: e.target.value })} error={errors.contact_phone}
                    helperText={errors.contact_phone && errors.contact_phone} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2.9} >
                  <StyledInputText size="small" name="quota" required id="quota" label="Correo electrónico" variant="outlined" value={establishment.contact_email}
                    onChange={(e) => setEstablishment({ ...establishment, contact_email: e.target.value })} error={errors.contact_email ? true : false}
                    helperText={errors.contact_email ? errors.contact_email : ''} />
                </Grid>
              </Grid>
            </Box>
          </CardBody3>

          <GridBtn>
            <Grid container item xs={12} sm={1.5} md={1.5} lg={1.5} xl={1.5} mt={2}>
              <ButtonActiosn360 label="CREAR" className='buttonAction360' backgroundColor={establishmentId ? "gray" : "#035533"}
                disabled={establishmentId ? true : false} onClick={handleCreateEstablishment} />
            </Grid>
            {establishmentId &&
              <Grid container item xs={12} sm={1.5} md={1.5} lg={1.5} xl={1.5} mt={2}>
                <ButtonActiosn360 label="GUARDAR" className='buttonAction360' backgroundColor={'#035533'} onClick={handleUpdateEstablishment} />
              </Grid>
            }
          </GridBtn>
        </GridBody>
      </ThemeProvider>
    </>
  );
}