// @flow 
import * as React from 'react';
import PurchaseStore from '../store/forms/PurchaseStore';

export const Store = () => {
    return (
        <div>
            <PurchaseStore/>
        </div>
    );
};
