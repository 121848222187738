import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import { ModalActions, ModalEdit } from './Styled';
import { Typography, Modal, Grid } from '@mui/material';
import { useAuthContext } from '../../../../../Contexts/AuthContext';
import { NumericFormatCustom } from '../../../../../Utilities/NumericFormat';
import { LineDivider } from '../../../../../components/elements/lines/Styled';
import { StyledInputText, StyledInputText2 } from '../../../../../components/elements/Inputs/Styled';
import ButtonActiosn360 from '../../../../../components/elements/Buttons/ButtonActions360';


export default function ModalUpdateQuota({ selectedClientId, isModalOpen, setIsModalOpen }) {
  const { token } = useAuthContext();
  const [porcentage, setPorcentage] = useState("");
  const [errors, setErrors] = useState({});
  const [hasTriedToSave, setHasTriedToSave] = useState(false);

  const validateForm = () => {
    const errors = {};
    if (!porcentage || !/^\d+$/.test(porcentage)) {
      errors.porcentage = 'El porcentage es un campo requerido y debe contener solo números.';
    }
    return errors;
  }

  const handleUpdatePorcentage = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const responseUpdate = await axios.put(`${process.env.REACT_APP_API_URL}/updateOneWithdrawal/${selectedClientId}`, {
          porcentage: porcentage,
        }, {
          headers: {
            authorization: token,
          },
        }
        );
        if (responseUpdate.status === 200) {
          setIsModalOpen(false);
          Swal.fire({
            icon: 'success',
            title: '¡¡ Actualización exitosa !!',
            text: "Felicidades, actualizacion de porcentaje de desembolso exitosa!",
            confirmButtonColor: '#035533',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          })
          window.localStorage.setItem("setResetClients", true);
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          Swal.fire({
            icon: 'error',
            title: '¡¡ Error al intentar actualizar porcentaje !!',
            text: "Lo sentimos, hay algun error al intenta actualizar el porcentaje de desembolso al cliente!",
            confirmButtonColor: '#035533',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          })
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: '¡¡ Error al intentar actualizar porcentaje !!',
          text: "Lo sentimos, hay algun error al intenta actualizar el porcentaje de desembolso al cliente!",
          confirmButtonColor: '#035533',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        })
      }
    } else {
      setHasTriedToSave(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    handleUpdatePorcentage()
  }, [])

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <ModalActions className="ModalStyle">
        <Grid item className='GridHeader' xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className='TypographyHeader'>Editar porcentaje de desembolso de crédito</Typography>
          <LineDivider color="#035533" width="96%" />
        </Grid>

        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
          <Grid item xs={11} sm={11} md={11} lg={7} xl={7} m={2}>
            <StyledInputText2
              required
              label="Nuevo porcentaje de retiro del crédito"
              variant="outlined"
              value={porcentage}
              onChange={(e) => setPorcentage(e.target.value)}
              error={errors.porcentage && hasTriedToSave}
              helperText={errors.porcentage && hasTriedToSave ? errors.porcentage : ''}
            />
          </Grid>
          <Grid container item xs={11} sm={11} md={11} lg={3} xl={3} m={2} justifyContent={'center'} >
            <ButtonActiosn360 backgroundColor={'#035533'} label="GUARDAR" onClick={handleUpdatePorcentage} />
          </Grid>
        </Grid>

      </ModalActions>
    </Modal >

  );
}