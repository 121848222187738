import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../../Contexts/AuthContext';
import { PUBLIC } from '../routes/path';
import React, { useEffect } from 'react';

export default function AdminRoute() {
  const { token, role, validate } = useAuthContext();
   useEffect(() => {
     if (token && role) {
       validate(token, role)
     }
   }, [window.localStorage.getItem("role")]);

  if (role === "administrador") {
    if (token && role === "administrador") {
      return <Outlet />
    }
    return <Navigate to={PUBLIC} />;

  } else if (role === "administradorEstablecimiento") {
    if (token && role === "administradorEstablecimiento") {
      return <Outlet />
    }

  } else if (role === "superAdministradorEstablecimiento") {
    if (token && role === "superAdministradorEstablecimiento") {
      return <Outlet />
    }

  } 
  return <Navigate to={PUBLIC} />;
} 