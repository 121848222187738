import React, { useEffect, useRef, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import './Styled';
import axios from 'axios';
import Badge from '@mui/material/Badge';
import { HeaderProfile } from './Styled';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import ModalInfoAdmin from './modals/ModalInfoAdmin';
import SettingsIcon from '@mui/icons-material/Settings';
import ModalInfoCompany from './modals/ModalInfoCompany';
import { useAuthContext } from '../../../Contexts/AuthContext';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DehazeOutlinedIcon from '@mui/icons-material/DehazeOutlined';
import { BottomNavigation, BottomNavigationAction, Box, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import ModalInfoWithdrawal from './modals/ModalWithdrawal';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import ListItemButton from '@mui/material/ListItemButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#035533',
    },
  },
});

export default function ProfileMenu() {
  const [isModalOpenCompany, setIsModalOpenCompany] = useState(false);
  const [isModalOpenWithdrawal, setIsModalOpenWithdrawal] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [isModalOpenAdmin, setIsModalOpenAdmin] = useState(false);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorNotification, setAnchorNotification] = useState(null);
  const [dataNotifications, setDataNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const { token, role, logout } = useAuthContext();
  const decoded = jwtDecode(token);

  const [value, setValue] = useState(0);
  const ref = useRef(null);
  const [messages, setMessages] = useState(() => refreshMessages());

  const handleOpenUserMenu = (event) => {
    setSelectedAdminId(decoded.id);
    setSelectedCompanyId(decoded.id);
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenNotifications = (event) => {
    setSelectedAdminId(decoded.id);
    setSelectedCompanyId(decoded.id);
    setAnchorNotification(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseNotification = () => {
    setAnchorNotification(null);
  };

  const handleMenuItemClick = (item) => {
    if (item.onClick) {
      item.onClick();
    }
    handleCloseUserMenu();
  };

  const handleMenuNotificationItemClick = (item) => {
    if (item.onClick) {
      item.onClick();
    }
    setSelectedNotification(item);
    setIsModalOpenWithdrawal(true);
  };

  const handleLogout = () => {
    logout()
  };

  const menuItems = role === 'administrador' ? [
    {
      label: 'Informacion de compañia',
      icon: <SettingsIcon sx={{ color: '#035533' }} />,
      onClick: () => {
        setSelectedCompanyId(decoded.id);
        setIsModalOpenCompany(true);
      },
    },
    {
      label: 'Credenciales de usuario',
      icon: <AssignmentIndIcon sx={{ color: '#035533' }} />,
      onClick: () => {
        setSelectedAdminId(decoded.id);
        setIsModalOpenAdmin(true);
      },
    },
    {
      label: 'Cerrar sesión',
      icon: <LogoutIcon sx={{ color: '#fa3535' }} />,
      onClick: handleLogout,
    },
  ]
    : [
      {
        label: 'Cerrar sesión',
        icon: <LogoutIcon sx={{ color: '#fa3535' }} />,
        onClick: handleLogout,
      },
    ];

  const notificationsWithdrawals = async ({ status }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getWithdrawalByStatus/${status}`, {
        headers: {
          authorization: token,
        },
      });
      setDataNotifications(response.data)
    } catch (error) {
    }
  }

  function refreshMessages() {
    const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

    return Array.from(new Array(50)).map(
      () => dataNotifications[getRandomInt(dataNotifications.length)],
    );
  }

  useEffect(() => {
    if (ref.current && ref.current.ownerDocument) {
      ref.current.ownerDocument.body.scrollTop = 0;
      setMessages(refreshMessages());
    }
  }, [value, setMessages]);


  useEffect(() => {
    if (role === "administrador") {
      notificationsWithdrawals({ status: 'pendiente' });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <HeaderProfile>
        {role === 'administrador' && (
          <>
            <IconButton onClick={handleOpenNotifications}>

              <Badge badgeContent={dataNotifications.length > 0 && dataNotifications[0].status === "pendiente" && (dataNotifications.length)} color="error">
                <NotificationsIcon className="icon" />
              </Badge>

            </IconButton>
            <Menu sx={{ mt: '45px ', maxHeight: "60vh", maxWidth: "100%" }} id="menu-appbar" keepMounted anchorEl={anchorNotification} open={Boolean(anchorNotification)}
              onClose={handleCloseNotification} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }} >

              <MenuItem>
                <BottomNavigation
                  showLabels
                  value={value}
                  onChange={(event, newValue) => { setValue(newValue) }}
                  sx={{
                    width: dataNotifications.some(item => item.status === 'Exitoso' || item.status === 'Rechazado') ? "40%" : "35%",
                    position: "fixed",
                    overflow: "hidden",
                    zIndex: 1,
                    marginTop: '2rem'
                  }}
                >

                  <BottomNavigationAction sx={{ color: '#008bfd' }} label="Buzón" icon={<RestoreIcon />} onClick={() => notificationsWithdrawals({ status: 'pendiente' })} />
                  <BottomNavigationAction sx={{ color: 'purple' }} label="En camino" icon={<RequestQuoteOutlinedIcon />} onClick={() => notificationsWithdrawals({ status: 'pendiente por desembolso' })} />
                  <BottomNavigationAction sx={{ color: 'green' }} label="Desembolsados" icon={<FavoriteIcon />} onClick={() => notificationsWithdrawals({ status: 'Exitoso' })} />
                  <BottomNavigationAction sx={{ color: 'red' }} label="Rechazados" icon={<ArchiveIcon />} onClick={() => notificationsWithdrawals({ status: 'Rechazado' })} />

                </BottomNavigation>
              </MenuItem>
              {dataNotifications.length === 0 || dataNotifications[0].message === "No hay resultados" ? (
                <MenuItem sx={{ padding: '1rem 14rem 1rem 14rem', marginTop: '2.5rem', width: "100%" }} disabled={true}>
                  <ListItemButton>
                    <ListItemText
                      primary="Sin solicitudes"
                      secondary="No existen solicitudes hasta el momento"
                    />
                  </ListItemButton>
                </MenuItem>
              ) : (
                dataNotifications.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleMenuNotificationItemClick(item)}
                    sx={{
                      backgroundColor: selectedNotification === item ? 'transparent' : '#cecc5426',
                      marginTop: index === 0 ? '2.5rem' : '0'
                    }}
                  >
                    <ListItemButton key={index}>
                      <ListItemText
                        primary={` Solicitud ${item.withdrawal_id}: `}
                        secondary={`El usuario ${item.customer_name} ha solicitado un desembolso de crédito, por el monto de ${item.withdrawal_value}`}

                      />
                    </ListItemButton>
                  </MenuItem>
                ))
              )}
            </Menu>
          </>
        )}

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Configuraciones">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              {role === 'cliente' ? <>
                <DehazeOutlinedIcon className="icon" />
              </> : <>
                <AccountCircleRoundedIcon className="icon" />
              </>
              }
            </IconButton>
          </Tooltip>
          <Menu sx={{ mt: '40px' }} id="menu-appbar" keepMounted anchorEl={anchorElUser} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'right' }} >
            {menuItems.map((item, index) => (
              <MenuItem key={index} onClick={() => handleMenuItemClick(item)} sx={{ textDecoration: 'none', color: item.icon.props.sx.color }}>
                {[item.icon, <Typography key={item.label} textAlign="center" ml={1}>{item.label}</Typography>]}
              </MenuItem>
            ))}
          </Menu>


          {isModalOpenCompany && (
            <ModalInfoCompany isModalOpenCompany={isModalOpenCompany} setIsModalOpenCompany={setIsModalOpenCompany} selectedCompanyId={selectedCompanyId} />
          )}
          {isModalOpenAdmin && (
            <ModalInfoAdmin isModalOpenAdmin={isModalOpenAdmin} setIsModalOpenAdmin={setIsModalOpenAdmin} selectedAdminId={selectedAdminId} />
          )}
          {isModalOpenWithdrawal && (
            <ModalInfoWithdrawal isModalOpenWithdrawal={isModalOpenWithdrawal} setIsModalOpenWithdrawal={setIsModalOpenWithdrawal} selectedWithdrawalId={selectedNotification} />
          )}
        </Box>
      </HeaderProfile>
    </ThemeProvider>
  );
}
