import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { AntTab, AntTabs } from './SyledTabs.jsx';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TableCashiers from '../../admin/modules/users/tables/ListCashiers';
import TableAdminEstablishment from '../../admin/modules/users/tables/ListAdminEstablishment';
import TableCashiersEstablishment from '../../admin/modules/users/tables/ListCashiersEstablishment';
import TableAdminBranch from '../../admin/modules/users/tables/ListAdminBranch.jsx';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#035533',
    },
  },
});
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other} >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabsTableUsers() {
  const [value, setValue] = React.useState(0);
  const [role] = useState(window.localStorage.getItem("role") || null);
  const isSmallScreen = useMediaQuery('(max-width: 900px)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', padding: "1% 0% 2% 0%" }}>
        <Box >
          {role === "administrador" ? (
            <>
              <AntTabs value={value} onChange={handleChange} indicatorColor="secondary" variant="scrollable" scrollButtons="auto">
                <AntTab label={isSmallScreen ? "Admins... de establecimientos." : "Administradores de establecimientos."} {...a11yProps(0)} />
                <AntTab label={isSmallScreen ? "Admins... de Sucursales." : "Administradores de Sucursales."} {...a11yProps(1)} />
                <AntTab label="Cajeros de establecimientos." {...a11yProps(2)} />
              </AntTabs>
            </>
          ) : (
            <>
            </>
          )}
        </Box>
        {role === "administrador" ? (
          <>
            <CustomTabPanel value={value} index={0} >
              <TableAdminEstablishment />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1} >
              <TableAdminBranch />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <TableCashiers />
            </CustomTabPanel>
          </>
        ) : (
          <>
            <Grid mt={9}>
              <TableCashiersEstablishment />
            </Grid>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
}
