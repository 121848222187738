import styled from "styled-components";
import { ContLogout, FondoLogout } from "../../store/forms/Styled";
import FondoUsuario from "../../../Assets/images/fondos svg/FondoUsuarioSinSombra.svg";
import { Box, Grid } from "@mui/material";
export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
`;

export const FlexRowStart = styled.div`
  display: flex;
  justify-content: start;
  height: auto;
`;

export const ContPurchaseMain = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${FondoLogout} {
    transition: all 0.2s ease-in-out;
    height: ${({ $actLogout }) => ($actLogout ? "100%" : 0)};
    ${ContLogout} {
      display: ${({ $actLogout }) => ($actLogout ? "flex" : "none")};
      opacity: ${({ $actLogout }) => ($actLogout ? 1 : 0)};
    }
  }
`;

export const ContPurchase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  @media (max-width: 798px) {
    width: 100%;
  }
`;

export const ContHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 0rem 0rem 1.5rem 1.5rem;
  background-color: #037847;
  @media (max-width: 798px) {
    width: 100%;
  }

  .icon {
    font-size: 35px;
    color: #ffffff;
  }
  .Logout {
    outline: none;
    svg {
      color: #fff;
      font-size: 2rem;
    }
  }
`;

export const ContHeaderArreglado = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #037847;
  height: auto;
  @media (max-width: 798px) {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .icon {
    font-size: 35px;
    color: #ffffff;
  }
  .Logout {
    outline: none;
    svg {
      color: #fff;
      font-size: 2rem;
    }
  }
`;

export const ContCredimg = styled.img`
  width: 100px !important;
  padding: 1rem 1rem 1rem 1rem;
  
`;

export const ContPromotion = styled.div`
  width: 90px;
  margin: -1.5rem 0rem 0rem -0.5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }

  .textPromo {
    color: white;
    font-size: 10px;
    font-weight: bold;
    position: absolute;
  }
  .textDescount {
    color: white;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
  }
`;

export const BackgrundInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-image: url(${FondoUsuario});
  background-size: cover;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 0px 5px 2px #0000003b;
`;

export const BackgrundInfoArreglado = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 0px 5px 2px #0000003b;
`;

export const ContInfoUser = styled.div`
  display: flex;
  align-self: center;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: space-around;
  width: 95%;
  gap: 1.2rem;
  .ContCredit {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    padding: 10px 0px 10px 10px;
  }
`;

export const NameUser = styled.div`
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #035533;
  text-transform: capitalize;

  @media (max-width: 798px) {
    font-size: 20px !important;
  }
  @media (max-width: 560px) {
    font-size: 17px !important;
  }
`;

export const ContToken = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;

  * {
    color: gray;
    margin: 0;
    font-weight: 500 !important;
  }

  h3 {
    font-weight: 400;
    margin: 0;
    font-size: 3rem;
  }

  p {
    text-align: start;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 12px;
    color: gray;
  }

  .circularProgress {
    width: 22px !important;
    margin-top: 3px;

    @media (max-width: 798px) {
      width: 20px !important;
      margin-top: 2px;
    }
    @media (max-width: 560px) {
      width: 15px !important;
      margin-top: -2px;
    }
  }

  span {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const ContaImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding-top: 2rem;

  .cardMedia {
    min-height: 150px;
    min-width: 150px;
    object-fit: cover;

    @media (max-width: 420px) {
      min-height: 120px;
      min-width: 120px;
    }
  }
  .cardMediaEst {
    min-height: 150px;
    min-width: 150px;
    object-fit: cover;

    @media (max-width: 510px) {
      min-height: 130px;
      min-width: 130px;
    }
    @media (max-width: 450px) {
      min-height: 170px;
      min-width: 170px;
    }
    @media (max-width: 420px) {
      min-height: 110px;
      min-width: 110px;
    }
    @media (max-width: 380px) {
      min-height: 130px;
      min-width: 130px;
    }
    @media (max-width: 300px) {
      min-height: 220px;
      min-width: 230px;
    }
  }
`;

export const ContaImageEstablecimiento = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  align-items: center;
  z-index: 1;

  .cardMediaEst {
    min-height: 150px;
    min-width: 150px;
    object-fit: cover;
    @media (max-width: 420px) {
      min-height: 120px;
      min-width: 120px;
    }
  }
`;

export const ContEstab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1.4rem;
`;

export const Establishments = styled.div`
  width: 95%;
  height: 50px;
  margin-bottom: 1rem;
  padding-top: -1rem;

  .verMas {
    color: gray !important;
  }

  .EstablishmentName {
    align-items: center;

    Button {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #037847 !important;
      color: white;
    }
    h1,
    h4,
    a {
      color: #037847;
      display: flex;
      align-items: center;
      text-decoration: none;
    }
    h1 {
      @media (max-width: 470px) {
        font-size: 1.4rem;
      }
    }
    a {
      font-weight: 500;
    }
  }
  .ProductDescription {
    span {
      font-weight: bold;
    }
  }
`;

export const BoxImg = styled.img`
  width: 70%;
`;

export const ContSlider = styled.div`
  display: flex;
  overflow-x: scroll;
  margin-left: 2.5rem;

  @media (max-width: 500px) {
    margin-left: 0.5rem;
  }
`;

export const ContEstablecimiento = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: start;
  padding-left: 3rem;

  .containerSubcategoriesList {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    max-height: auto;
    margin-top: 1.5rem;
  }

  .typography {
    padding-left: 20px;
    font-weight: 500;
    padding-top: 2rem;
  }

  .containerSubcategoriesName {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    max-height: auto;
    margin-top: 0.5rem;
  }

  .containerPromotion {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    max-height: auto;
    margin-top: 1.5rem;

    .typography {
      padding-left: 10px;
      font-weight: 500;
    }
  }

  .containeEstabishment {
    padding-left: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: start;
  }

  @media (max-width: 500px) {
    padding-left: 0rem;
  }
`;

export const ContainerButtonWhts = styled(Box)`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 1vh;
  right: 1rem;
  z-index: 1000;

  & > *:not(style) {
    margin: 1px;
    width: 56px;
    height: 56px;
  }
`;
