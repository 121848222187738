import { Document, Text, Page, StyleSheet, Image, View, } from "@react-pdf/renderer";
import Lenddi from "../../../../../Assets/images/Logos/LenddiOscuro.png";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#E4E4E4",
    padding: 30,
  },

  bold: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "justify",
    lineHeight: 1.5,
    margin: 10,
  },

  image: {
    width: "200px",
    height: "auto",
    paddingTop: "30pt",
  },

  title: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    paddingTop: "50px"
  },
  section: {
    display: "flex",
    flexDirection: "row",
    margin: 10,
    padding: 10,
  },
  parragraph: {
    fontSize: 12,
    textAlign: "justify",
    lineHeight: 1.5,
    margin: 10,
  },
  stamp: {
    position: "absolute",
    bottom: 200, // Ajusta la posición vertical según sea necesario
    left: 100, // Ajusta la posición horizontal según sea necesario
    opacity: 0.5, // Ajusta la opacidad del sello para que sea una marca de agua
  },

  stampText: {
    fontSize: 40,
    fontWeight: "bold",
    transform: "rotate(-45deg)",
  },

  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  }
});
const PdfRequestWithdrawal = ({ requestWithdrawal }) => {
  let stampColor = ""; // Variable para almacenar el color del texto de la marca de agua

  // Determina el color del texto de la marca de agua según el estado
  switch (requestWithdrawal.status) {
    case "pendiente":
      stampColor = "#ffd000"; // Amarillo
      break;
    case "pendiente por desembolso":
      stampColor = "#800080"; // Morado
      break;
    case "Exitoso":
      stampColor = "#008000"; // Verde
      break;
    case "Rechazado":
      stampColor = "#FF0000"; // Rojo
      break;
    default:
      stampColor = "#000000"; // Color por defecto (negro) si el estado no coincide con ninguna de las opciones anteriores
      break;
  }
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.stamp}>
          <Text style={[styles.stampText, { color: stampColor }]}>{requestWithdrawal.status}</Text>
        </View>

        <Image style={styles.image} src={Lenddi} />
        <Text style={styles.parragraph}>{requestWithdrawal.create_at}.</Text>
        <Text style={styles.title}>Solicitud de desembolso de credito # {requestWithdrawal.withdrawal_id}.</Text>
        <Text style={styles.parragraph}>
          Estimados señores.</Text>
        <Text style={styles.parragraph}>
          Por la presente, me dirijo a ustedes para solicitar un desembolso de crédito en mi cuenta bancaria bajo las siguientes especificaciones:
        </Text>
        <Text style={styles.bold}>
          Nombre del Solicitante:
          <Text style={styles.parragraph}> {requestWithdrawal.customer_name}</Text>
        </Text>
        <Text style={styles.bold}>
          Número de Identidad:
          <Text style={styles.parragraph}> {requestWithdrawal.customer_id}</Text>
        </Text>
        <Text style={styles.bold}>
          Monto Solicitado:
          <Text style={styles.parragraph}> {requestWithdrawal.withdrawal_value}</Text>
        </Text>
        <Text style={styles.bold}>
          Banco:
          <Text style={styles.parragraph}> {requestWithdrawal.bank}</Text>
        </Text>
        <Text style={styles.bold}>
          Tipo de Cuenta:
          <Text style={styles.parragraph}> {requestWithdrawal.type_of_account}</Text>
        </Text>
        <Text style={styles.bold}>
          Número de Cuenta Bancaria:
          <Text style={styles.parragraph}> {requestWithdrawal.account}</Text>
        </Text>

        <Text style={styles.parragraph}>
          Deseo informarles que he cumplido con todos los requisitos establecidos por su institución para acceder a este desembolso. Agradecería mucho si pudieran procesar esta solicitud lo antes posible.
        </Text>
        <Text style={styles.parragraph}>
          Agradezco de antemano su atención y colaboración.
        </Text>
        <Text style={styles.parragraph}>
          Atentamente,
        </Text>
        <Text style={styles.parragraph}>
          Firma: {requestWithdrawal.customer_name}
        </Text>

        <View style={styles.pageNumber}>
          <Text render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`} />
        </View>
      </Page>
    </Document>
  );
}

export default PdfRequestWithdrawal;