import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

import { Grid, Typography } from '@mui/material';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { formatNumber } from '../../../Utilities/NumericFormat';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import { CardDiagram } from '../../admin/modules/moduleDashboard/Styled';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import CajeroAzul from '../../../Assets/images/fondos svg/CajeroAzul.svg';
import CajeroRojo from '../../../Assets/images/fondos svg/CajeroRojo.svg';
import TypographyH4 from '../../../components/elements/labels/TypographyH4';
import Lineasrojas from '../../../Assets/images/fondos svg/Lineasrojas.svg';
import CajeroVerde from '../../../Assets/images/fondos svg/CajeroVerde.svg';
import Lineasazules from '../../../Assets/images/fondos svg/Lineasazules.svg';
import Lineasverdes from '../../../Assets/images/fondos svg/Lineasverdes.svg';
import Lineasamarillas from '../../../Assets/images/fondos svg/Lineasamarillas.svg';

export default function CardInfoDashboard({ typeCard, label, sales }) {
  const { token } = useAuthContext();
  const [clients, setClients] = useState([]);
  const [branchs, setBranchs] = useState([]);
  const [infoEstablishment, setinfoEstablishment] = useState([])
  const [activeClientsCount, setActiveClientsCount] = useState(0);
  const [inactiveClientsCount, setInactiveClientsCount] = useState(0);
  const [pendingClientsCount, setPendingClientsCount] = useState(0);
  const [activeCashierCount, setActiveCashierCount] = useState(0);
  const [inactiveCashierCount, setInactiveCashierCount] = useState(0);
  const [activeBranchCount, setActiveBranchCount] = useState(0);
  const [inactiveBranchCount, setInactiveBranchCount] = useState(0);
  const [pendingBranchCount, setPendingBranchCount] = useState(0);

  const decode = jwtDecode(token)

  const InfoClient = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewAssigned`, {
        headers: {
          authorization: token,
        },
      });
      setClients(response.data);
      const activeClients = response.data.filter(client => client.status === 'activo');
      const pendingClients = response.data.filter(client => client.status === 'pendiente');
      const inactiveClients = response.data.filter(client => client.status === 'inactivo');
      setActiveClientsCount(activeClients.length);
      setInactiveClientsCount(inactiveClients.length);
      setPendingClientsCount(pendingClients.length);
    } catch (error) {
    }
  }

  const InfoEstablishment = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/selectEstablishmentBranch/${decode.NIT}`, {
        headers: {
          authorization: token,
        },
      });
      setinfoEstablishment(response.data);
      const activeBranch = response.data.filter(branch => branch.status === 'activo');
      const pendingBranch = response.data.filter(branch => branch.status === 'pendiente');
      const inactiveBranch = response.data.filter(branch => branch.status === 'inactivo');
      setActiveBranchCount(activeBranch.length);
      setInactiveBranchCount(inactiveBranch.length);
      setPendingBranchCount(pendingBranch.length);
    } catch (error) {
    }
  }

  const InfoBranch = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCashiersByEst/${decode.establishment_id}`, {
        headers: {
          authorization: token,
        },
      });
      setBranchs(response.data);
      const activeCashierCount = response.data.filter(client => client.status === 'activo');
      const inactiveCashierCount = response.data.filter(client => client.status === 'inactivo');
      setActiveCashierCount(activeCashierCount.length);
      setInactiveCashierCount(inactiveCashierCount.length);
    } catch (error) {
    }
  }

  useEffect(() => {
    if (label === "administrador") {
      InfoClient()
    } else if (label === "administradorEstablecimiento") {
      InfoEstablishment()
    } else if (label === "administradorSucursal") {
      InfoBranch()
    }
  }, [label])

  return (
    label === "administrador" ? (
      <>
        {typeCard === "registered" ? (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <GroupsRoundedIcon className="icon" /> Usuarios registrados</Typography>
              </Grid>
              <TypographyH4 className='TypographyStatitics' fontColor='gray' text={formatNumber(clients.length)} ml={2.5} mt={1} />
            </Grid>

            <Grid item className='GridContainerII'>
              <img className='barImg' src={Lineasazules} alt="" />
            </Grid>
          </CardDiagram>
        ) : typeCard === "withQuote" ? (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <HowToRegRoundedIcon className="icon" /> Usuarios con cupo</Typography>
              </Grid>
              <TypographyH4 fontColor='gray' text={formatNumber(activeClientsCount)} ml={2.5} mt={1} />
            </Grid>

            <Grid item className='GridContainerII'>
              <img className='barImg' src={Lineasverdes} alt="" />
            </Grid>
          </CardDiagram>
        ) : typeCard === "pending" ? (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <PendingRoundedIcon className="icon" /> Usuarios pendientes</Typography>
              </Grid>
              <TypographyH4 fontColor='gray' text={formatNumber(pendingClientsCount)} ml={2.5} mt={1} />
            </Grid>

            <Grid item className='GridContainerII'>
              <img className='barImg' src={Lineasamarillas} alt="" />
            </Grid>
          </CardDiagram>
        ) : (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <PersonOffRoundedIcon className="icon" /> Usuarios inhabilitados</Typography>
              </Grid>
              <TypographyH4 fontColor='gray' text={formatNumber(inactiveClientsCount)} ml={2.5} mt={1} />
            </Grid>

            <Grid item className='GridContainerII'>
              <img className='barImg' src={Lineasrojas} alt="" />
            </Grid>
          </CardDiagram>
        )}
      </>
    ) : label === "administradorSucursal" ? (
      <>
        {typeCard === "registeredCashier" ? (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <GroupsRoundedIcon className="icon" /> Cajeros registrados</Typography>
              </Grid>
              <TypographyH4 className='TypographyStatitics' fontColor='gray' text={formatNumber(infoEstablishment.length)} ml={2.5} mt={1} />
            </Grid>

            <Grid item className='GridContainerII'>
              <img className='barImg' src={CajeroAzul} alt="" />
            </Grid>
          </CardDiagram>
        ) : typeCard === "activeCashier" ? (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <HowToRegRoundedIcon className="icon" /> Cajeros activos</Typography>
              </Grid>
              <TypographyH4 fontColor='gray' text={formatNumber(activeCashierCount)} ml={2.5} mt={1} />
            </Grid>

            <Grid item className='GridContainerII'>
              <img className='barImg' src={CajeroVerde} alt="" />
            </Grid>
          </CardDiagram>
        ) : typeCard === "inactiveCashier" ? (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <PersonOffRoundedIcon className="icon" /> Cajeros inactivos</Typography>
              </Grid>
              <TypographyH4 fontColor='gray' text={formatNumber(inactiveCashierCount)} ml={2.5} mt={1} />
            </Grid>

            <Grid item className='GridContainerII'>
              <img className='barImg' src={CajeroRojo} alt="" />
            </Grid>
          </CardDiagram>
        ) : (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <TrendingUpRoundedIcon className="icon" /> Total ventas</Typography>
              </Grid>
              <TypographyH4 fontColor='gray' text={formatNumber(sales)} ml={2.5} mt={1} />
            </Grid>
            <Grid item className='GridContainerII'>
              <img className='barImg' src={Lineasazules} alt="" />
            </Grid>
          </CardDiagram>
        )}
      </>
    ) : (
      <>
        {typeCard === "registeredBranch" ? (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <GroupsRoundedIcon className="icon" /> Sucursales registradas</Typography>
              </Grid>
              <TypographyH4 className='TypographyStatitics' fontColor='gray' text={infoEstablishment.length} ml={2.5} mt={1} />
            </Grid>

            <Grid item className='GridContainerII'>
              <img className='barImg' src={Lineasazules} alt="" />
            </Grid>
          </CardDiagram>
        ) : typeCard === "activeBranch" ? (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <HowToRegRoundedIcon className="icon" /> Sucursales activas</Typography>
              </Grid>
              <TypographyH4 fontColor='gray' text={formatNumber(activeBranchCount)} ml={2.5} mt={1} />
            </Grid>

            <Grid item className='GridContainerII'>
              <img className='barImg' src={Lineasverdes} alt="" />
            </Grid>
          </CardDiagram>
        ) : typeCard === "inactiveBranch" ? (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <PendingRoundedIcon className="icon" /> Sucursales inhabilitados</Typography>
              </Grid>
              <TypographyH4 fontColor='gray' text={formatNumber(pendingBranchCount)} ml={2.5} mt={1} />
            </Grid>

            <Grid item className='GridContainerII'>
              <img className='barImg' src={Lineasamarillas} alt="" />
            </Grid>
          </CardDiagram>
        ) : (
          <CardDiagram type="info">
            <Grid container className='GridContainer'>
              <Grid container >
                <Typography className='TypographyHeader'> <PersonOffRoundedIcon className="icon" /> Total de sucursales</Typography>
              </Grid>
              <TypographyH4 fontColor='gray' text={formatNumber(inactiveBranchCount)} ml={2.5} mt={1} />
            </Grid>

            <Grid item className='GridContainerII'>
              <img className='barImg' src={Lineasrojas} alt="" />
            </Grid>
          </CardDiagram>
        )}
      </>
    )
  )
}

