import styled from "styled-components";
import { Box } from "@mui/material";

export const ModalView = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: auto;
  padding-bottom: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 1% 2% 0% 2%;
  }

  .bodyCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1% 0% 1.5% 2%;
  }

  .blockItem {
    display: flex;

    .start {
      display: flex;
      justify-content: start;
    }
    .end {
      display: flex;
      justify-content: end;
    }
    @media (max-width: 1496px) and (max-height: 716px) {
      margin-right: 3rem;
    }
    @media (max-width: 600px) and (max-height: 572px) {
      margin-top: -0.5rem;
  }
  }

  .containerItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 1200px){
    width: 65%;
  }
  @media (max-width: 900px){
    width: 72%;
  }
  @media (max-width: 600px){
    width: 75%;
  }

`;
