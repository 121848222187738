import React, { useEffect, useMemo, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import ReplayIcon from '@mui/icons-material/Replay';
import { MaterialReactTable } from 'material-react-table';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridHeaderII } from '../../moduleDashboard/Styled';
import ProfileMenu from '../../../../Components/menu/Profile';
import { Box, IconButton, Grid, Tooltip } from '@mui/material';
import ModalViewDetailsCashiers from '../modals/ViewDetailsCashiers';
import { useAuthContext } from '../../../../../Contexts/AuthContext';
import FormatId from '../../../../../Utilities/FormatIdentification';
import { TittleModule } from '../../registrationEstablishment/Styled';

const TableCashiersEstablishment = () => {
  const { token } = useAuthContext();
  const [cashiers, setCashiers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCashierId, setSelectedCashierId] = useState(null);
  const [/* selectedCashier */, setSelectedCashier] = useState(null);
  const [/* showRegistrationComponent */, setShowRegistrationComponent] = useState(false);
  const decode = jwtDecode(token)

  const getCashiersEstablishment = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCashiersByEst/${decode.establishment_id}`, {
        headers: {
          authorization: token,
        },
      });
      setCashiers(response.data);
    } catch (error) {
    }
  };

  const handleUpdateState = async (row) => {
    try {
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/ChangeCashierStatus/${row.original.cashier_id}`, null, config);
      setCashiers((prevEstablishments) => {
        return prevEstablishments.map((cashier) => {
          if (cashier.status === row.original.status) {
            return { ...cashier };
          } else {
            return cashier;
          }
        });
      }, response.data);
    } catch (error) {
    }
  };

  const handleViewModal = (cashierId) => {
    setSelectedCashierId(cashierId);
    setSelectedCashier(cashierId)
    setShowRegistrationComponent(false);
    setIsModalOpen(true);
  };

  const handleReload = () => {
    getCashiersEstablishment();
  }

  useEffect(() => {
    getCashiersEstablishment()
    handleUpdateState();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'cashier_id',
        header: 'Número de identificación',
        size: 250,
        Cell: ({ cell }) => (
          <Box component="span" >
            {<FormatId id={cell.getValue()} />}
          </Box>
        ),
      },
      {
        accessorKey: 'cashier_name',
        header: 'Nombre del cajero',
      },
      {
        accessorKey: 'role',
        header: 'Rol',
      },
      {
        accessorKey: 'status',
        header: 'Estado del cajero',
        Cell: ({ cell, row }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color:
                row.original.status === 'activo'
                  ? theme.palette.success.dark
                  : row.original.status === 'pendiente'
                    ? 'rgb(255, 174, 0)'
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              fontWeight: 'bold',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        Cell: ({ cell, row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px', }}>
            <>
              <Tooltip title="Ver detalle">
                <IconButton style={{ color: '#035533' }} onClick={() => handleViewModal(row.original.cashier_id)}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </>
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
      <GridHeaderII container item xl={12} mt={-18} pb={3}>
        <TittleModule >Lista de cajeros</TittleModule>
        <ProfileMenu />
      </GridHeaderII>
      <MaterialReactTable columns={columns} data={cashiers}
        renderTopToolbarCustomActions={() => (
          <>
            <Tooltip arrow placement="bottom" title="Reload">
              <IconButton onClick={() => handleReload()}>
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        initialState={{
          density: 'compact'
        }}
      />

      {setSelectedCashierId !== null && (
        <ModalViewDetailsCashiers isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedCashierId={selectedCashierId} />
      )}
    </Grid>
  );
};

export default TableCashiersEstablishment;