import "swiper/css";
import "./styles.css";
import "swiper/css/pagination";
import { Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Card, CardMedia, Typography } from "@mui/material";
import { ContPromotion, ContSlider, ContaImage } from "../../../pages/client/forms/Styled";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import StarRed from "../../../Assets/images/iconos/StarRed.svg"
import { useAuthContext } from "../../../Contexts/AuthContext";


export default function SliderCategoryPromo({ label, city }) {
  const { role } = useAuthContext();
  const [establishments, setEstablishments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [promotions, setPromotions] = useState([]);

  const fetchEstablishment = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getEstablishmentByCity`, {
          city: city,
          category_id: null
      })
      setEstablishments(response.data);
    } catch (error) { }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCategories/${role}`)
      setCategories(response.data);
    } catch (error) { }
  };

  const fetchPromotions = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getEstablishmentsWithDiscount`, {
        city: city
      })
      setPromotions(response.data);
    } catch (error) {
      if (error.response.data.message === "La ciudad no tiene promociones") {
        Swal.fire({
          icon: 'info',
          title: 'Ciudad sin promociones',
          text: `Hata el momento los establecimientos de ${city} no han hecho puclicación de promociones.`,
          confirmButtonColor: '#035533'
        });
      }
    }
  };

  useEffect(() => {
    if (label === "Establecimientos") {
      fetchEstablishment()
    }
    if (label === "Categorias") {
      fetchCategories()
    }
    if (label === "Promociones") {
      fetchPromotions()
    }
  }, [label, city]);

  return (
    <>
      <Swiper slidesPerView={3} spaceBetween={30} pagination={{ clickable: true }} modules={[Pagination]} className="mySwiper">
        <ContSlider>
        {label === "Establecimientos" &&
            establishments.map((establishment, index) => (<>
              <ContaImage key={index}>
              <Link to={establishment.url}>
                  <Card sx={{ margin: "0px 0px 5px 30px", borderRadius: "100px", boxShadow: "0px 2px 5px 2px #0000001b" }}>
                    <CardMedia className="cardMedia" image={`${process.env.REACT_APP_API_URL}/${establishment.image}`} title={establishment.alt} />
                  </Card>
                </Link>
                <div className="ProductDescription">
                  <Typography sx={{ marginTop: "10px", marginLeft:"10px"}} variant="body2" color="text.secondary">
                    {establishment.business_name}
                  </Typography>
                </div>
              </ContaImage>
            </>
            ))}
          {label === "Categorias" &&
            categories.map((category, index) => (
              <ContaImage key={index}>
                <Link to={`/client/subcategories/${category.category_id}`} key={index}>
                  <Card sx={{ margin: "0px 0px 5px 10px", borderRadius: "100px", boxShadow: "0px 2px 5px 2px #0000001b" }}>
                    <CardMedia className="cardMedia" image={`${process.env.REACT_APP_API_URL}/${category.image}`} title={category.alt} />
                  </Card>
                </Link>
                <div className="ProductDescription">
                  <Typography sx={{ marginTop: "10px" }} variant="body2" color="text.secondary">
                    {category.category_name}
                  </Typography>
                </div>
              </ContaImage>
            ))}
          {label === "Promociones" &&
            promotions.map((promotion, index) => (<>
              <ContaImage key={index}>
                <Link to={`/client/promotions/${promotion.NIT}`}>
                  <ContPromotion >
                    <img src={StarRed} alt="Promoción" /> {/* Aquí puedes agregar la imagen del componente ContPromotion */}
                    <Typography variant="button" className="textDescount" mb={2}>{promotion.MAXdescuento} %</Typography> {/* Agrega el texto */}
                    <Typography variant="body2" className="textPromo" mt={2}>De descuento</Typography> {/* Agrega el texto */}
                  </ContPromotion>
                  <Card sx={{ margin: "0px 0px 5px 30px", borderRadius: "100px", boxShadow: "0px 2px 5px 2px #0000001b" }}>
                    <CardMedia className="cardMedia" image={`${process.env.REACT_APP_API_URL}/${promotion.image}`} title={promotion.alt} />
                  </Card>
                </Link>
              </ContaImage>
            </>
            ))}
        </ContSlider>
      </Swiper>
    </>
  );
}
