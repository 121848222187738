import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";

import LogoutIcon from '@mui/icons-material/Logout';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FormatPrice from "../../../Utilities/FormatPrices.jsx";
import Lenddi from "../../../Assets/images/Logos/LenddiBlanco.svg";
import { useAuthContext } from "../../../Contexts/AuthContext.jsx";
import { ContLogout, FondoLogout } from "../../store/forms/Styled.jsx";
import { Button, CircularProgress, Fab, Typography, Grid, Avatar } from "@mui/material";
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import TypographyH6 from "../../../components/elements/labels/TypographyH6.jsx";
import TypographyH3 from "../../../components/elements/labels/TypographyH3.jsx";
import TypographyH4 from "../../../components/elements/labels/TypographyH4.jsx";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { BackgrundInfoArreglado, ContCredimg, ContEstab, ContHeaderArreglado, ContInfoUser, ContPurchase, ContPurchaseMain, ContToken, ContainerButtonWhts, Establishments, FlexRow, NameUser, } from "./Styled";
import ModalWithdrawalRequest from "../modals/withdrawalRequest.jsx";
import ProfileMenu from "../../Components/menu/Profile.jsx";
import TypographyH5 from "../../../components/elements/labels/TypographyH5.jsx";
import SliderCategoryPromo from "../../../components/elements/slider/SliderCategoryPromo.jsx";
import SelectCity from "../../../components/elements/ComboBox/comboCity.jsx";

export default function PurchaseCustomer() {
  const [, /* id */ setId] = useState();
  const { token, logout } = useAuthContext();
  const [, /* error */ setError] = useState(null);
  const [tokenClient, setTokenClient] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeLogout, setActiveLogout] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState();
  const [showDesembolsoText, setShowDesembolsoText] = useState(false);
  const [companyData, setCompanyData] = useState([{ company_name: "compañia", company_phone: "0" }])
  const [searchedClient, setSearchedClient] = useState([{
    available_credit: 0,
    customer_name: ""
  }]);
  const [, /* serverTime */ setServerTime] = useState(new Date());
  const [codVerification, setCodVerification] = useState({
    identificacion: "",
    token: "",
  });

  const decode = jwtDecode(token)
  const navigate = useNavigate()
  const category_id = null
  const city = localStorage.getItem('ciudad') || null;

  const handleViewModal = (nitEstablecimiento) => {
    setSearchedClient(nitEstablecimiento)
    setIsModalOpen(true);
  };

  const fetchServerTime = async () => {
    try {
      const serverTime = new Date();
      const now = serverTime.toLocaleTimeString();
      const seconds = serverTime.getSeconds();
      setSecondsRemaining(seconds);
      setServerTime(now);
    } catch (error) { }
  };

  const fetchCompanyData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCompanyToClient`);
      setCompanyData(response.data);
    } catch (error) {
    }
  };

  const handleIconClick = () => {
    setShowDesembolsoText(!showDesembolsoText);
  };

  const handleEnviarId = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/sendId`, {
        customer_id: decode.id_user,
      },
        {
          headers: {
            authorization: token,
          },
        }
      );
      setId(response.data);
    } catch (error) {
      setError(error);
    }
  };

  const handlegenerateToken = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generateToken`, {
        customer_id: decode.id_user,
      },
      );
      setCodVerification({
        ...codVerification,
        token: response.data.tokenquesemanda,
      });
      setTokenClient({ token: response.data.tokenquesemanda });
    } catch (error) {
      setError(error);
      if (error.response.status === 401) {
        logout()
      }
    }
  };

  const fetchToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getToken/${decode.id_user}`,
        {
          headers: {
            authorization: token,
          },
        }
      );
      if (response.data.length > 0) {
        setTokenClient({ token: response.data[0].token });
      }
    } catch (error) { }
  };

  const searchClient = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getSingleCustomerWithCreditForClient/${decode.id_user}`,
        {
          headers: {
            authorization: token,
          },
        }
      );
      setSearchedClient(response.data)
    } catch (error) { }
  };

  const Logout = () => {
    logout()
    setActiveLogout(!activeLogout)
    setTimeout(() => {
      navigate('/loginClient');
    }, [1000]);

  }
  useEffect(() => {
    handlegenerateToken();
  }, [])

  useEffect(() => {
    fetchToken();
    fetchServerTime();
    fetchCompanyData();
    handleEnviarId();
    searchClient();

    const tokenTimer = setInterval(() => {
      fetchToken();
      fetchServerTime();
    }, 1000);

    const serverTimeTimer = setInterval(() => {
      setSecondsRemaining((prevSeconds) => {
        if (prevSeconds === 0) {
          handlegenerateToken();
          return 60;
        }
        return prevSeconds - 1;

      });
    }, 1000);

    return () => {
      clearInterval(tokenTimer);
      clearInterval(serverTimeTimer);
    };
  }, []);

  return (
    <ContPurchaseMain $actLogout={activeLogout}>
      <FondoLogout>
        <ContLogout>
          <LogoutIcon />
          <h3>¿Quieres cerrar sesión?</h3>
          <button className='button Confirm' onClick={() => Logout()}>SI</button>
          <button className='button Cancel' onClick={() => setActiveLogout(!activeLogout)}>NO</button>
        </ContLogout>
      </FondoLogout>
      <ContPurchase>
        <BackgrundInfoArreglado>
          <ContHeaderArreglado>
            <Grid container item xs={11} sm={11} md={5} lg={4} xl={3} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
                <Link to={`/client`}>
                  <ContCredimg src={Lenddi} />
                </Link>
              </Grid>
              <Grid item xs={5} sm={4} md={7} lg={7} xl={7} >
                <SelectCity />
              </Grid>
            </Grid>
            <ProfileMenu />
          </ContHeaderArreglado>
          <ContInfoUser>
            <NameUser>
              <Grid container item xs={9} sm={6} md={6} lg={6} xl={6} alignItems={'center'}>
                {searchedClient && (
                  <Avatar sx={{ bgcolor: '#93b890', marginRight: '10px' }}>{searchedClient[0]?.customer_name.charAt(0)}</Avatar>
                )}
                {`${searchedClient[0]?.customer_name || "alguien"}`}
              </Grid>

              <ContToken container item xs={3} sm={3} md={3} lg={3} xl={3} >
                <Grid item xs={9} sm={9.5} md={10} lg={10.5} xl={11} sx={{ display: "flex", justifyContent: "end" }}>
                  <TypographyH5 text={'Token:'} mt={1} />
                  <TypographyH5 text={tokenClient.token} mt={1} />
                </Grid>
                <CircularProgress className="circularProgress" variant="determinate" value={(secondsRemaining / 60) * 100} />
              </ContToken>
            </NameUser>
            <FlexRow style={{ alignItems: "start" }} >
              <div className="ContCredit" >
                {searchedClient[0].status === "inactivo" && (
                  <>
                    <TypographyH6 sx={{ fontSize: "1rem" }} text="Cupo disponible de:" />
                    <TypographyH3 sx={{ fontWeight: "600", fontSize: "2rem" }} text={<FormatPrice price={`${searchedClient[0].available_credit}`} />} fontColor="#fa3535 !important" />
                    <TypographyH6 text={`Cupo inactivo`} fontColor="#fa3535 !important" />
                  </>
                )}

                {searchedClient[0].status === "congelado" && (
                  <>
                    <TypographyH6 sx={{ fontSize: "1rem" }} text="Cupo disponible de:" />
                    <TypographyH3 sx={{ fontWeight: "600", fontSize: "2rem" }} text={<FormatPrice price={`${searchedClient[0].available_credit}`} />} fontColor="#49514e !important" />
                    <TypographyH6 text={`Cupo congelado por desembolso`} fontColor="#49514e !important" />
                  </>
                )}

                {searchedClient[0].status === "activo" && (
                  <>
                    <Typography variant="subtitle3" sx={{ color: "#49514e" }} > Cupo disponible:</Typography>
                    <div style={{ display: "flex", flexDirection: 'row', alignItems: "center" }}>
                      <TypographyH4 fontColor="#49514e" sx={{ fontWeight: "600" }} text={<FormatPrice price={`${searchedClient[0].available_credit}`} />} />
                      {showDesembolsoText ? (
                        <ExpandLessIcon onClick={handleIconClick} style={{ color: "#49514e", fontSize: "1.2rem", cursor: "pointer" }} />
                      ) : (
                        <ExpandMoreIcon onClick={handleIconClick} style={{ color: "#49514e", fontSize: "1.2rem", cursor: "pointer" }} />
                      )}
                    </div>

                    {showDesembolsoText && (
                      <Grid item xs={10} sm={3.5} md={3} lg={2} xl={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Typography variant="subtitle3" sx={{ color: "#098b4d" }} > Disponible para retiro <FormatPrice price={`${searchedClient[0].withdrawal_credit}`} /></Typography>
                      </Grid>
                    )}
                  </>
                )}
              </div>
            </FlexRow>
          </ContInfoUser>
        </BackgrundInfoArreglado>

        <FlexRow className="EstablishmentName" style={{ width: "80%", height: "100px" }}>
          <Button onClick={() => navigate("/client/creditList")} style={{ width: "45%", backgroundColor: "#037847", color: "white", display: "flex", flexDirection: "column" }} >
            <LocalMallOutlinedIcon sx={{ color: "white", fontSize: "30px", marginTop: "15px" }} />
            <h4 className="whatsapp-button" sx={{ color: "white", fontSize: "20px" }}>Movimientos</h4>
          </Button>

          <Button style={{ width: "45%", backgroundColor: "#037847", color: "white", display: "flex", flexDirection: "column" }} onClick={() => handleViewModal(searchedClient)}>
            <MonetizationOnOutlinedIcon sx={{ color: "white", fontSize: "30px", marginTop: "15px" }} />
            <h4 className="whatsapp-button" sx={{ color: "white", fontSize: "20px" }} >Retiro de dinero</h4>
          </Button>
        </FlexRow>


        <ContEstab>
          <Establishments>
            <FlexRow className="EstablishmentName">
              <h1>Establecimientos Aliados</h1>
              <a className="verMas" onClick={() => navigate(`/client/establishmentList`)} target="_blank" rel="noreferrer"> Ver más <RemoveRedEyeOutlinedIcon fontSize="small" sx={{ marginLeft: "5px" }} /> </a>
            </FlexRow>
          </Establishments>
          <SliderCategoryPromo label={'Establecimientos'} city={city} />
        </ContEstab>

        <ContEstab>
          <Establishments>
            <FlexRow className="EstablishmentName">
              <h1>Categorias</h1>
              <a className="verMas" onClick={() => navigate(`/client/categories/${category_id}`)} target="_blank" rel="noreferrer"> Ver más <RemoveRedEyeOutlinedIcon fontSize="small" sx={{ marginLeft: "5px" }} /> </a>
            </FlexRow>
          </Establishments>
          <SliderCategoryPromo label={'Categorias'} city={city} />
        </ContEstab>

        <ContEstab>
          <Establishments>
            <FlexRow className="EstablishmentName">
              <h1>Promociones</h1>
            </FlexRow>
          </Establishments>
          <SliderCategoryPromo label={'Promociones'} city={city} />
        </ContEstab>

        <ContainerButtonWhts>
          <Fab style={{ backgroundColor: "#24d163" }} aria-label="edit">
            <a href={`https://wa.me/${companyData[0].company_phone}?text=Estoy%20interesado%20en%20asesoria%20con%20${companyData[0].company_name}`} target="_blank" rel="noreferrer" style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <WhatsAppIcon alt="Chatea con nosotros" className="whatsapp-button" sx={{ color: "white", fontSize: "28px" }} />
            </a>
          </Fab>
        </ContainerButtonWhts>
      </ContPurchase>

      {searchedClient !== null && (
        <ModalWithdrawalRequest isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} decode={searchedClient} />
      )}
    </ContPurchaseMain>
  );
}
