import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import MuiDrawer from '@mui/material/Drawer';
import { Grid, Tooltip } from '@mui/material';
import { HeaderSidebar } from '../../home/Styled';
import Typography from '@mui/material/Typography';
import { BoxImg } from '../../client/forms/Styled'; 
import CategoryIcon from '@mui/icons-material/Category';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { BoxSidebar, Item, TabItem, TextItem } from './Styled';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LogoLenndi from '../../../Assets/images/Logos/logoLenddi.svg';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import DomainAddRoundedIcon from '@mui/icons-material/DomainAddRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import Lenddi from '../../../Assets/images/Logos/LenddiBlanco.svg';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#035533',
    },
  },
});

export default function ItemsSidebar({ setActiveTab }) {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  const handleResize = () => {
    setOpen(window.innerWidth >= 900);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.localStorage.setItem("movil", JSON.stringify(!open));
  }, [open]);

  const getTabIcon = (index) => {
    const icons = {
      1: <HomeRoundedIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      2: <DomainAddRoundedIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      3: <BusinessRoundedIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      4: <PeopleAltRoundedIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      5: <RecentActorsIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      6: <PriceCheckIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      7: <PriceCheckIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      8: <CategoryIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,

    };
    return icons[index] || null;
  };

  const getTabContent = (index, label) => {
    return (
      <Item>
        {open ?
          <>
            {getTabIcon(index)}
            <TextItem className='typographyItem'>{label}</TextItem>
          </>
          :
          <Tooltip title={label} placement="right">
            <div>
              {getTabIcon(index)}
            </div>
          </Tooltip>
        }
      </Item> 
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <MuiDrawer variant="permanent" open={open}>
          <BoxSidebar>
            <Tabs orientation="vertical" value={value} onChange={handleChange} className='orientationTab' indicatorColor="secondary">
              <HeaderSidebar>
              <Grid container alignItems={'center'} mt={2}>
                  {open ? <BoxImg src={Lenddi} alt="Lenddi" /> : <BoxImg src={LogoLenndi} alt="Lenddi" />}
                </Grid>
              </HeaderSidebar>

              {open && <Typography className='typographyTab'> PRINCIPAL </Typography>}
              <TabItem label={getTabContent(1, 'Dashboard')} />

              {open && <Typography className='typographyTab'> ESTABLECIMIENTOS </Typography>}
              <TabItem label={getTabContent(8, 'Categoria de productos')} />
              <TabItem label={getTabContent(2, 'Registro establecimiento')} />
              <TabItem label={getTabContent(3, 'Lista establecimientos')}/>
              <TabItem label={getTabContent(4, 'Lista de usuarios')} />

              {open && <Typography className='typographyTab'> CUPOS </Typography>}
              <TabItem label={getTabContent(6, 'Gestion de cupos')} />

              {open && <Typography className='typographyTab'> FACTURACION </Typography>}
              <TabItem label={getTabContent(6, 'Liquidación por estable.....')} />
              <TabItem label={getTabContent(7, 'Lista de ventas generales')} />
            </Tabs>
          </BoxSidebar>
        </MuiDrawer>
      </Box>
    </ThemeProvider>
  );
}
