
import React, { useEffect, useState } from 'react';
import validator from 'validator';
import Swal from 'sweetalert2'
import axios from 'axios';

import { GridHeaderII } from '../moduleDashboard/Styled';
import ProfileMenu from '../../../Components/menu/Profile';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAuthContext } from '../../../../Contexts/AuthContext';
import { CssBaseline, Grid, Box, Typography } from "@mui/material";
import { LineDivider } from '../../../../components/elements/lines/Styled';
import ComboBox360 from '../../../../components/elements/ComboBox/comboBox360';
import { StyledInputText } from '../../../../components/elements/Inputs/Styled';
import { CardBody2, CardBody3, GridBody, GridBtn, TittleModule } from './Styled';
import ButtonActiosn360 from '../../../../components/elements/Buttons/ButtonActions360';

export default function RegistrationBranch({ branchId, selectedBranch, headerVisibility }) {
  const { token } = useAuthContext();
  const [errors, setErrors] = useState({});
  const [/* open */, setOpen] = useState(false);
  const [selectedCiudad, setSelectedCiudad] = useState("");
  const [ubicacion, setUbicacion] = useState({ domicilio: "" });
  const [selectedEstablishment, setSelectedEstablishment] = useState("");
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [establishment, setEstablishment] = useState({
    additional_info: "",
    contact_position: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
  });
  const [direccion, setDireccion] = useState({
    tipoVia: "",
    primerNumeroVia: "",
    segundoNumeroVia: "",
    tercerNumeroVia: "",
  });
  const type = "sucursal"

  const validateForm = () => {
    const errors = {};
    if (!establishment.contact_email || !validator.isEmail(establishment.contact_email)) {
      errors.contact_email = 'El Correo no es válido.';
    }
    if (!selectedDepartamento) {
      errors.selectedDepartamento = 'El campo Departamento es requerido.';
    }
    if (!selectedCiudad) {
      errors.selectedCiudad = 'El campo Ciudad es requerido.';
    }
    if (!direccion.tipoVia || !direccion.tipoVia.trim()) {
      errors.direccionTipoVia = 'Campo requerido.';
    }
    if (!direccion.primerNumeroVia || !direccion.primerNumeroVia.trim()) {
      errors.direccionPrimerNumeroVia = 'Campo requerido.';
    }
    if (!direccion.segundoNumeroVia || !direccion.segundoNumeroVia.trim()) {
      errors.direccionSegundoNumeroVia = 'Campo requerido.';
    }
    if (!direccion.tercerNumeroVia || !direccion.tercerNumeroVia.trim()) {
      errors.direccionTercerNumeroVia = 'Campo requerido.';
    }

    const stringFields = ['contact_position', 'contact_name', 'additional_info'];
    stringFields.forEach((field) => {
      if (!establishment[field] || !establishment[field].trim()) {
        errors[field] = `Campo requerido.`;
      }
    });

    const numericFields = ['contact_phone'];
    numericFields.forEach((field) => {
      if (!establishment[field] || !/^\d{1,10}$/.test(establishment[field])) {
        errors[field] = `Lim. 10 digitos`;
      }
    });

    return errors;
  };

  const validateFormUpdate = () => {
    const errors = {};
    if (!establishment.contact_email || !validator.isEmail(establishment.contact_email)) {
      errors.contact_email = 'El Correo no es válido.';
    }
    if (!selectedDepartamento) {
      errors.selectedDepartamento = 'El campo Departamento es requerido.';
    }
    if (!selectedCiudad) {
      errors.selectedCiudad = 'El campo Ciudad es requerido.';
    }
    if (!direccion.tipoVia || !direccion.tipoVia.trim()) {
      errors.direccionTipoVia = 'Campo requerido.';
    }
    if (!direccion.primerNumeroVia || !direccion.primerNumeroVia.trim()) {
      errors.direccionPrimerNumeroVia = 'Campo requerido.';
    }
    if (!direccion.segundoNumeroVia || !direccion.segundoNumeroVia.trim()) {
      errors.direccionSegundoNumeroVia = 'Campo requerido.';
    }
    if (!direccion.tercerNumeroVia || !direccion.tercerNumeroVia.trim()) {
      errors.direccionTercerNumeroVia = 'Campo requerido.';
    }

    const stringFields = ['contact_position', 'contact_name', 'additional_info',];
    stringFields.forEach((field) => {
      if (!establishment[field] || !establishment[field].trim()) {
        errors[field] = `Campo requerido.`;
      }
    });

    const numericFields = ['contact_phone'];
    numericFields.forEach((field) => {
      if (!establishment[field] || !/^\d{1,10}$/.test(establishment[field])) {
        errors[field] = `Lim. 10 digitos.`;
      }
    });

    return errors;
  };

  const resetFields = () => {
    setEstablishment({
      additional_info: "",
      contact_position: "",
      contact_name: "",
      contact_email: "",
      contact_phone: "",
    });

    setDireccion({
      tipoVia: "",
      primerNumeroVia: "",
      segundoNumeroVia: "",
      tercerNumeroVia: "",
    });

    setUbicacion({ setUbicacion: "" });
    setSelectedDepartamento(null);
    setSelectedCiudad(null);
  };

  const updateUbicacion = () => {
    const { tipoVia, primerNumeroVia, segundoNumeroVia, tercerNumeroVia } = direccion;
    const domicilio = `${tipoVia} ${primerNumeroVia} ${segundoNumeroVia} ${tercerNumeroVia}`;
    setUbicacion(domicilio);
  };

  const handleCreateEstablishment = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/createEstablishment`, {
          type: type,
          NIT: selectedEstablishment?.NIT,
          DV: selectedEstablishment.DV,
          business_name: selectedEstablishment.business_name,
          email: selectedEstablishment.email,
          url: selectedEstablishment.url,
          phone_number: selectedEstablishment.phone_number,
          image: selectedEstablishment.image,
          category_id: selectedEstablishment.category_id, 
          department: selectedDepartamento.label,
          city: selectedCiudad,
          address: ubicacion,
          additional_info: establishment.additional_info,
          contact_position: establishment.contact_position,
          contact_name: establishment.contact_name,
          contact_phone: establishment.contact_phone,
          contact_email: establishment.contact_email,
        }, {
          headers: {
            authorization: token,
          },
        });
        setOpen(true);
        setEstablishment(response.data);
        Swal.fire(
          '¡Bien hecho!',
          'Establecimiento creado exitosamente!',
          'success',
        );
        resetFields()
      } catch (error) {
        setOpen(true);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Hubo un error al crear el establecimiento. Verifique los campos y vuelva a intentarlo.',
        confirmButtonColor: '#035533',
      });
      setErrors(validationErrors);
    }
  };

  const handleUpdateEstablishment = async () => {
    const validationErrors = validateFormUpdate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/UpdateEstablishment/${branchId}`, {
          type: type,
          business_name: selectedBranch.business_name,
          email: selectedBranch.email,
          url: selectedBranch.url,
          phone_number: selectedBranch.phone_number,
          image: selectedBranch.image,
          department: selectedDepartamento.label,
          city: selectedCiudad,
          address: ubicacion,
          additional_info: establishment.additional_info,
          contact_position: establishment.contact_position,
          contact_name: establishment.contact_name,
          contact_phone: establishment.contact_phone,
          contact_email: establishment.contact_email,
        }, {
          headers: {
            authorization: token,
          },
        });
        resetFields()
        Swal.fire(
          'Actualización exitosa!',
          'Establecimiento actualizado exitosamente!',
          'success'
        );
        setOpen(true);
        setEstablishment(response.data);
      } catch (error) {
        setOpen(true);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Hubo un error al actualizar el establecimiento. Verifique los campos y vuelva a intentarlo.',
        confirmButtonColor: '#035533',
      });
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    if (selectedBranch) {
      setEstablishment(selectedBranch);
    }
  }, [selectedBranch]);

  return (
    <>
      <ThemeProvider theme={createTheme}>
        <CssBaseline />
        <GridBody >
          {headerVisibility !== 'headerVisibility' && (
            <GridHeaderII container xl={12} pb={selectedBranch ? 3: 3} style={{ marginTop: selectedBranch ? "-4.5rem" : "-9rem" }}>
              {selectedBranch ? <TittleModule >Actualización de sucursal</TittleModule> : <TittleModule >Gestión de sucursales</TittleModule>}
              <ProfileMenu />
            </GridHeaderII>
          )}

          <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={12} p={5}>
            <ComboBox360
              label="Establecimiento"
              type="establecimiento"
              selectedValue={selectedBranch}
              setSelectedValue={setSelectedEstablishment}
              error={errors.selectedcashier}
              helperText={errors.selectedcashier && errors.selectedcashier}
              setSelectedEstablishment={setSelectedEstablishment}
            />
          </Grid>

          <CardBody2>
            <Grid item className='GridHeader' mt={1}>
              <Typography className='TypographyHeader'>Domicilio</Typography>
              <LineDivider color="#035533" width="98%" />
            </Grid>
            <Box component="form" noValidate onSubmit={(e) => { e.preventDefault() }}>
              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} ml={0.1} spacing={2} mt={0.1}>
                <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.6} >
                  <ComboBox360 label="Departamento" selectedValue={selectedDepartamento} setSelectedValue={setSelectedDepartamento} onBlur={updateUbicacion}
                    error={errors.selectedDepartamento} helperText={errors.selectedDepartamento && errors.selectedDepartamento} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.8} xl={1.5} >
                  <ComboBox360 label="Ciudad" departamento={selectedDepartamento} selectedValue={selectedCiudad} setSelectedValue={setSelectedCiudad} onBlur={updateUbicacion}
                    error={errors.selectedCiudad} helperText={errors.selectedCiudad && errors.selectedCiudad} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.4}>
                  <StyledInputText size='small' name="tipoVia" required id="tipoVia" label="Tipo de vía"
                    value={direccion.tipoVia} onChange={(e) => setDireccion({ ...direccion, tipoVia: e.target.value })} onBlur={updateUbicacion}
                    error={errors.direccionTipoVia} helperText={errors.direccionTipoVia && errors.direccionTipoVia} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.8} xl={1.4}>
                  <StyledInputText size='small' name="primerNumeroVia" required id="primerNumeroVia" label="Primer número de vía"
                    value={direccion.primerNumeroVia} onChange={(e) => setDireccion({ ...direccion, primerNumeroVia: e.target.value })} onBlur={updateUbicacion}
                    error={errors.direccionPrimerNumeroVia} helperText={errors.direccionPrimerNumeroVia && errors.direccionPrimerNumeroVia} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.4}>
                  <StyledInputText size='small' name="segundoNumeroVia" required id="segundoNumeroVia" label="Segundo número de vía"
                    value={direccion.segundoNumeroVia} onChange={(e) => setDireccion({ ...direccion, segundoNumeroVia: e.target.value })} onBlur={updateUbicacion}
                    error={errors.direccionSegundoNumeroVia} helperText={errors.direccionSegundoNumeroVia && errors.direccionSegundoNumeroVia} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.4}>
                  <StyledInputText size='small' name="tercerNumeroVia" required id="tercerNumeroVia" label="Tercer número de vía"
                    value={direccion.tercerNumeroVia} onChange={(e) => setDireccion({ ...direccion, tercerNumeroVia: e.target.value })} onBlur={updateUbicacion}
                    error={errors.direccionTercerNumeroVia} helperText={errors.direccionTercerNumeroVia && errors.direccionTercerNumeroVia} />
                </Grid>
                <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={2} >
                  <StyledInputText size='small' name="quota" required id="quota" label="Información adicional"
                    value={establishment.additional_info} onChange={(e) => setEstablishment({ ...establishment, additional_info: e.target.value })}
                    error={errors.additional_info} helperText={errors.additional_info && errors.additional_info} />
                </Grid>
              </Grid>
            </Box>
          </CardBody2>

          <CardBody3 >
            <Grid item className='GridHeader' mt={1}>
              <Typography className='TypographyHeader'>Contactos establecimiento</Typography>
              <LineDivider color="#035533" width="98%" />
            </Grid>
            <Box component="form" noValidate onSubmit={(e) => { e.preventDefault() }} mt={1.5}>
              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} ml={0.3} spacing={2}>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2.9} >
                  <StyledInputText size="small" name="quota" required id="quota" label="Cargo" variant="outlined" value={establishment.contact_position}
                    onChange={(e) => setEstablishment({ ...establishment, contact_position: e.target.value })} error={errors.contact_position}
                    helperText={errors.contact_position && errors.contact_position} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2.9} >
                  <StyledInputText size="small" name="quota" required id="quota" label="Nombre completo" variant="outlined" value={establishment.contact_name}
                    onChange={(e) => setEstablishment({ ...establishment, contact_name: e.target.value })} error={errors.contact_name}
                    helperText={errors.contact_name && errors.contact_name} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2.9} >
                  <StyledInputText size="small" type="number" name="quota" required id="quota" label="Número de teléfono" variant="outlined" value={establishment.contact_phone}
                    onChange={(e) => setEstablishment({ ...establishment, contact_phone: e.target.value })} error={errors.contact_phone}
                    helperText={errors.contact_phone && errors.contact_phone} />
                </Grid>
                <Grid item xs={10.7} sm={5.5} md={2.9} lg={2.9} xl={2.9} >
                  <StyledInputText size="small" name="quota" required id="quota" label="Correo electrónico" variant="outlined" value={establishment.contact_email}
                    onChange={(e) => setEstablishment({ ...establishment, contact_email: e.target.value })} error={errors.contact_email ? true : false}
                    helperText={errors.contact_email ? errors.contact_email : ''} />
                </Grid>
              </Grid>
            </Box>
          </CardBody3>

          <GridBtn>
            <Grid container item xs={12} sm={1.5} md={1.5} lg={1.5} xl={1.5} mt={2}>
              <ButtonActiosn360 label="CREAR" className='buttonAction360' backgroundColor={branchId ? "gray" : "#035533"}
                disabled={branchId ? true : false} onClick={handleCreateEstablishment} />
            </Grid>
            {branchId &&
              <Grid container item xs={12} sm={1.5} md={1.5} lg={1.5} xl={1.5} mt={2}>
                <ButtonActiosn360 label="GUARDAR" className='buttonAction360' backgroundColor={'#035533'} onClick={handleUpdateEstablishment} />
              </Grid>
            }
          </GridBtn>
        </GridBody>
      </ThemeProvider>
    </>
  );
}