import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../../Contexts/AuthContext';
import { PUBLIC } from '../routes/path';
import React, { useEffect} from 'react';
export default function CashierRoute() {
    const {token, role, validate} = useAuthContext();
    useEffect(() => {
        if(token && role){
        validate(token, role)
        }
    }, [window.localStorage.getItem("role")]);
    if (token && role === "cajero" ) {
        return <Outlet />
    }

    return <Navigate to={PUBLIC} />;

}