import styled from "styled-components";
import { Box } from "@mui/material";

export const ModalEdit = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 42%;
  height: 25vh;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 2% 2% 0% 2%;
  }
  /* Dimensiones 125% */
  @media (max-width: 1496px) and (max-height: 716px) {
    width: 40%;
    height: 30vh !important;
  }
  @media (max-width: 900px) and (max-height: 716px) {
    width: 50%;
    height: 40vh !important;
  }

  /* Dimensiones 150% */
  @media (max-width: 1240px) and (max-height: 572px) {
    width: 50%;
    height: 35vh !important;
  }
  @media (max-width: 900px) and (max-height: 572px) {
    width: 50%;
    height: 45vh !important;
  }
`;

export const ModalActions = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: auto;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 2% 2% 0% 2%;
  }

  @media (max-width: 900px) {
    width: 58%;
  }
  @media (max-width: 600px) {
    width: 80%;
  }
`;
