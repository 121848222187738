import * as React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';

export const NumericFormatCustom = React.forwardRef(function NumericFormatCustom( props, ref ) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$"
    />
  );
});

NumericFormatCustom.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export const formatNumber = (number) => {
  return new Intl.NumberFormat('es-CO').format(number);
};