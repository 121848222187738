import React, { useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2'
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { BodyCard, ModalEdit } from './Styled';
import ImageIcon from '@mui/icons-material/Image';
import { Typography, Modal, Grid, InputAdornment } from '@mui/material';
import { useAuthContext } from '../../../../../Contexts/AuthContext';
import { NumericFormatCustom } from '../../../../../Utilities/NumericFormat';
import { LineDivider } from '../../../../../components/elements/lines/Styled';
import InputText2 from '../../../../../components/elements/Inputs/InputText2';
import { StyledInputText2 } from '../../../../../components/elements/Inputs/Styled';
import ButtonActiosn360 from '../../../../../components/elements/Buttons/ButtonActions360';
import ComboBox360 from '../../../../../components/elements/ComboBox/comboBox360';
import MultipleSelect from '../../../../../components/elements/ComboBox/multiSelect';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
export default function ModalUpdatePublication({ selectedPublicationId, isModalOpen, setIsModalOpen, initialData, }) {
  const tokenDec = localStorage.getItem('token');
  const decodedToken = jwtDecode(tokenDec);
  const { token } = useAuthContext();
  const { id } = selectedPublicationId;
  const [file, setFile] = useState(initialData.image);
  const [subcategories, setSubcategories] = useState([]);
  const [discount, setDiscount] = useState(initialData.discount);
  const [categories, setCategories] = useState(initialData.category);
  const [selectedImage, setSelectedImage] = useState(initialData.image);
  const [Price, setPrice] = useState({ numberformat: initialData.price });
  const [Description, setDescription] = useState(initialData.description);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setFile(file);
    }
  };

  const prefillFields = () => {
    setDescription(initialData.description);
    setPrice({ numberformat: initialData.price });
  };

  const handleSubcategoriesChange = (selectedSubcategories) => {
    setSubcategories(selectedSubcategories);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formData = new FormData();
  formData.append("image", file);
  formData.append("promotion_name", Description);
  formData.append("promotion_price", Price.undefined);
  formData.append("establishment_id", decodedToken.establishment_id);
  formData.append("discount", discount);
  formData.append("subcategory_id", subcategories);
  
  const UpdatePublication = async () => {
    const formData = new FormData();
    if (categories !== '') formData.append("category_id", categories);
    if (subcategories !== '') formData.append("subcategory_id", subcategories);
    if (Description !== '') formData.append("promotion_name", Description);
    if (file) formData.append("image", file);
    if (Price !== '') formData.append("promotion_price", Price.numberformat);
    if (discount !== '') formData.append("discount", discount);
    formData.append("establishment_id", decodedToken.establishment_id);
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/updatePromotion/${selectedPublicationId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "authorization": token,
        },
      })
      handleCloseModal()
      Swal.fire({
        icon: "success",
        title: "Publicación exitosa",
        text: "Se creo exitosamente tu nueva publicidad!",
        confirmButtonColor: '#035533',
      })
      window.localStorage.setItem("setResetPublication", true);
      window.location.reload()
      setTimeout(() => {
        window.localStorage.removeItem("setResetPublication", false);
      }, 2000)
    } catch (error) {
      handleCloseModal()
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ocurrió un error al intentar crear la publicidad!",
        confirmButtonColor: '#035533',
      });
    }
}



return (
  <Modal open={isModalOpen} onClose={handleCloseModal} onEnter={prefillFields} >
    <ModalEdit className="ModalStyle">
      <Grid item className='GridHeader' xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography className='TypographyHeader'>Editar producto</Typography>
        <LineDivider color="#035533" width="96%" />
      </Grid>
      <BodyCard >
        <Grid className='Grid'>
          <label htmlFor={`imageInput-${id}`} className='containerLabel'>
            {selectedImage ? (
              <img src={selectedImage} alt="Selected" className='img' />
            ) : selectedPublicationId.src ? (
              <img src={`${process.env.REACT_APP_API_URL}/${selectedPublicationId.src}`} alt="Selected" className='img' />
            ) :
              <ImageIcon className='icon' />
            }
            <VisuallyHiddenInput id={`imageInput-${id}`} type="file" onChange={handleImageChange} />
          </label>
        </Grid>
        <Grid container item xs={12} sm={8.8} md={8.8} lg={8.8} xl={8.8} gap={1} className='containerFormPublication' mt={1}>
          <Grid container item sx={{ display: "flex", justifyContent: "space-between" }}>
            <Grid width={"40%"}>
              <ComboBox360 label="Categorias" selectedValue={categories} setSelectedValue={setCategories} />
            </Grid>
            <Grid width={"55%"}>
              <MultipleSelect label={'Subcategorias'} onChange={handleSubcategoriesChange} value={subcategories} idCategory={categories} />
            </Grid>
          </Grid>

          <Grid width={"100%"}>
            <StyledInputText2 required={true} size='small' label="Nombre producto o servicio" value={Description} onChange={(e) => setDescription(e.target.value)} />
          </Grid>

          <Grid container item sx={{ display: "flex", justifyContent: "space-between" }}>
            <Grid width={"55%"}>
              <StyledInputText2 required size='small' label="Precio" value={Price.numberformat} onChange={(e) => setPrice({ ...Price, [e.target.name]: e.target.value })} id="formatted-numberformat-input"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  value: Price.numberformat || '',
                }}
              />
            </Grid>
            <Grid width={"40%"}>
              <StyledInputText2 required size='small' label="Descuento" value={discount} onChange={(e) => setDiscount(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </BodyCard>

      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} textAlign={"end"} justifyContent={"center"} mt={1}>
        <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} >
          <ButtonActiosn360 label="Guardar" onClick={UpdatePublication} backgroundColor={"#035533"} />
        </Grid>
      </Grid>
    </ModalEdit>
  </Modal>
);
}