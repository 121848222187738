import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RegistrationEstablishment from './RegistrationEstablishment';
import RegistrationBranch from './RegistrationBranch';
import { Grid } from '@mui/material';
import { GridHeaderII } from '../moduleDashboard/Styled';
import { TittleModule } from './Styled';
import ProfileMenu from '../../../Components/menu/Profile';
import UsersRegister from '../users/RegisterUser';

export default function StepRegisterEstablishment() {
  const [numBranchForms, setNumBranchForms] = React.useState(1); // Estado para el número de formularios de sucursal
  const [numUserForms, setNumUserForms] = React.useState(1); // Estado para el número de formularios de usuario

  // Función para agregar un formulario de sucursal
  const addBranchForm = () => {
    setNumBranchForms(num => num + 1);
  };

  // Función para eliminar un formulario de sucursal
  const removeBranchForm = () => {
    if (numBranchForms > 1) {
      setNumBranchForms(num => num - 1);
    }
  };

  // Función para agregar un formulario de usuario
  const addUserForm = () => {
    setNumUserForms(num => num + 1);
  };

  // Función para eliminar un formulario de usuario
  const removeUserForm = () => {
    if (numUserForms > 1) {
      setNumUserForms(num => num - 1);
    }
  };

  return (
    <div>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <GridHeaderII container xl={12}>
          <TittleModule>Gestión de establecimiento</TittleModule>
          <ProfileMenu />
        </GridHeaderII>
      </Grid>
      <Accordion style={{ marginTop: "2rem" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" sx={{ color: '#035533', fontWeight: 500 }}>
          Registro de establecimiento.
        </AccordionSummary>
        <AccordionDetails>
          <RegistrationEstablishment headerVisibility={'headerVisibility'} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header" sx={{ color: '#035533', fontWeight: 500 }} >
          Registro de sucursales
        </AccordionSummary>

        <AccordionDetails>
          {[...Array(numBranchForms)].map((_, index) => (
            <>
              <IconButton onClick={addBranchForm} color="success">
                <AddIcon />
              </IconButton>
              <IconButton onClick={removeBranchForm} color="error" disabled={numBranchForms === 1}>
                <DeleteIcon />
              </IconButton>
              <hr/>
              <RegistrationBranch key={index} headerVisibility={'headerVisibility'} />
            </>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header" sx={{ color: '#035533', fontWeight: 500 }} >
          Registro de personal de establecimientos y/o sucursales
        </AccordionSummary>
        <AccordionDetails>
          {[...Array(numUserForms)].map((_, index) => (
            <>
              <IconButton onClick={addUserForm} color="success">
                <AddIcon />
              </IconButton>
              <IconButton onClick={removeUserForm} color="error" disabled={numUserForms === 1}>
                <DeleteIcon />
              </IconButton>
              <hr/>
              <UsersRegister key={index} headerVisibility={'headerVisibility'} />
            </>
          ))}
        </AccordionDetails>
      </Accordion>
    </div >
  );
}
