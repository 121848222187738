import styled from 'styled-components';
import TextField from '@mui/material/TextField';


export const MaterialInput = styled(TextField)`
  width: 100%;
  @media (max-width: 414px) {
    width: 100%;
  }
`;

export const StyledInputText = styled(MaterialInput)({
  '& label.Mui-focused, & label': {
    color: '#035533;',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#000000',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#047445',
    },
    '&:hover fieldset': {
      borderColor: '#2e2e2e',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#035533;',
    },
  },
});


export const StyledInputText2 = styled(StyledInputText)`
  width: 100%;

  @media (max-width: 414px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  input[type="number"]::-webkit-inner-spin-button {
    display: none;
  }
`;

export const StyledInputPass = styled(StyledInputText)`
  width: 100%;

  @media (max-width: 414px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;