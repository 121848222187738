import React from 'react';
import { StyledTittle } from './Styled';

function Tittle({ text, mt, ml }) {
  return (
    <StyledTittle variant="h4" mt={mt} ml={ml} sx={{
      '@media (max-width: 560px)': {
        fontSize: '30px !important'
      },
      '@media (max-width: 500px)': {
        fontSize: '25px !important'
      },
      '@media (max-width: 440px)': {
        fontSize: '20px !important',
        marginTop: '5px'
      }
    }}>
      {text}
    </StyledTittle>
  );
}

export default Tittle;
