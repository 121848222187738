import styled from "styled-components";
import { Grid, Box } from "@mui/material";

export const BoxContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .containerHeader {
    border-radius: 10px 10px 10px 10px;
  }
`;

export const ContentTableHome = styled.div`
  display: grid;
  width: 70%;
  height: auto;
  box-sizing: border-box;
  overflow: auto;

  @media (max-width: 1496px) and (max-height: 716px) {
    margin-top: -20px;
  }
  @media (max-width: 900px) and (max-height: 716px) {
    width: 90%;
  }
`;

export const ContainerBodyVoucher = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  .containerGrid {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .grid {
    width: 100%;
  }
`;

export const DivTransactions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .gridDetailsVoucher {
    width: 40%;
    height: 50%;
    justify-content: center;

    @media (max-width: 1750px) {
      width: 60% !important;
    }
    @media (max-width: 1200px) {
      width: 70% !important;
    }
    @media (max-width: 1014px) {
      width: 90% !important;
    }
  }

  .grid-button {
    display: flex;
    flex-direction: column;

    @media (max-width: 1496px) and (max-height: 716px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    @media (max-width: 1014px) {
      width: 90% !important;
    }
  }
`;

export const ContainerHeaderVoucher = styled.div`
  width: 100%;
  height: 25vh;
  background-color: #035533;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -28px;
  border-radius: 0rem 0rem 1.5rem 1.5rem;

  .button:hover {
    background-color: #035533;
  }

  @media (max-width: 768px) {
    width: 100% !important;
    height: 25vh;
    border-radius: 0rem 0rem 1.5rem 1.5rem;
  }

  @media (max-width: 414px) {
    width: 100%;
    height: 18vh;
    border-radius: 0rem 0rem 1.5rem 1.5rem;
    margin-top: -30px;
  }
`;

export const BodyHeaderVoucher = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5%;
  justify-content: space-around;

  .containerButton {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 1496px) and (max-height: 716px) {
      margin-top: 5rem;
    }
  }

  .icon {
    font-size: 35px !important;
    color: white;
  }
`;

export const ModuleStoreVoucher = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;

  .containerModuleStore {
    display: flex;
    flex-direction: column !important;
  }

  @media (max-width: 414px) {
    width: 100%;
  }
`;

export const ItemVoucher = styled(Grid)`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column !important;
  align-items: flex-start;
`;

export const Voucher = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ConfirmationVoucher = styled(Grid)`
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
`;

export const ImgCheck = styled.img`
  width: 175px;
  height: 100px;

  @media (max-width: 600px) {
    width: 100px;
    height: 100px;
  }
`;

export const BodyVoucher = styled(Grid)`
  display: flex;
  justify-content: center;
  background-color: transparent !important ;

  .containerTittle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .containerValue {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .bold {
    font-weight: bold !important;
    font-size: 20px;
  }
`;

/* -------- List tRnasactions ------------ */

export const DivListTransactions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalView = styled(Box)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: auto;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 1% 2% 0% 2%;
  }

  .bodyCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1% 0% 1.5% 2%;
  }

  .bodyCard2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1% 0% 1.5% 2%;
  }

  .blockItem {
    display: flex;

    .start {
      display: flex;
      justify-content: start;
    }
    .end {
      display: flex;
      justify-content: end;
    }
  }

  .containerItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 1200px) {
    width: 70vh;
  }

  @media (max-width: 900px) {
    width: 80vh;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const ContainerHeaderList = styled.div`
width: 100%;
height: 7vh;
background-color: #035533;
display: flex;
align-items: center;
justify-content: center;
padding-top: 10px;
border-radius: 0rem 0rem 1.5rem 1.5rem;

  .button:hover {
    background-color: #037847;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
    border-radius: 0rem 0rem 0rem 0rem;
  }
`;
export const ContainerHeaderListHistory = styled.div`
width: 100%;
height: 6.5vh;
background-color: #037847;
display: flex;
align-items: center;
justify-content: center;
padding-bottom: 10px;

  .button:hover {
    background-color: #037847;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 100%;
    border-radius: 0rem 0rem 0rem 0rem;
  }
`;

export const BodyHeaderList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .icon {
    font-size: 35px;
    color: #ffffff;
  }
`;

export const ListTransactions = styled.div`
  display: flex;
  flex-direction: column !important;
  width: 100%;

  .boxTransaction {
    width: 55%;
    height: 80%;
    display: flex;
    justify-content: center;

    .SummaryTransaction {
      display: flex;
      flex-direction: row !important;
      justify-content: center;
      width: 100%;
      margin: 5% 0% 2% 0% !important;
      align-items: center;

      .tittleTransaction2 {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        width: 60%;

        .hours {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    @media (max-width: 1430px) {
      width: 70%;
    }
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  .boxButton {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;

    .date {
      display: flex;
    }

    Button {
      border: solid 1px gray;

      .tittleTransaction {
        display: flex;
        flex-direction: column;
        width: 55%;
        align-items: flex-start;
        color: #037847;

        .see {
          display: flex;
          justify-content: start;
          align-items: center;
        }
      }
      .tittleTransaction2 {
        display: flex;
        flex-direction: column;
        width: 45%;
        align-items: flex-end;
        color: #037847;

        .see {
          display: flex;
          justify-content: end;
          align-items: center;

          @media (max-width: 400px) {
            RemoveRedEyeOutlinedIcon {
              font-size: 10px !important;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1430px) {
    width: 100%;
  }
`;

export const ContainerDetails = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  height: 55vh;

  @media (max-width: 1430px) {
    width: 80%;
  }
`;

export const BodyDetailBuy = styled.div`
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  height: auto;
  width: 60%;

  @media (max-width: 1650px) {
    width: 70%;
  }
  @media (max-width: 1496px) {
    margin-top: 2rem;
  }
  @media (max-width: 1240px) {
    margin-top: 7rem;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const PriceDetailBuy = styled(Grid)`
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  width: 100%;
  height: auto;
  align-items: center;
`;

export const DetailBuy = styled(Grid)`
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  width: 100%;
  padding-top: 8%;
  align-items: center;
`;
