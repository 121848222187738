import React from 'react';
import { StyledTypography } from './Styled';

function TypographyH6({ text, mt, fontColor, sx, bold }) {
  return (
    <StyledTypography variant='h6' mt={mt} style={sx} sx={{
      '@media (max-width: 414px)': {
        fontSize: '15px !important',
      }, fontWeight: { bold }
    }} color={fontColor}>
      {text}
    </StyledTypography>
  );
}

export default TypographyH6;