import React from 'react';
import { StyledTypography } from './Styled';

function TypographyH6II({ text, mt, fontColor, fontWeight }) {
  return (
    <StyledTypography variant='h6' mt={mt} sx={{
      '@media (max-width: 600px)': {
        fontSize: '17px !important',
      },
      '@media (max-width: 500px)': {
        fontSize: '15px !important',
      },
      '@media (max-width: 430px)': {
        fontSize: '15px !important',
      },
      '@media (max-width: 376px)': {
        fontSize: '13px !important',
      },
      '@media (max-width: 321px)': {
        fontSize: '10px !important',
      },
      '@media (max-width: 1240px) and (max-height: 572px)': {
        fontSize: '11px !important',
      },
      fontWeight: { fontWeight }
    }} color={fontColor}>
      {text}
    </StyledTypography>
  );
}

export default TypographyH6II;