import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import Swal from 'sweetalert2';
import axios from 'axios';
import ReplayIcon from '@mui/icons-material/Replay';
import { GridBtn, InputHidden, ModalActions } from './Style';
import useMediaQuery from '@mui/material/useMediaQuery';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PaymentsIcon from '@mui/icons-material/Payments';
import { GridHeaderII } from '../../moduleDashboard/Styled';
import ModalViewDetailsSale from '../modals/ViewSaleDetails';
import ProfileMenu from '../../../../Components/menu/Profile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import FormatPrice from '../../../../../Utilities/FormatPrices';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { TittleModule } from '../../registrationEstablishment/Styled';
import InputDate from '../../../../../components/elements/Inputs/InputDate';
import { LineDivider } from '../../../../../components/elements/lines/Styled';
import { Box, IconButton, Grid, Modal, Tooltip, Typography } from '@mui/material';
import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';
import ButtonActiosn360 from '../../../../../components/elements/Buttons/ButtonActions360';
import { useAuthContext } from '../../../../../Contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';

const SalesEstablishment = () => {
  const [salesEstablishment, setSalesEstablishment] = useState([]);
  const [selectedSaleId, setSelectdSaleId] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState('');
  const { token, role } = useAuthContext();
  const [searchedRows, setSearchedRows] = useState([]);
  const [keysObjectsArray, setKeysObjectsArray] = useState([]);
  const [rowSelection, setRowSelection] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(true);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const isSmallScreen = useMediaQuery('(max-width: 1050px)');
  const tokenDec = localStorage.getItem('token');
  const decodedToken = jwtDecode(tokenDec);

  if (typeof (fechaInicio) === 'string') {
    if (fechaInicio.length === 0) {
      setFechaInicio(null)
    }
  }

  if (typeof (fechaFinal) === 'string') {
    if (fechaFinal.length === 0) {
      setFechaFinal(null)
    }
  }

  const handleViewModal = (idSale) => {
    setSelectdSaleId(idSale);
    setIsModalOpen(true);
  };


  const fetchDateSales = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/GetPurchaseByTwoDatesEstablishment`, {
        firtsDate: fechaInicio,
        secondDate: fechaFinal,
        NIT: decodedToken.NIT,
      },{
        headers: {
          Authorization: token,
        }})

      if (response.data.length > 0) {
        const sortedCompras = response.data.sort((a, b) => new Date(b.purchase_date) - new Date(a.purchase_date));
        setSalesEstablishment(sortedCompras);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Hubo un error al traer la información. No hay datos disponibles.',
        confirmButtonColor: '#035533'
      });
    }
  };

  const requestPayment = async (idSale, status) => {
    let title = '';
    let text = '';
    let botton='';
    if (status === 'solicitud de pago') {
      title = "¿Seguro que quiere cancelar la solicitud de pago?";
      text = "Esta acción cancelará la solicitud de pago de este movimiento.";
      botton = "Cancelar solicitud de pago lenddi";

    } else if (status === 'pendiente por pago') {
      title = "¿Quiere solicitar el pago de este movimiento?";
      text = "Usted esta solicitando el pago de este movimiento a Lenddi.";
      botton = "Solicitar pago lenddi";

    }
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#035533",
      cancelButtonColor: "#d33",
      confirmButtonText: botton,
      cancelButtonText: "Cancelar"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/changePurchasePaymentStatus/${idSale}`, {}, {
            headers: {
              Authorization: token,
            },
          }
          )
          if (response.data.purchase_payment_status !== 10) {
            Swal.fire({
              title: "¡Cancelación de solicitud de pago!",
              text: "Se ha cancelado la slicitud de pago de esta venta a Lenddi correctamente.",
              icon: "success",
              confirmButton: "#035533",
            });
          } else {
            Swal.fire({
              title: "¡Pago solicitado!",
              text: "El pago de esta venta fue solicitada a Lenddi correctamente.",
              icon: "success",
              confirmButton: "#035533",
            });
          }
          handleReload()
        } catch (error) {
          if (error.response.data === 'Solo se puede cambiar pendiente de pago') {
            Swal.fire({
              title: "¡Solicitud declinada!",
              text: "Verifique que los movimientos se encuentren en solicitud de pago o pendiente por pago Lenddi.",
              icon: "error",
              confirmButton: "#035533",
            });
          }
        }
      }
    });
  };
  const requestPaymentMassive = async () => {
    Swal.fire({
      title: "¿Quiere pagar este movimiento ?",
      text: "Esta acción no se puede revertir, automatcamente realizará el pago",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#035533",
      cancelButtonColor: "#d33",
      confirmButtonText: "Pagar movimiento",
      cancelButtonText: "Cancelar"
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.put(`${process.env.REACT_APP_API_URL}/updatePurchase`, [keysObjectsArray], {
            headers: {
              authorization: token,
            },
          });
          Swal.fire({
            title: "¡Solicitud de pago Lenddi efectuada!",
            text: "Se ha solicitado un pago Lenddi correctamente.",
            icon: "success",
            confirmButton: "#035533",
          });
          handleReload()
        } catch (error) {
          if (error.response.data === 'Solo se puede cambiar pendiente de pago') {
            Swal.fire({
              title: "¡Solicitud declinada!",
              text: "Verifique que los movimientos se encuentren en solicitud de pago o pendiente por pago Lenddi.",
              icon: "error",
              confirmButton: "#035533",
            });
          }
        }
      }
    });
  };


  const handleExportAllData = () => {
    exportData(salesEstablishment);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    fetchDateSales();
  };

  const handleOpenModal = () => {
    setModalOpen(true)
    fetchDateSales();
  };

  const handleReload = () => {
    fetchDateSales()
  }

  const searchRows = () => {
    if (salesEstablishment && Array.isArray(salesEstablishment) && salesEstablishment.length > 0) {
      const filteredData = salesEstablishment.filter((row) => {
        return (
          (row.fecha_compra && row.fecha_compra.includes(searchCriteria)) ||
          (row.id_compra && row.id_compra.toString().includes(searchCriteria)) ||
          (row.factura && row.factura.toString().includes(searchCriteria)) ||
          (row.cliente && row.cliente.includes(searchCriteria)) ||
          (row.valor_compra && row.valor_compra.toString().includes(searchCriteria)) ||
          (row.cuotas && row.cuotas.toString().includes(searchCriteria)) ||
          (row.Estado && row.Estado.includes(searchCriteria)) ||
          (row.Estado_pago && row.Estado_pago.includes(searchCriteria)) ||
          (row.establecimiento && row.establecimiento.includes(searchCriteria))
        );
      });

      setSearchedRows(filteredData);
      exportData(filteredData);
    }
  };

  const exportData = (data) => {
    if (data.length > 0) {
      const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
      });
      const csv = generateCsv(csvConfig)(data);
      download(csvConfig)(csv);
    }
  };

  const applyFilters = () => {
    if (searchCriteria === '') {
      return salesEstablishment;
    } else if (salesEstablishment && Array.isArray(salesEstablishment) && salesEstablishment.length > 0) {
      return salesEstablishment.filter((row) => {
        return (
          (row.factura && row.factura.toLowerCase().toString().includes(searchCriteria)) ||
          (row.cliente && row.cliente.toLowerCase().includes(searchCriteria)) ||
          (row.Estado.toLowerCase() && row.Estado.toLowerCase().includes(searchCriteria)) ||
          (row.Estado_pago.toLowerCase() && row.Estado_pago.toLowerCase().includes(searchCriteria)) ||
          (row.establecimiento.toLowerCase() && row.establecimiento.toLowerCase().includes(searchCriteria)) ||

          (row.factura && row.factura.toUpperCase().includes(searchCriteria)) ||
          (row.cliente && row.cliente.toUpperCase().includes(searchCriteria)) ||
          (row.Estado.toUpperCase() && row.Estado.toUpperCase().includes(searchCriteria)) ||
          (row.Estado_pago.toUpperCase() && row.Estado_pago.toUpperCase().includes(searchCriteria)) ||
          (row.establecimiento.toUpperCase() && row.establecimiento.toUpperCase().includes(searchCriteria)) ||

          (row.fecha_compra && row.fecha_compra.includes(searchCriteria)) ||
          (row.id_compra && row.id_compra.toString().includes(searchCriteria)) ||
          (row.factura && row.factura.toString().includes(searchCriteria)) ||
          (row.cliente && row.cliente.includes(searchCriteria)) ||
          (row.valor_compra && row.valor_compra.toString().includes(searchCriteria)) ||
          (row.cuotas && row.cuotas.toString().includes(searchCriteria)) ||
          (row.Estado.toString() && row.Estado.toString().includes(searchCriteria)) ||
          (row.Estado_pago.toString() && row.Estado_pago.toString().includes(searchCriteria)) ||
          (row.establecimiento && row.establecimiento.includes(searchCriteria))
        );
      });
    }
    return [];
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id_compra',
        header: 'Ref. compra',
        size: 5,
      },
      {
        accessorKey: 'factura',
        header: 'Ref. fact',
        size: 10,
      },
      {
        accessorKey: 'cliente',
        header: 'Cliente',
        size: 230,
        muiTableHeadCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'valor_compra',
        header: 'Valor compra',
        size: 150,
        Cell: ({ cell, row }) => (
          <Box component="span"
            sx={(theme) => ({
              fontWeight: 'bold',
              p: '0.25rem',
              display: "flex",
              justifyContent: "end",
              marginRight: '25px'
            })}
          >
            {<FormatPrice price={cell.getValue()} />}
          </Box>
        ),
      },
      {
        accessorKey: 'establecimiento',
        header: 'Cajero y sucursal',
      },
      {
        accessorKey: 'fecha_compra',
        header: 'Fecha',
        size: 10,
      },
      {
        accessorKey: 'Estado',
        header: 'Estado venta',
        size: 5,
        Cell: ({ cell, row }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color:
                row.original.Estado === 'Exitoso'
                  ? theme.palette.success.dark
                  : row.original.Estado === 'pendiente'
                    ? 'rgb(255, 174, 0)'
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              fontWeight: 'bold',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'Estado_pago',
        header: 'Estado pago Lenddi',
        size: 5,
        Cell: ({ cell, row }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color:
                row.original.Estado_pago === 'pago'
                  ? theme.palette.success.dark
                  : row.original.Estado_pago === 'pendiente por pago' || row.original.Estado_pago === 'No aplica'
                    ? theme.palette.error.dark
                    : 'rgb(255, 174, 0)',
              borderRadius: '0.25rem',
              fontWeight: 'bold',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        size: 1,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            <Tooltip title="Ver detalle">
              <IconButton style={{ color: '#035533' }} onClick={() => handleViewModal(row.original.id_compra)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={ row.original.Estado_pago !== 'solicitud de pago' ? "Solicitar pago Lenddi" : "Cancelar solicitud de pago Lenddi" }>
              <IconButton style={{ color: row.original.Estado_pago === 'pago' ? 'gray' : '#035533' }} disabled={row.original.Estado_pago === 'pago'} onClick={() => requestPayment(row.original.id_compra, row.original.Estado_pago)}>
                <PaymentsIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    fetchDateSales();

    const keysArray = Object.keys(rowSelection);
    const keysObjectsArray = keysArray.map((key) => ({ purchase_id: key }));
    setKeysObjectsArray(keysObjectsArray);
    setButtonDisabled(keysArray.length === 0);
    if (role === 'administradorEstablishment') {
      requestPaymentMassive();

    }
  }, [rowSelection]);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <ModalActions className="ModalStyle">
          <Grid item className='GridHeader' xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className='TypographyHeader'>Filtrar movimientos de ventas por periodo y sucursal</Typography>
            <LineDivider color="#035533" width="96%" />
          </Grid>

          <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} m={2}>
            <InputDate label={""} value={fechaInicio} onChange={(e) => setFechaInicio(e.target.value)} />
          </Grid>
          <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} m={2}>
            <InputDate label={""} value={fechaFinal} onChange={(e) => setFechaFinal(e.target.value)} />
          </Grid>
          <Grid item m={2} textAlign={"end"}>
            <ButtonActiosn360 label="Buscar ventas" backgroundColor={"#035533"} onClick={handleCloseModal} />
          </Grid>
        </ModalActions>
      </Modal>

      <div>
        <GridHeaderII container xl={12} pb={1}>
          <TittleModule >Lista de ventas por periodo</TittleModule>
          <ProfileMenu />
        </GridHeaderII>
        <Grid item display={"flex"} xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container textAlign={"end"} item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} m={1}>
              <ButtonActiosn360 label="Filtrar por fechas" onClick={handleOpenModal} backgroundColor={'#035533'} startIcon={<ContentPasteSearchRoundedIcon />} />
            </Grid>
          </Grid>
        </Grid>

        <InputHidden type="text" placeholder="Criterio de búsqueda (Fecha)" value={searchCriteria} onChange={(e) => setSearchCriteria(e.target.value)} />
        <MaterialReactTable columns={columns} data={searchedRows.length > 0 ? searchedRows : applyFilters()} enableGlobalFilterModes
          initialState={{
            showGlobalFilter: false,
            globalFilter: searchCriteria,
            density: 'compact'
          }}
          renderTopToolbarCustomActions={() => (
            <Grid item display={"flex"} xs={3} sm={3} md={3} lg={3} xl={3}>
              <Tooltip arrow placement="bottom" title="Reload">
                <IconButton onClick={() => handleReload()}>
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
              <Grid item m={1}>
                <ButtonActiosn360 label={isSmallScreen ? "..." : "Tabla"} onClick={handleExportAllData} backgroundColor={'#035533'} startIcon={<FileDownloadIcon />} />
              </Grid>
              <Grid item m={1}>
                <ButtonActiosn360 label={isSmallScreen ? "..." : "Buscados"} onClick={searchRows} backgroundColor={'#035533'} startIcon={<SystemUpdateAltIcon />} />
              </Grid>
            </Grid>
          )}
          positionGlobalFilter="right"
          columnResizeMode="onChange"
          positionActionsColumn="last"
          enableRowSelection
          getRowId={(row) => row.id_compra}
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          muiSearchTextFieldProps={{
            placeholder: `Buscar en ${salesEstablishment.length} registros`,
            sx: { minWidth: '100px' },
            variant: 'standard',
            value: searchCriteria,
            onChange: (e) => {
              setSearchCriteria(e.target.value);
            },
          }}
          onFilterChange={(newFilter) => {
            setSearchCriteria(newFilter);
          }}
        />

        <GridBtn container item xs={12} sm={12} md={12} lg={12} xl={12} height={"6vh"} mt={2} >
          <Grid container item xs={5} sm={4} md={2} lg={2} xl={1.5} ml={1}>
            <ButtonActiosn360 label="Solicitar pago lenddi" className='buttonAction360' onClick={requestPaymentMassive} backgroundColor={buttonDisabled ? "grey" : "#035533"} disabled={buttonDisabled} />
          </Grid>
        </GridBtn>

        {selectedSaleId !== null && (
          <ModalViewDetailsSale isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedSaleId={selectedSaleId} />
        )}

      </div>
    </Grid>
  );
};

export default SalesEstablishment;