import styled from "styled-components";
import { Card, Typography } from "@mui/material";

export const TittleModule = styled(Typography)`
  margin: 0px 0px 10px 18px !important;
  font-size: 13px;
  color: #035533;
  font-weight: 550 !important;
  display: flex;
  align-items: center;
  text-align: center;
  height: 50px;
`;

export const CardBody = styled(Card)`
  margin-bottom: 2%;
  height: 16vh;
  width: 100%;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 2% 2% 0% 2%;
  }

  .bodyCard {
    display: flex;
    align-items: center;
    margin-left: 0.5px;
  }

  @media (max-width: 1535px) and (max-height: 931px),
    (max-width: 1200px) and (max-height: 931px),
    (max-width: 900px) and (max-height: 931px) {
    width: 100%;
    height: 30vh;
  }

  @media (max-width: 600px) and (max-height: 931px) {
    width: 100%;
    height: 45vh;
  }

  @media (max-width: 414px) and (max-height: 931px) {
    width: 100%;
    height: 45vh;
  }
`;

export const CardTable = styled(Card)`
  height: auto;
`;
