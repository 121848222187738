import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ModalView2 } from './Styled';
import ReplayIcon from '@mui/icons-material/Replay';
import useMediaQuery from '@mui/material/useMediaQuery';
import { GridHeaderII } from '../../moduleDashboard/Styled';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalViewDetailsSale from './ViewSaleDetails';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import FormatPrice from '../../../../../Utilities/FormatPrices';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { TittleModule } from '../../registrationEstablishment/Styled';
import { Box, IconButton, Grid, Modal, Tooltip } from '@mui/material';
import ButtonActiosn360 from '../../../../../components/elements/Buttons/ButtonActions360';

export default function ModalViewDetailsSaleBranch({ isModalOpenTransactions, setIsModalOpenTransactions, selectedSalesId, filterDataEstablishment, data }) {
  const [salesBranch, setSalesBranch] = useState([]);
  const [selectedSaleId, setSelectdSaleId] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [searchedRows, setSearchedRows] = useState([]);
  const [/* dataReview */, setDataReview] = useState(data)
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [fechaInicio, setFechaInicio] = useState(filterDataEstablishment.fechaInicio || null);
  const [fechaFinal, setFechaFinal] = useState(filterDataEstablishment.fechaFinal || null);
  const [branch, setBranch] = useState(selectedSalesId || null);
  const isSmallScreen = useMediaQuery('(max-width: 1050px)');

  if (typeof (fechaInicio) === 'string') {
    if (fechaInicio.length === 0) {
      setFechaInicio(null)
    }
  }

  if (typeof (fechaFinal) === 'string') {
    if (fechaFinal.length === 0) {
      setFechaFinal(null)
    }
  }

  if (typeof (establishment) === 'string') {
    if (branch.length === 0) {
      setBranch(null)
    }
  }



  const handleCloseModalSaleEstablishment = () => {
    fetchDateSales()
    setIsModalOpenTransactions(false);
    setSalesBranch(salesBranch)
    setDataReview()
    handleReload()
  };

  const handleExportAllData = () => {
    exportData(salesBranch);
  };

  const fetchDateSales = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/GetPurchaseByTwoDatesAndEstId`, {
        firtsDate: filterDataEstablishment.fechaInicio || null,
        secondDate: filterDataEstablishment.fechaFinal || null,
        establishment_id: selectedSalesId || null,
      });
      if (response.data.length > 0) {
        const sortedCompras = response.data.sort((a, b) => new Date(b.purchase_date) - new Date(a.purchase_date));
        setSalesBranch(sortedCompras);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Hubo un error al traer la información. No hay datos disponibles.',
        confirmButtonColor: '#035533',
      });
    }
  };

  const handleOpenModal = (idSale) => {
    setSelectdSaleId(idSale);
    setIsModalOpen(true);
    handleReload()
  };

  const handleReload = () => {
    fetchDateSales()
  }

  useEffect(() => {
    setBranch(selectedSalesId);
    fetchDateSales();
  }, [selectedSalesId]);

  const searchRows = () => {
    if (salesBranch && Array.isArray(salesBranch) && salesBranch.length > 0) {
      const filteredData = salesBranch.filter((row) => {
        return (
          (row.fecha_compra && row.fecha_compra.includes(searchCriteria)) ||
          (row.id_compra && row.id_compra.toString().includes(searchCriteria)) ||
          (row.factura && row.factura.toString().includes(searchCriteria)) ||
          (row.cliente && row.cliente.includes(searchCriteria)) ||
          (row.valor_compra && row.valor_compra.toString().includes(searchCriteria)) ||
          (row.cuotas && row.cuotas.toString().includes(searchCriteria)) ||
          (row.Estado && row.Estado.includes(searchCriteria)) ||
          (row.establecimiento && row.establecimiento.includes(searchCriteria))
        );
      });

      setSearchedRows(filteredData);
      exportData(filteredData);
    }
  };

  const exportData = (data) => {
    if (data.length > 0) {
      const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
      });
      const csv = generateCsv(csvConfig)(data);
      download(csvConfig)(csv);
    }
  };

  const applyFilters = () => {
    if (searchCriteria === '') {
      return salesBranch;
    } else if (salesBranch && Array.isArray(salesBranch) && salesBranch.length > 0) {
      return salesBranch.filter((row) => {
        return (
          (row.factura && row.factura.toLowerCase().toString().includes(searchCriteria)) ||
          (row.cliente && row.cliente.toLowerCase().includes(searchCriteria)) ||
          (row.Estado.toLowerCase() && row.Estado.toLowerCase().includes(searchCriteria)) ||
          (row.establecimiento.toLowerCase() && row.establecimiento.toLowerCase().includes(searchCriteria)) ||

          (row.factura && row.factura.toUpperCase().includes(searchCriteria)) ||
          (row.cliente && row.cliente.toUpperCase().includes(searchCriteria)) ||
          (row.Estado.toUpperCase() && row.Estado.toUpperCase().includes(searchCriteria)) ||
          (row.establecimiento.toUpperCase() && row.establecimiento.toUpperCase().includes(searchCriteria)) ||

          (row.fecha_compra && row.fecha_compra.includes(searchCriteria)) ||
          (row.id_compra && row.id_compra.toString().includes(searchCriteria)) ||
          (row.factura && row.factura.toString().includes(searchCriteria)) ||
          (row.cliente && row.cliente.includes(searchCriteria)) ||
          (row.valor_compra && row.valor_compra.toString().includes(searchCriteria)) ||
          (row.cuotas && row.cuotas.toString().includes(searchCriteria)) ||
          (row.Estado.toString() && row.Estado.toString().includes(searchCriteria)) ||
          (row.establecimiento && row.establecimiento.includes(searchCriteria))
        );
      });
    }
    return [];
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id_compra',
        header: 'Ref. compra',
        size: 5,
      },
      {
        accessorKey: 'factura',
        header: 'Ref. fact',
        size: 10,
      },
      {
        accessorKey: 'cliente',
        header: 'Cliente',
        size: 230,
        muiTableHeadCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'valor_compra',
        header: 'Valor compra / retiro',
        size: 150,
        Cell: ({ cell, row }) => (
          <Box component="span"
            sx={(theme) => ({
              fontWeight: 'bold',
              p: '0.25rem',
              display: "flex",
              justifyContent: "end",
              marginRight: '25px'
            })}
          >
            {<FormatPrice price={cell.getValue()} />}
          </Box>
        ),
      },
      {
        accessorKey: 'establecimiento',
        header: 'Cajero y Establecimiento',
      },
      {
        accessorKey: 'fecha_compra',
        header: 'Fecha',
        size: 10,
      },
      {
        accessorKey: 'Estado',
        header: 'Estado',
        size: 5,
        Cell: ({ cell, row }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color:
                row.original.Estado === 'Exitoso'
                  ? theme.palette.success.dark
                  : row.original.Estado === 'pendiente'
                    ? 'rgb(255, 174, 0)'
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              fontWeight: 'bold',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        size: 1,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            <IconButton style={{ color: '#035533' }} onClick={() => handleOpenModal(row.original.id_compra)}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Modal open={isModalOpenTransactions} onClose={handleCloseModalSaleEstablishment}>
        <ModalView2 className="ModalStyle">
          <GridHeaderII container xl={12} pt={4}>
            <TittleModule >Lista de ventas del rango de fecha de la sucursal.</TittleModule>
          </GridHeaderII>

          <MaterialReactTable columns={columns} data={searchedRows.length > 0 ? searchedRows : applyFilters()} enableGlobalFilterModes
            initialState={{
              showGlobalFilter: false,
              globalFilter: searchCriteria,
              density: 'compact'
            }}
            renderTopToolbarCustomActions={() => (
              <Grid item display={"flex"} xs={3} sm={3} md={3} lg={3} xl={3}>
                <Tooltip arrow placement="bottom" title="Reload">
                  <IconButton onClick={() => handleReload()}>
                    <ReplayIcon />
                  </IconButton>
                </Tooltip>
                <Grid item m={1}>
                  <ButtonActiosn360 label={isSmallScreen ? "..." : "Tabla"} onClick={handleExportAllData} backgroundColor={'#035533'} startIcon={<FileDownloadIcon />} />
                </Grid>
                <Grid item m={1}>
                  <ButtonActiosn360 label={isSmallScreen ? "..." : "Buscados"} onClick={searchRows} backgroundColor={'#035533'} startIcon={<SystemUpdateAltIcon />} />
                </Grid>
              </Grid>
            )}
            positionGlobalFilter="right"
            muiSearchTextFieldProps={{
              placeholder: `Buscar en ${salesBranch.length} registros`,
              sx: { minWidth: '100px' },
              variant: 'standard',
              value: searchCriteria,
              onChange: (e) => {
                setSearchCriteria(e.target.value);
              },
            }}
            onFilterChange={(newFilter) => {
              setSearchCriteria(newFilter);
            }}
          />

          {selectedSaleId !== null && (
            <ModalViewDetailsSale isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedSaleId={selectedSaleId} />
          )}
        </ModalView2>
      </Modal>
    </div>
  );
}
