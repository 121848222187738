import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

import { GridBtn } from './Style.jsx';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import ModalUpdateQuota from '../modals/UpdateQuota.jsx';
import { MaterialReactTable } from 'material-react-table';
import FormatPrice from '../../../../../Utilities/FormatPrices.jsx';
import ModalMassivePorcentage from '../modals/MassivePorcentage.jsx';
import { Box, Grid, IconButton, Switch, Tooltip } from '@mui/material';
import FormatId from '../../../../../Utilities/FormatIdentification.jsx';
import { useAuthContext } from '../../../../../Contexts/AuthContext.jsx';
import ButtonActiosn360 from '../../../../../components/elements/Buttons/ButtonActions360.jsx';

export default function TableClientsQuotes() {
  const { token, role } = useAuthContext();
  const [/* error */, setError] = useState();
  const [clients, setClients] = useState([]);
  const [rowSelection, setRowSelection] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [/* stateQuota */, setStateQuota] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [keysObjectsArray, setKeysObjectsArray] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [modalOpenPorcentage, setModalOpenPorcentage] = useState(false);
  const [/* selectedClient */, setSelectedClient] = useState(null);
  const [ /* stateMassiveQuota */, setStateMassiveQuota] = useState(false);

  const handleViewModal = (customer_id, customer_name, initial_credit_limit) => {
    setSelectedClientId(customer_id);
    setSelectedClient({ customer_id, customer_name, initial_credit_limit });
    setIsModalOpen(true);
    setModalOpenPorcentage(false)
  };

  const handleViewModalQuota = (customer_id, customer_name, initial_credit_limit) => {
    setSelectedClientId(customer_id);
    setSelectedClient({ customer_id, customer_name, initial_credit_limit });
    setModalOpenPorcentage(true);
    setIsModalOpen(false);
  };

  async function GetClients() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewAssigned`, {
        headers: {
          authorization: token,
        },
      });
      setClients(response.data);
    } catch (error) {
      setError(error);
    }
  }

  async function handleUpdateState(customer_id) {
    try {
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/changeCreditStatus/${customer_id}`, null, config);
      setStateQuota(response.data);
      handleReload()
    } catch (error) {
    }
  }

  async function handleUpdateMassiveState(rowSelection) {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/changeCreditsStatus`,
        keysObjectsArray, {
        headers: {
          authorization: token,
        },
      });
      setStateMassiveQuota(response.data);
      handleReload()
    } catch (error) {
    }
  }

  const handleReload = () => {
    GetClients()
  }

  useEffect(() => {
    GetClients()
  }, []);

  useEffect(() => {
    const keysArray = Object.keys(rowSelection);
    const keysObjectsArray = keysArray.map((key) => ({ customer_id: key }));
    setKeysObjectsArray(keysObjectsArray);

    setButtonDisabled(keysArray.length === 0);
    if (role !== 'administrador') {
      handleUpdateState();
    }
  }, [rowSelection]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'customer_id',
        header: 'Id cliente',
        size: 100,
        Cell: ({ cell }) => (
          <Box component="span" sx={{ display: "flex", justifyContent: "end", marginRight: "10px" }}>
            {<FormatId id={cell.getValue()} />}
          </Box>
        ),
      },
      {
        accessorKey: 'customer_name',
        header: 'Nombre',
        size: 150,
        Cell: ({ cell }) => (
          <Box
            component="span" sx={(theme) => ({
              marginRight: "10px"
            })}>
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'initial_credit_limit',
        header: 'Cupo inicial',
        size: 120,
        Cell: ({ cell }) => (
          <Box component="span" sx={{ fontWeight: 'bold', display: "flex", justifyContent: "end", marginRight: "25px" }}>
            {<FormatPrice price={cell.getValue()} />}
          </Box>
        ),
      },
      {
        accessorKey: 'outstanding_credit',
        header: 'Valor adeudado',
        size: 130,
        Cell: ({ cell }) => (
          <Box sx={{ textAlign: "end" }}>
            <Box component="span" sx={(theme) => ({
              backgroundColor: cell.getValue() === 0 ? theme.palette.success.dark : theme.palette.error.dark,
              borderRadius: '0.25rem',
              color: '#fff',
              p: '0.25rem',
              marginRight: "5px"
            })}
            >
              {<FormatPrice price={cell.getValue()} />}
            </Box>

          </Box>

        ),
      },
      {
        accessorKey: 'available_credit',
        header: 'Cupo disponible',
        size: 130,
        Cell: ({ cell, row }) => (
          <Box sx={{ textAlign: "end" }}>
            <Box
              component="span" sx={(theme) => ({
                color: row.original.status === 'activo' && cell.getValue() !== 0 ? theme.palette.success.dark : row.original.status === 'pendiente' ? 'rgb(255, 174, 0)' : theme.palette.error.dark,
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: "5px"
              })}
            >
              {<FormatPrice price={cell.getValue()} />}
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'porcentage',
        header: ' % de retiro',
        size: 100,
        Cell: ({ cell, row }) => (
          <Box sx={{ textAlign: "end" }}>
            <Box component="span" sx={(theme) => ({
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: "5px",
            })}>
              {cell.getValue()}%
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'withdrawal_credit',
        header: 'Valor a desembolsar',
        size: 120,
        Cell: ({ cell, row }) => (
          <Box sx={{ textAlign: "end" }}>
            <Box component="span" sx={(theme) => ({
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: "5px",
            })}>
              {<FormatPrice price={cell.getValue()} />}
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'withdrawn_value',
        header: 'Valor desembolsado',
        size: 120,
        Cell: ({ cell, row }) => (
          <Box sx={{ textAlign: "end" }}>
            <Box component="span" sx={(theme) => ({
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'flex-end',
              marginRight: "5px",
            })}>
              {<FormatPrice price={cell.getValue()} />}
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'company',
        header: 'Empresa',
        size: 100
      },
      {
        accessorKey: 'status',
        header: 'Estado cupo',
        size: 120,
        Cell: ({ cell, row }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color:
                row.original.status === 'activo'
                  ? theme.palette.success.dark
                  : row.original.status === 'pendiente'
                    ? 'rgb(255, 174, 0)'
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              fontWeight: 'bold',
              maxWidth: '5ch',

              display: "flex",
              flexDirection: "end"
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        size: 100,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px', }}>
            <Tooltip title="Editar % de retiro">
              <IconButton aria-label="Actualizar" style={{ color: '#035533' }} onClick={() => handleViewModal(row.original.customer_id)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Switch defaultChecked={row.original.status === "activo"} color={row.original.status === "activo" ? "success" : "error"} onChange={() => handleUpdateState(row.original.customer_id)} />
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={1.5}>
      <MaterialReactTable columns={columns} data={clients}
        defaultColumn={{
          maxSize: 400,
          minSize: 80,
          size: 200
        }}
        enableColumnResizing={true}
        columnResizeMode="onChange"
        renderTopToolbarCustomActions={() => (
          <>
            <Tooltip arrow placement="bottom" title="Reload">
              <IconButton onClick={() => handleReload()}>
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        initialState={{
          density: 'compact'
        }}
        positionActionsColumn="last"
        enableRowSelection
        getRowId={(row) => row.customer_id}
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection }}
      />

      <GridBtn container item xs={12} sm={12} md={12} lg={12} xl={12} height={"6vh"} mt={2} >
        <Grid container item xs={5} sm={4} md={2} lg={2} xl={1.5} ml={1}>
          <ButtonActiosn360 label="Cambiar estado" className='buttonAction360' onClick={() => handleUpdateMassiveState()} backgroundColor={buttonDisabled ? "grey" : "#035533"} disabled={buttonDisabled} />
        </Grid>
        <Grid container item xs={5} sm={4} md={2} lg={2} xl={1.5} ml={1}>
          <ButtonActiosn360 label="Asignar % retiro" className='buttonAction360' onClick={() => handleViewModalQuota()} backgroundColor={buttonDisabled ? "grey" : "#035533"} disabled={buttonDisabled} />
        </Grid>
      </GridBtn>

      {selectedClientId !== null && (
        <ModalUpdateQuota isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedClientId={selectedClientId} />
      )}

      {rowSelection !== null && (
        <ModalMassivePorcentage modalOpenPorcentage={modalOpenPorcentage} setModalOpenPorcentage={setModalOpenPorcentage} rowSelection={keysObjectsArray} />
      )}
    </Grid >
  );
}