import TextField from "@mui/material/TextField";
import { StyleComboBox } from "./StyleComboBox";

const rolesAlias = {
  "Administrador de establecimiento": "1",
  "Administrador de sucursal": "2",
  "Cajero": "3",
};

const roles = [
  { label: "Administrador de establecimiento" },
  { label: "Administrador de sucursal" },
  { label: "Cajero" },
];

const ComboBoxWithAlias = ({ label, selectedValue, setSelectedValue, disabled }) => {
  const handleChange = (event, newValue) => {
    let selectedRole = '';
    if (newValue) {
      const originalLabel = newValue.label;
      selectedRole = rolesAlias[originalLabel] || originalLabel;
    }
    setSelectedValue(selectedRole);
  };

  const selectedValueObject = { label: selectedValue };

  return (
    <StyleComboBox
      disablePortal
      size="small"
      id="combo-box-demo"
      options={label === "Rol" ? roles : ""}
      disabled={disabled}
      value={selectedValueObject} // Usar el objeto con la propiedad label
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};


export default ComboBoxWithAlias;
