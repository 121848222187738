import styled from "styled-components";
import { Card, Grid } from "@mui/material";
import TarjetaEstablecimiento from "../../../../Assets/images/fondos svg/TarjetaEstablecimiento.svg";
import TarjetaCajero from "../../../../Assets/images/fondos svg/TarjetaCajero.svg";

export const GridMarca = styled(Grid)`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1200px) and (max-height: 931px) {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;

    .DivDashboard1 {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 60%;
    }
  }
`;

export const DivDashboard1 = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .GridCards {
    gap: 8px;
    height: 24%;
    margin-bottom: 2%;
    margin-top: 2%;
    width: 100%;
  }

  .GridBar {
    height: 65%;
  }
`;


export const DivDashboard2 = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .GridCard {
    height: 30%;
  }
`;

export const GridHeaderII = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-top: -3.5rem;
`;

export const CardDiagram = styled(Card)`
  margin-bottom: 2%;
  display: flex;
  width: 100%;
  flex-direction: row;
  border-radius: 20px 20px 20px 20px !important;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  ${({ type }) => {
    if (type === "establishment") {
      return `
        height: 22vh;
        width: 100%;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
        background-image: url(${TarjetaEstablecimiento});
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 100%;
        border-radius: 10px 10px 10px 10px !important;

        @media (max-width: 448px) and (max-height: 977px) {
          width: 100%;
        }
      `;
    }
    if (type === "establishmentII") {
      return `
        height: 22vh;
        width: 100%;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
        background-image: url(${TarjetaCajero});
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: 100%;
        border-radius: 10px 10px 10px 10px !important;
      `;
    }
    if (type === "info") {
      return `
        height: 10vh;
        width: 49%;
        gap: 20px;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
        border-radius: 10px 10px 10px 10px !important;

        @media (max-width: 1380px) and (max-height: 931px) {
          width: 100%;
        }

        @media (max-width: 552px) and (max-height: 997px) {
          width: 100%;
        }

        @media (max-width: 320px) and (max-height: 480px), (max-width: 320px) and (max-height: 568px) {
          width: 100%;
          height: 15vh;
        }
        
        @media (max-width: 430px) and (max-height: 932px) {
          width: 100%;
        }
       

      `;
    }
    if (type === "bar") {
      return `
        height: 55vh;
        width: 100%;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);

        @media (max-width: 1496px) and (max-height: 931px) {
          margin-top: 18%
        }

        @media (max-width: 552px) and (max-height: 997px) {
          margin-top: 40%
        }

        @media (max-width: 400px) and (max-height: 997px) {
          margin-top: 50%
        }

        @media (max-width: 1200px) and (max-height: 931px) {
          width: 100%;
          height: 36vh;
          margin-top: 0%
        }
        @media (max-width: 600px) and (max-height: 931px) {
          height: 28vh;
        }
        
        @media (max-width: 1496px) and (max-height: 716px){
          margin-top: 0%
        }

        @media (max-width: 1240px) and (max-height: 572px){
          margin-top: 16%
        }
        @media (min-width: 1239.33px) and (max-height: 572px){
          margin-top: 16%
        }

      `;
    }
    if (type === "pie") {
      return `
        height: 54vh;
        width: 100%;
        
        margin: 0% 2% 8% 2%;
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
       
        @media (max-width: 1200px) and (max-height: 931px) {
          height: 54vh;
          border-radius: 10px 10px 10px 10px !important;
        }
        @media (max-width: 1200px) and (max-height: 836px) {
          margin-top: 55%;
          height: 54vh;
          border-radius: 10px 10px 10px 10px !important;
        }


        @media (max-width: 1496px) and (max-height: 716px){
          height: 55vh;
          margin-top: 0%
          border-radius: 10px 10px 10px 10px !important;
        }
        @media (max-width: 1200px) and (max-height: 716px) {
          margin-top: 0%;
          height: 54vh;
          border-radius: 10px 10px 10px 10px !important;
        }


        @media (max-width: 1224px) and (max-height: 556px) {
          margin-top: 30%;
          height: 54vh;
          border-radius: 10px 10px 10px 10px !important;
        }
        @media (max-width: 1200px) and (max-height: 556px) {
          margin-top: 47%;
          height: 54vh;
          border-radius: 10px 10px 10px 10px !important;
        }

        @media (max-width: 1239.33px) and (max-height: 571.33px){
          margin-top: 6%
        }
      `;
    }
  }}

  .GridContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }

  .info {
    height: 22vh;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 2% 0% 0% 5%;
    font-size: 14px;

    /*dimension 100%*/
    @media (max-width: 1250px) and (max-height: 836px) {
      font-size: 15px;
    }
    /*dimension 125%*/
    @media (max-width: 1451px) and (max-height: 619px) {
      font-size: 15px;
    }
    @media (max-width: 1251px) and (max-height: 619px) {
      font-size: 13px;
    }
    /*dimension 150%*/
    @media (max-width: 1223px) and (max-height: 556px) {
      font-size: 12px;
    }
  }

  .item {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) and (max-height: 716px) {
      margin-top: 7%;
    }
  }

  .icon {
    margin-bottom: -3%;

    /*dimension 100%*/
    @media (max-width: 1250px) and (max-height: 836px) {
      font-size: 20px;
    }
    /*dimension 125%*/
    @media (max-width: 1470px) and (max-height: 668px) {
      font-size: 20px;
    }
    /*dimension 150%*/
    @media (max-width: 1223px) and (max-height: 556px) {
      font-size: 18px;
    }
  }

  .GridContainerII {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    width: 30%;
  }

  .barImg {
    width: 80%;
    height: 50px;

    /*dimension 100%*/
    @media (max-width: 1250px) and (max-height: 836px) {
      width: 55%;
      height: 40px;
      padding-right: 10px;
    }
    /*dimension 125%*/
    @media (max-width: 1470px) and (max-height: 668px) {
      width: 60%;
      height: 50px;
      padding-right: 10px;
    }
    /*dimension 150%*/
    @media (max-width: 1223px) and (max-height: 556px) {
    }
  }

  /*dimension 100%*/
  @media (max-width: 1318px) and (max-height: 836px),
    (max-width: 1200px) and (max-height: 836px) {
    width: 100%;
  }
  /*dimension 125%*/
  @media (max-width: 1470px) and (max-height: 668px) {
    width: 100%;
  }
  /*dimension 150%*/
  @media (max-width: 1223px) and (max-height: 556px) {
    width: 100%;
  }
`;
