import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import CashierRegister from '../RegisterUser';
import ReplayIcon from '@mui/icons-material/Replay';
import { Edit as EditIcon } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridHeaderII } from '../../moduleDashboard/Styled';
import ProfileMenu from '../../../../Components/menu/Profile';
import ModalViewDetailsAdmins from '../modals/ViewDetailsAdmins';
import { useAuthContext } from '../../../../../Contexts/AuthContext';
import FormatId from '../../../../../Utilities/FormatIdentification';
import { TittleModule } from '../../registrationEstablishment/Styled';
import { Box, IconButton, Grid, Switch, Tooltip } from '@mui/material';
import UsersRegister from '../RegisterUser';
import ModalEditAdminBranch from '../modals/EditUserAdminBranch';

const TableAdminBranch = () => {
  const { token } = useAuthContext();
  const [adminsBranch, setAdminsBranch] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAdminsBranch, setSelectedAdminsBranch] = useState(null);
  const [editingAdminstBranchId, setEditingAdminsBranchId] = useState(null);
  const [selectedAdminsBranchId, setSelectedAdminsBranchId] = useState(null);
  const [showRegistrationComponent, setShowRegistrationComponent] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const getAdminsBranch = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getAllEstablishmentAdmin`, {
        headers: {
          authorization: token,
        },
      });
      setAdminsBranch(response.data);
    } catch (error) {
    }
  };

  const handleUpdateState = async (row) => {
    try {
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/ChangeAdminStablishmentStatus/${row.original.cashier_id}`, null, config);
      setAdminsBranch((prevEstablishments) => {
        return prevEstablishments.map((cashier) => {
          if (cashier.status === row.original.status) {

            return { ...cashier };
          } else {
            return cashier;
          }
        });
      }, response.data);
      getAdminsBranch()
    } catch (error) {
    }
  };

  const handleEditAdminsBranch = (AdminId) => {
    setEditingAdminsBranchId(AdminId);
    setShowRegistrationComponent(true);
    setIsEditModalOpen(true);
  };

  const handleViewModal = (AdminId) => {
    setSelectedAdminsBranchId(AdminId);
    setSelectedAdminsBranch(AdminId)
    setShowRegistrationComponent(false);
    setIsModalOpen(true);
  };

  const handleReload = () => {
    getAdminsBranch()
  }

  useEffect(() => {
    const adminToEdit = adminsBranch.find(admin => admin.cashier_id === editingAdminstBranchId);
    setSelectedAdminsBranch(adminToEdit);
  }, [editingAdminstBranchId, adminsBranch]);

  useEffect(() => {
    getAdminsBranch();
  }, []);


  const columns = useMemo(
    () => [
      {
        accessorKey: 'cashier_id',
        header: 'Número de identificación',
        Cell: ({ cell }) => (
          <div>
            <Box component="span">
              {<FormatId id={cell.getValue()} />}
            </Box>
          </div>
        ),
      },

      {
        accessorKey: 'cashier_name',
        header: 'Nombre del administrador',
      },
      {
        accessorKey: 'business_name',
        header: 'Establecimiento',
        size: 0,
      },
      {
        accessorKey: 'status',
        header: 'Estado del administrador',
        Cell: ({ cell, row }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color:
                row.original.status === 'activo'
                  ? theme.palette.success.dark
                  : row.original.status === 'pendiente'
                    ? 'rgb(255, 174, 0)'
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              fontWeight: 'bold',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
      <GridHeaderII container xl={12} mt={-18} pb={10}>
        <TittleModule >Lista de admins sucursales</TittleModule>
        <ProfileMenu />
      </GridHeaderII>
      <>
        <MaterialReactTable columns={columns} data={adminsBranch} enableRowActions
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              <Tooltip title="Editar">
                <IconButton style={{ color: '#035533' }}>
                  <EditIcon onClick={() => handleEditAdminsBranch(row.original.cashier_id)} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Ver detalle">
                <IconButton style={{ color: '#035533' }} onClick={() => handleViewModal(row.original.cashier_id)}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
              <Switch defaultChecked={row.original.status === "activo"} color={row.original.status === "activo" ? "success" : "error"} onClick={() => { handleUpdateState(row) }} />
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <>
              <Tooltip arrow placement="bottom" title="Reload">
                <IconButton onClick={() => handleReload()}>
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          positionActionsColumn="last"
        />
      </>

      {setSelectedAdminsBranchId !== null && (
        <ModalViewDetailsAdmins isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedAdminsId={selectedAdminsBranchId} />
      )}

      {isEditModalOpen && (
        <ModalEditAdminBranch
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          selectedAdminsBranch={selectedAdminsBranch}
          adminsBranchId={editingAdminstBranchId}
        />
      )}
    </Grid>
  );
};

export default TableAdminBranch;