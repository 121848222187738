import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
import { DivModal, ModalView, ModalView2 } from './Styled';
import { Typography, Modal, Grid, Box } from '@mui/material';
import { useAuthContext } from '../../../../Contexts/AuthContext';
import { LineDivider } from '../../../../components/elements/lines/Styled';
import { StyledInputText } from '../../../../components/elements/Inputs/Styled';
import ButtonActiosn360 from '../../../../components/elements/Buttons/ButtonActions360';

export default function ModalInfoCompany({ selectedCompanyId, isModalOpenCompany, setIsModalOpenCompany }) {
  const { token } = useAuthContext();
  const [errors, setErrors] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [isChangeCredentialsVisible, setIsChangeCredentialsVisible] = useState(true);
  const [companyData, setCompanyData] = useState({
    company: "",
    address: "",
    contact: "",
    idCompany:0
  });

  const validateForm = () => {
    const errors = {};

    if (!/^\d+$/.test(companyData.contact)) {
      errors.DV = 'El DV debe ser un número.';
    }

    const stringFields = ['company', 'address'];
    stringFields.forEach((field) => {
      if (!companyData[field] || !companyData[field].trim()) {
        errors[field] = `El campo es requerido.`;
      }
    });

    setErrors(errors);
    return errors;
  }

  const fetchCompanyData = async () => {
    try {
      const idAdmin = selectedCompanyId;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCompany/${idAdmin}`, {
        headers: {
          authorization: token,
        },
      });
      const infoEmpresa = response.data[0];
      setCompanyData({
        company: infoEmpresa.company_name,
        address: infoEmpresa.company_address,
        contact: infoEmpresa.company_phone,
        idCompany: infoEmpresa.id_company
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'Hubo un error al traer la información. No hay datos disponibles.',
        confirmButtonColor: '#035533',
      });
    }
  };

  const updateCompanyData = async () => {
    try {
      const idCompany = companyData.idCompany;
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/UpdateCompany/${idCompany}`,
        {
          company_name: companyData.company,
          company_address: companyData.address,
          company_phone: companyData.contact,
        },
        {
          headers: {
            authorization: token,
          },
        }
      );
   
        const infoEmpresa = response.data[0];
        setCompanyData({
          company: infoEmpresa.company_name,
          address: infoEmpresa.company_address,
          contact: infoEmpresa.company_phone,
        })
        handleCloseModal();
        Swal.fire(
          'Bien hecho!',
          'Información de la compañia actualizada exitosamente!',
          'success'
        );
    
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'Hubo un error al actualizar la información. No hay datos disponibles.',
        confirmButtonColor: '#035533',
      });
    }
  };


  useEffect(() => {
    if (selectedCompanyId && isModalOpenCompany) {
      fetchCompanyData();
    }
  }, [selectedCompanyId, isModalOpenCompany]);


  const handleEditButtonClick = () => {
    setIsEditable(true);
    setIsChangeCredentialsVisible(false);
  };

  const handleSaveButtonClick = () => {
    setIsEditable(false);
    setIsChangeCredentialsVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalOpenCompany(false);
    setIsEditable(false);
    setIsChangeCredentialsVisible(true);
  };

  return (
    <Modal open={isModalOpenCompany} onClose={handleCloseModal}>
      <ModalView2 className="ModalStyle">
        {companyData && (
          <DivModal>

            <Grid item className='GridHeader'>
              <Typography className='TypographyHeader'>Información de la compañia </Typography>
              <LineDivider color="#035533" width="98%" />
            </Grid>

            <Box component="form" noValidate onSubmit={(e) => { e.preventDefault() }}>
              <Grid container item className='bodyCard' xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} >
                <Grid item xs={12} sm={12} md={3.8} lg={3.8} xl={3.8} mt={2}>
                  <StyledInputText type="text" name="quota" required id="quota" label="Nombre de la compañia" value={companyData.company} onChange={(e) => setCompanyData({ ...companyData, company: e.target.value })} error={errors.company}
                    helperText={errors.company && errors.company} disabled={!isEditable} />
                </Grid>
                <Grid item xs={12} sm={12} md={3.8} lg={3.8} xl={3.8} mt={2}>
                  <StyledInputText type="text" name="quota" required id="quota" label="Dirección" value={companyData.address} onChange={(e) => setCompanyData({ ...companyData, address: e.target.value })} error={errors.address ? true : false}
                    helperText={errors.address ? errors.address : ''} disabled={!isEditable} />
                </Grid>
                <Grid item xs={12} sm={12} md={3.8} lg={3.8} xl={3.8} mt={2}>
                  <StyledInputText name="quota" required id="quota" label="Contacto" value={companyData.contact} onChange={(e) => setCompanyData({ ...companyData, contact: e.target.value })} error={errors.contact}
                    helperText={errors.contact && errors.contact} disabled={!isEditable} />
                </Grid>
              </Grid>
            </Box>

            <Grid container item className='bodyCard' xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} mt={5}>
              {isChangeCredentialsVisible && (
                <>
                  <Grid container item xs={0} sm={2} md={2} lg={2} xl={2} mb={2}>
                  </Grid>
                  <Grid container item xs={12} sm={6} md={3} lg={2} xl={2} mb={2}>
                    <ButtonActiosn360 label="ACTUALIZAR" onClick={handleEditButtonClick} backgroundColor={"#035533"} />
                  </Grid>
                </>
              )}
              {!isChangeCredentialsVisible && (
                <>
                  <Grid container item xs={3} sm={2} md={1.5} lg={1.5} xl={1.5} mb={2}>
                  </Grid>
                  <Grid container item xs={12} sm={6} md={3} lg={2} xl={2} mb={2} textAlign={'end'}>
                    <ButtonActiosn360 label="GUARDAR" onClick={updateCompanyData} backgroundColor={"#035533"} />
                  </Grid>
                </>
              )}
            </Grid>
          </DivModal>
        )}
      </ModalView2>
    </Modal>
  );
}