import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import LogoutIcon from '@mui/icons-material/Logout';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Lenddi from "../../../Assets/images/Logos/LenddiBlanco.svg";
import { useAuthContext } from "../../../Contexts/AuthContext.jsx";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { ContLogout, FondoLogout } from "../../store/forms/Styled.jsx";
import { Fab, Grid } from "@mui/material";
import { BackgrundInfoArreglado, ContCredimg, ContEstab, ContHeaderArreglado, ContPurchase, ContPurchaseMain, ContainerButtonWhts, Establishments, FlexRow, FlexRowStart } from "./Styled.jsx";
import ProfileMenu from "../../Components/menu/Profile.jsx";
import SliderEstablishmentXSubcategories from "../../../components/elements/slider/SliderEstablishmentXSubcategories.jsx";
import SelectCity from "../../../components/elements/ComboBox/comboCity.jsx";
import Swal from "sweetalert2";

export default function SubcategoriesEstablishment() {
  const { logout } = useAuthContext();
  const [activeLogout, setActiveLogout] = useState(false);
  const [companyData, setCompanyData] = useState([{ company_name: "compañia", company_phone: "0" }])
  const navigate = useNavigate()
  const [establishmentSubcategories, setEstablishmentSubcategories] = useState({});
  const [establishment, setEstablishment] = useState([]);
  let { id } = useParams();
  const idNull = null
  const city = localStorage.getItem('ciudad') || null;

  const fetchEstablishmentSubcategories = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getEstablishmentBySubcategory/${id}`, {
        city: city
      })
      if (response.data.message) {
        if (response.data.message === "Sin resultados para esta subcategoria") {
          setEstablishmentSubcategories(response.data);
          Swal.fire({
            icon: 'info',
            title: 'Ciudad sin promociones',
            text: `Hata el momento los establecimientos de ${city} no han hecho puclicación de promociones.`,
            confirmButtonColor: '#035533'
          });
        }
      } else {
        setEstablishmentSubcategories(response.data);
        setEstablishment(response.data.establishments)
      }
    } catch (error) {
    }
  };

  const fetchCompanyData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCompanyToClient`);
      setCompanyData(response.data);
    } catch (error) {
    }
  };

  const Logout = () => {
    logout()
    setActiveLogout(!activeLogout)
    setTimeout(() => {
      navigate('/loginClient');
    }, [1000]);
  }

  useEffect(() => {
    fetchCompanyData();
    fetchEstablishmentSubcategories()
  }, [city])
  return (
    <ContPurchaseMain $actLogout={activeLogout}>
      <FondoLogout>
        <ContLogout>
          <LogoutIcon />
          <h3>¿Quieres cerrar sesión?</h3>
          <button className='button Confirm' onClick={() => Logout()}>SI</button>
          <button className='button Cancel' onClick={() => setActiveLogout(!activeLogout)}>NO</button>
        </ContLogout>
      </FondoLogout>
      <ContPurchase>
        <BackgrundInfoArreglado>
          <ContHeaderArreglado>
            <Grid container item xs={11} sm={11} md={5} lg={4} xl={3} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
                <Link to={`/client`}>
                  <ContCredimg src={Lenddi} />
                </Link>
              </Grid>
              <Grid item xs={5} sm={4} md={7} lg={7} xl={7} >
                <SelectCity />
              </Grid>
            </Grid>
            <ProfileMenu />
          </ContHeaderArreglado>
        </BackgrundInfoArreglado>

        <ContEstab>
          <Establishments>
            <FlexRow className="EstablishmentName">
              <h1>{establishmentSubcategories.subcategory}</h1>
            </FlexRow>
            <FlexRowStart className="EstablishmentName">
              <Link to={`/client/categories/${idNull}`}>
                <KeyboardArrowLeftIcon color="red" />
                <h4>{establishmentSubcategories.category}</h4>
              </Link>
            </FlexRowStart>
          </Establishments>
          <SliderEstablishmentXSubcategories establishment={establishment} />
        </ContEstab>

        <ContainerButtonWhts>
          <Fab style={{ backgroundColor: "#24d163" }} aria-label="edit">
            <a href={`https://wa.me/${companyData[0].company_phone}?text=Estoy%20interesado%20en%20asesoria%20con%20${companyData[0].company_name}`} target="_blank" rel="noreferrer" style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <WhatsAppIcon alt="Chatea con nosotros" className="whatsapp-button" sx={{ color: "white", fontSize: "28px" }} />
            </a>
          </Fab>
        </ContainerButtonWhts>
      </ContPurchase>

    </ContPurchaseMain >
  );
}
