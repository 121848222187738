import React from 'react';
import { StyledButton360Outlined } from './Styled.jsx';

const Button360Outlined = ({ label, onClick }) => {
  return (
    <StyledButton360Outlined variant="outlined" onClick={onClick}>
      {label}
    </StyledButton360Outlined>
  );
}

export default Button360Outlined;
