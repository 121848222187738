import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import validator from 'validator';
import Swal from 'sweetalert2';
import axios from "axios";

import { BoxImg, ModuleLogin, ContainerBody, ContainerHeader, MainDiv } from './styled'
import { Container, Grid, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button360 from "../../components/elements/Buttons/Button360";
import InputText2 from "../../components/elements/Inputs/InputText2";
import Lenddi from "../../Assets/images/Logos/LenddiBlanco.svg";
import TypographyH3 from '../../components/elements/labels/TypographyH3';
import LogoAnimated from "../../components/elements/animatedLogo/AnimatedLogo";
import { StyledInputText } from "../../components/elements/Inputs/Styled";
import { useAuthContext } from "../../Contexts/AuthContext";

export const Logins = () => {
  const navigate = useNavigate();
  const { login, validate } = useAuthContext()
  const [errors, setErrors] = useState({});
  const [/* error */, setError] = useState("")
  const [showLogo, setShowLogo] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [user, serUser] = useState({
    email: "",
    password: ""
  })

  const handleInputChange = (campo, valor) => {
    serUser({ ...user, [campo]: valor });
  };

  const validateLoginForm = () => {
    const errors = {};
    if (validator.isEmpty(user.email)) {
      errors.email = 'El campo de correo electrónico es obligatorio';
    } else if (!validator.isEmail(user.email)) {
      errors.email = 'El correo electrónico no es válido';
    }

    if (validator.isEmpty(user.password)) {
      errors.password = 'El campo de contraseña es obligatorio';
    }

    return errors;
  };

  const handleConfirmLogin = async () => {
    const validationErrors = validateLoginForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
          email: user.email,
          password: user.password,
        });
        setShowLogo(false);
        validate(response.data.token, response.data.role)
        login(response.data.token, response.data.role);
        if (response.data.role === "administradorEstablecimiento" || response.data.role === "superAdministradorEstablecimiento") {
          navigate("/home");
        }
      } catch (error) {
        setError(error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al ingresar, las credenciales de acceso estan inactivas o no coinciden.',
          confirmButtonColor: '#035533',
        });
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <MainDiv>
      {showLogo ? (
        <LogoAnimated />
      ) : (
        <ContainerBody>
          <ContainerHeader>
            <BoxImg src={Lenddi} alt="Lenddi" />
          </ContainerHeader>
          <ModuleLogin component="form" noValidate
            onSubmit={(e) => {
              e.preventDefault();
              handleConfirmLogin();
            }}>

            <Container justify="center" xs={10} md={8} lg={9}>
              <Grid item xs={12} mt={9} >
                <TypographyH3 text="Iniciar sesión" />
              </Grid>
            </Container>

            <Container maxWidth="sm">
              <Grid container justify="center" alignItems="center" mt={5}>
                <Grid item xs={12}>
                  <InputText2 label="Usuario*" value={user.email} onChange={(e) => handleInputChange("email", e.target.value)} />
                  {errors.email && <span className="error-message" style={{ color: "red" }}>{errors.email}</span>}
                </Grid>
                <Grid item xs={12} mt={3}>
                  <StyledInputText
                    label="Contraseña*"
                    value={user.password}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => handleInputChange("password", e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showPassword ? (
                            <VisibilityOff style={{ color: "#035533" }} onClick={() => setShowPassword(!showPassword)} />
                          ) : (
                            <Visibility style={{ color: "#035533" }} onClick={() => setShowPassword(!showPassword)} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleConfirmLogin();
                      }
                    }}
                  />
                  {errors.password && <span className="error-message" style={{ color: "red" }}>{errors.password}</span>}
                </Grid>
              </Grid>
            </Container>

            <Container maxWidth="sm">
              <Grid item xs={12} sx={{ marginTop: 9 }}>
                <Button360 label="Iniciar sesión" ml={{ xs: 14, md: 0 }} onClick={() => handleConfirmLogin()} />
              </Grid>
            </Container>
          </ModuleLogin>
        </ContainerBody>
      )}
    </MainDiv>
  );
};
