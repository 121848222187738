import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios';

import { useAuthContext } from '../../../Contexts/AuthContext';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DiagramPie() {
  const [resultsIncome, setResultsIncome] = useState([])
  const { token } = useAuthContext();

  const income = resultsIncome.map(item => item.total_compras);

  const ResultsSales = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/TotalValuePurchaseByMonth`, {
        headers: {
          authorization: token,
        },
      }
      );
      setResultsIncome(response.data);
    } catch (error) {
     }
  };

  useEffect(() => {
    ResultsSales()
  }, [])

  var options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  var data = {
    labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    datasets: [
      {
        label: 'Ingreso total del mes',
        data: income,
        backgroundColor: [
          '#0e7a4d',
          '#32faa5',
          '#38baf2',
        ],
      },
    ],
  };

  return <Doughnut data={data} options={options} style={{ width: "95%", height: "50vh", textAlign: "center" }} />
}

