import { useState, useEffect } from 'react';

import axios from 'axios';
import Swal from 'sweetalert2'
import { jwtDecode } from 'jwt-decode';

import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
import { Button, CssBaseline, Grid } from "@mui/material";
import VoucherPurchase from '../Transactions/Voucher.jsx';
import FormatPrice from '../../../Utilities/FormatPrices.jsx';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useAuthContext } from '../../../Contexts/AuthContext.jsx';
import Tittle from '../../../components/elements/labels/Tittle.jsx';
import Button360 from '../../../components/elements/Buttons/Button360.jsx';
import { NumericFormatCustom } from '../../../Utilities/NumericFormat.jsx';
import InputNumber from '../../../components/elements/Inputs/InputNumber.jsx';
import TypographyH2 from '../../../components/elements/labels/TypographyH2.jsx';
import TypographyH4II from '../../../components/elements/labels/TypographyH4II.jsx';
import TypographyH6 from '../../../components/elements/labels/TypographyH6.jsx';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { StyledInputText2 } from '../../../components/elements/Inputs/Styled.jsx';
import Button360Outlined from '../../../components/elements/Buttons/Button360Outlined.jsx';
import { MainDiv, ContainerBody, ContainerHeader, ContainerInfo, BodyHeader, ModuleStore, DivTransactions, ContLogout, FondoLogout, GridContainerValidation } from './Styled.jsx'

export default function PurchaseStore() {
  const navigate = useNavigate()
  const [step, setStep] = useState(1);
  const [nombre, setNombre] = useState("");
  const { token, logout } = useAuthContext();
  const [ idCompra, setIdCompra] = useState({});
  const [showFields, setShowFields] = useState(false);
  const [tokenLabel, setTokenLabel] = useState("Token");
  const [activeLogout, setActiveLogout] = useState(false);
  const [numeroDocumento, setNumeroDocumento] = useState("");
  const [tokenValidation, setTokenValidation] = useState("");
  const [showTokenField, setShowTokenField] = useState(false);
  const [/* compraExitosa */, setCompraExitosa] = useState(false);
  const [/* purchaseResult */, setPurchaseResult] = useState(null);
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [showListTransactions, setshowListTransactions] = useState(false);
  const [valorCompra, setValorCompra] = useState({ numberformat: null, });
  const [isNumeroDocumentoEmpty, setIsNumeroDocumentoEmpty] = useState(false)
  const [quotaAllocation, setQuotaAllocation] = useState({
    identificacion: "",
    cupo: "",
    estado: ""
  });
  const tokenDec = localStorage.getItem('token');
  const decodedToken = jwtDecode(tokenDec);

  const buscarPersona = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getSingleCustomerWithCredit/${numeroDocumento}`, {
        headers: {
          authorization: token,
        },
      });
      if (response.status === 200) {
        const data = response.data;

        if (data && data.length > 0) {
          const cliente = data[0];

          setNombre(cliente.customer_name);
          setQuotaAllocation({
            identificacion: cliente.customer_id,
            cupo: cliente.available_credit,
            estado: cliente.status
          });
          setShowFields(true);
          setStep(2);
        } else {
          setNombre("");
          setQuotaAllocation({
            identificacion: "",
            cupo: ""
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: '¡¡ Error al intentar consultar !!',
          text: "Lo sentimos, el usuario no tiene cupo!",
          confirmButtonColor: '#035533',
          showClass: { popup: 'animate__animated animate__fadeInDown' },
          hideClass: { popup: 'animate__animated animate__fadeOutUp' }
        });
      }
    } catch (error) {

      if (error.response.status === 401) {
        logout()
      }
      Swal.fire({
        icon: 'error',
        title: '¡¡ Error al intentar consultar !!',
        text: "Lo sentimos, el usuario no tiene cupo o no está registrado!",
        confirmButtonColor: '#035533',
        showClass: { popup: 'animate__animated animate__fadeInDown' },
        hideClass: { popup: 'animate__animated animate__fadeOutUp' }
      });
    }
  };

  const confirmarCompra = async () => {
    const valorCompraNum = parseFloat(valorCompra.undefined);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/checkToken`, {
        value: valorCompraNum,
        token: tokenValidation,
        customer_id: numeroDocumento,
        cashier_id: decodedToken.id_user,
        establishment_id: decodedToken.establishment_id
      }, {
        headers: {
          authorization: token,
        },
      });

      if (response.status === 200) {
        setIdCompra(response.data)
        setShowFields(false);
        setCompraExitosa(true);
        setShowConfirmButton(false);
        setNombre("Compra exitosa");
        setshowListTransactions(true);
        setPurchaseResult('Compra exitosa');
        setQuotaAllocation({ identificacion: "", cupo: "" });
      } else {
        setPurchaseResult('Error al confirmar la compra');
      }
    } catch (error) {
      setPurchaseResult('Error al confirmar la compra');
      if (valorCompraNum > parseFloat(quotaAllocation.cupo)) {
        Swal.fire({
          icon: 'error',
          title: 'Error al confirmar la compra',
          text: 'No puede hacer una compra por un valor mayor al cupo disponible.',
          confirmButtonColor: '#035533',
          showClass: { popup: 'animate__animated animate__fadeInDown' },
          hideClass: { popup: 'animate__animated animate__fadeOutUp' }
        });
        return;
      }
      Swal.fire({
        icon: 'error',
        title: '¡¡ Error al intentar comprar !!',
        text: "Lo sentimos, los campos son requeridos o el token ya expiró!",
        confirmButtonColor: '#035533',
        showClass: { popup: 'animate__animated animate__fadeInDown' },
        hideClass: { popup: 'animate__animated animate__fadeOutUp' }
      })
      if (error.response.status === 401) {
        logout()
      }
    }
  };

  const handleValorCompraChange = (event) => {
    setValorCompra({ ...valorCompra, [event.target.name]: event.target.value });
  };

  const handleConfirmTokenClick = async (type) => {
    setStep(3);
    setTokenLabel(type);
    setShowTokenField(true);
    setShowConfirmButton(true);

    if (type === "otp-msj") {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/sendOTP`, {
          customer_id: numeroDocumento,
        }, {
          headers: {
            authorization: token,
          },
        });
        if (response.status === 201) {
          Swal.fire(
            '¡Código OTP enviado!',
            `Código de verificacion OTP enviado via mensaje de texto al nuúmero!`,
            'success'
          );
        }
      } catch (error) {
        setPurchaseResult('Error al confirmar la compra');
        Swal.fire({
          icon: 'error',
          title: '¡¡ Error al intentar comprar !!',
          text: "Lo sentimos, los campos son requeridos o el token ya expiró!",
          confirmButtonColor: '#035533',
          showClass: { popup: 'animate__animated animate__fadeInDown' },
          hideClass: { popup: 'animate__animated animate__fadeOutUp' }
        })
        if (error.response.status === 401) {
          logout()
        }
      }
    }
  };

  const handleNumeroDocumentoChange = (value) => {
    setNumeroDocumento(value);
    setIsNumeroDocumentoEmpty(value === "");
  };

  const Logout = () => {
    navigate('/store/logout1');
    setActiveLogout(!activeLogout)
  }

  const autorization = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/authCashier`, {
        headers: {
          authorization: token,
        },
      });
    } catch (error) {
      if (error.response.status === 401) {
        logout()
      }
    }
  }

  useEffect(() => {
    autorization()
  }, [])

  return (
    <MainDiv $actLogout={activeLogout}>
      <FondoLogout>
        <ContLogout>
          <LogoutIcon />
          <h3>¿Quieres cerrar sesión?</h3>
          <button className='button Confirm' onClick={() => Logout()}>SI</button>
          <button className='button Cancel' onClick={() => setActiveLogout(!activeLogout)}>NO</button>
        </ContLogout>
      </FondoLogout>
      <ContainerBody>
        {!showListTransactions && (
          <ContainerHeader>
            <BodyHeader>
              <Grid>
                {showFields
                  ? <Button startIcon={<ArrowBackIosNewRoundedIcon className='icon' />} onClick={() => window.location.reload()} />
                  : <Button className='Logout' startIcon={<LogoutIcon />} onClick={() => setActiveLogout(!activeLogout)} />
                }
              </Grid>
              <Grid container justifyContent={"center"} >
                {showFields ? <Tittle text="Validacion de compra" /> : <Tittle text="Validacion de usuario" />}
              </Grid>
            </BodyHeader>
          </ContainerHeader>
        )}
        <ModuleStore >
          {showFields && nombre && (
            <ContainerInfo>
              <TypographyH4II text={nombre} />
              <TypographyH6 text="Cupo disponible de:" />
              {quotaAllocation.estado === "inactivo" && (
                <>
                  <TypographyH2 text={<FormatPrice price={quotaAllocation.cupo} />} fontColor="#fa3535 !important" />
                  <TypographyH6 text={"Cupo inactivo"} fontColor="#fa3535 !important" />
                </>
              )}
              {quotaAllocation.estado === "activo" && (
                <TypographyH2 text={<FormatPrice price={quotaAllocation.cupo} />} />
              )}
            </ContainerInfo>
          )}

          <ThemeProvider theme={createTheme} >
            <Grid container>
              <CssBaseline />
              <Grid component="form" noValidate onSubmit={(e) => { e.preventDefault() }} container justifyContent="center" mt={3}>
                {nombre ? null : (
                  <Grid item xs={10} sm={8} md={8} lg={10} xl={10} >
                    <Grid container justifyContent="center" >
                      <Grid item xs={10} sm={10} md={12} lg={8} xl={6.5}>
                        <InputNumber label="Número de cedula" value={numeroDocumento} onChange={(e) => handleNumeroDocumentoChange(e.target.value)} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                {step === 1 && (
                  <Grid item xs={10} sm={8} md={8} lg={10} xl={10}>
                    <GridContainerValidation container justifyContent="center">
                      <Grid item xs={10} sm={10} md={12} lg={8} xl={6.5}>
                        <Button360 label="Validar usuario" onClick={buscarPersona} disabled={isNumeroDocumentoEmpty} />
                      </Grid>
                      <Grid item xs={10} sm={10} md={12} lg={8} xl={6.5} mt={2}>
                        <Link to="/store/listTransactions">
                          <Button360Outlined label="Ver transacciones" onClick={handleConfirmTokenClick} />
                        </Link>
                      </Grid>
                    </GridContainerValidation>
                  </Grid>
                )}

                {showListTransactions && (
                  <DivTransactions>
                    <VoucherPurchase numeroDocumento={numeroDocumento} compra={"compra"} idComprae={idCompra.purchase_id}/>
                  </DivTransactions>
                )}

                {showFields && (
                  <Grid item xs={10} sm={8} md={8} lg={10} xl={10}>
                    <Grid container justifyContent="center" >
                      <Grid item xs={10} sm={10} md={12} lg={8} xl={6.5} >
                        <StyledInputText2 label=" Ingrese el valor de la compra" value={valorCompra.numberformat} onChange={handleValorCompraChange} id="formatted-numberformat-input" variant="outlined"
                          InputProps={{ inputComponent: NumericFormatCustom }} />
                      </Grid>
                    </Grid>
                    {step === 2 && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justifyContent="center" mt={10} >
                          <Grid item xs={10} sm={10} md={12} lg={8} xl={6.5} >
                            <Button360 label="Validar por Token" onClick={() => handleConfirmTokenClick("token")} />
                          </Grid>
                          <Grid item xs={10} sm={10} md={12} lg={8} xl={6.5} mt={2} >
                            <Button360Outlined label="Validar por OTP" onClick={() => handleConfirmTokenClick("otp-msj")} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {showTokenField && (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justifyContent="center" mt={2} >
                          <Grid item xs={10} sm={10} md={12} lg={8} xl={6.5} >
                            <InputNumber label={tokenLabel === "otp-msj" ? "Ingrese el código OTP - MSJ" : "Ingrese el token"} type="number" autoFocus onChange={(e) => setTokenValidation(e.target.value)} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}

                {showConfirmButton && (
                  <Grid item xs={10} sm={8} md={8} lg={10} xl={12}>
                    <Grid container justifyContent="center" mt={7} >
                      <Grid item xs={10} sm={10} md={12} lg={8} xl={5.5} >
                        <Button360 label="Validar Compra" onClick={confirmarCompra} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

              </Grid>
            </Grid>
          </ThemeProvider>
        </ModuleStore>
      </ContainerBody>
    </MainDiv>
  )
}