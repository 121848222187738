import styled from "styled-components";
import { Box, Toolbar } from "@mui/material";

export const BoxHome = styled(Box)`
  display: flex;
  height: 100vh;
  width: 100%;
  flex-direction: row;
`;

export const BoxContainHeader = styled(Box)`
  width: 20%;
  height: auto;
  background-color: #035533;
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100vh !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  
`;

export const HeaderDashboard = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  background-color: white;
  position: static;
  z-index: -1;

  .deploymentButton {
    display: flex;
    align-items: center;
  }

  .icon {
    color: #035533;
  }
`;

export const HeaderSidebar = styled(Toolbar)`
  display: flex;
  align-items: center;
  justify-content: center;

  .icon2 {
    color: white;
  }
`;

export const BodyDashboard = styled(Box)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;

  .gridContainerDashboard {
    display: flex;
    flex-direction: row;
  }

  .gridBody {
    display: flex;
  }
`;