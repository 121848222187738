import styled from "styled-components";
import { Box } from "@mui/material";

export const ModalEdit = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 42%;
  height: 40vh;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .GridBody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) and (max-height: 931px){
    margin-bottom: 20px;
  }
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 2% 2% 0% 2%;
  }



  @media (max-width: 900px) and (max-height: 931px),
    (max-width: 414) and (max-height: 931px) {
    width: 80%;
    height: 40vh;
  }
  @media (max-width: 900px) and (max-height: 715.21px){
    height: 50%;
  }
  @media (max-width: 1239.34px) and (max-height: 571.33px){
    height: 58%;
  }


  @media (max-width: 600px) and (max-height: 931px) {
    width: 80%;
    height: 35vh;
  }
  @media (max-width: 500px) and (max-height: 715.21px){
    height: 45%;
    width: 90%;
  }
`;

export const ImgCheck = styled.img`
  width: 205px;
  height: 150px;
  top: 2%;

  @media (max-width: 600px) {
    width: 100px;
    height: 100px;
  }
`;

export const ModalView = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: auto;
  padding-bottom: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 1% 2% 0% 2%;
  }

  .bodyCard {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 3% 0% 1.5% 2%;
  }

  .blockItem{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .start{
      display: flex;
      justify-content: start;
    }
    .end{
      display: flex;
      justify-content: end;
    }
  }

  .containerItem{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 1200px){
    width: 70%;
  }

  @media (max-width: 900px){
    width: 70%;
  }
 
  @media (max-width: 600px){
    width: 90%;
  }

`;
