import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

import { ModalView } from './Styled';
import { Modal, Grid, Typography, DialogTitle, DialogContentText, DialogContent } from '@mui/material';

import { useAuthContext } from '../../../Contexts/AuthContext';
import { LineDivider } from '../../../components/elements/lines/Styled';
import TypographyDetails from '../../../components/elements/labels/TypographyDetails';
import ButtonActiosn360 from '../../../components/elements/Buttons/ButtonActions360';
import FormatPrice from '../../../Utilities/FormatPrices';
import FormatId from '../../../Utilities/FormatIdentification';
import InputNumber from '../../../components/elements/Inputs/InputNumber';
import { StyledInputText } from '../../../components/elements/Inputs/Styled';

export default function ModalWithdrawalRequest({ decode, isModalOpen, setIsModalOpen }) {
  const [withdrawalValue, setWithdrawalValue] = useState(0);
  const { token } = useAuthContext();

  const handleInputChange = (event) => {
    const { value } = event.target;
    setWithdrawalValue(value);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const WithdrawalRequest = async () => {
    if (decode[0].accountType !== "" || decode[0].bank !== "" || decode[0].accountNumber !== "" || withdrawalValue !== 0) {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/insertWithdrawal`, {
          bank: decode[0].bank,
          type_of_account: decode[0].accountType,
          account: decode[0].accountNumber,
          withdrawal_value: parseInt(withdrawalValue),
          customer_id: decode[0].customer_id,
        }, {
          headers: {
            authorization: token,
          },
        })
        handleCloseModal();
        Swal.fire({
          icon: 'success',
          title: 'Solicitud exitosa',
          text: 'Su solicitud está en proceso de verificación para un desembolso por el area encargada',
          confirmButtonColor: '#035533',
        });
      } catch (error) {
        if (parseFloat(withdrawalValue) > decode[0].withdrawal_credit) {
          handleCloseModal();
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El monto a retirar pasa los limites del valor disponible de desembolso.',
            confirmButtonColor: '#035533',
          });
        }
      }
    } else {
      handleCloseModal();
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Por favor complete todos los campos requeridos.',
        confirmButtonColor: '#035533',
      });
    }

  }

  return (
    <div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <ModalView className="ModalStyle">
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <DialogTitle className='TypographyHeader'>
              Solicitud de desembolso
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description" fontColor="red" >
                El total de desembolsos son hasta el 50% del crédito. Cada transacción tiene un costo del 5% del valor desembolsado.
              </DialogContentText>
            </DialogContent>
            <Grid item className='GridHeader'>
              <Typography className='TypographyHeader'>Información básica</Typography>
              <LineDivider color="#035533" width="98%" />
            </Grid>

            <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={11.5}>
                <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TypographyDetails text="Nombre:" fontSize={"15px"} />
                </Grid>
                <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TypographyDetails text={decode[0].customer_name} fontColor="gray" />
                </Grid>
              </Grid>
              <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5} mt={2}>
                <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={5}>
                  <TypographyDetails text="Núm. de identificación:" fontSize={"15px"} />
                </Grid>
                <Grid item className='end' xs={12} sm={6} md={6} lg={5} xl={6}>
                  <TypographyDetails text={<FormatId id={`${decode[0].customer_id}`} />} fontColor="gray" />
                </Grid>
              </Grid>
              <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5} mt={2}>
                <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={4.8}>
                  <TypographyDetails text="Cupo disponible:" fontSize={"15px"} />
                </Grid>
                <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TypographyDetails text={<FormatPrice price={`${decode[0].available_credit}`} />} fontColor="gray" />
                </Grid>
              </Grid>
            </Grid>

            <Grid item className='GridHeader'>
              <Typography className='TypographyHeader'>Información de la cuenta</Typography>
              <LineDivider color="#035533" width="98%" />
            </Grid>

            <Grid container item className='bodyCard' xs={12} sm={11.5} md={11.5} lg={11.5} xl={11.5}>
              <Grid item xs={11.5} sm={5} md={2.9} lg={2.9} xl={2} mt={1}>
                <StyledInputText size='small' name="bank" required disabled={true} id="bank" label="Banco" variant="outlined" autoFocus value={decode[0].bank} />
              </Grid>
              <Grid item xs={11.5} sm={5} md={2.9} lg={2.9} xl={2} mt={1}>
                <StyledInputText size='small' name="type_of_account" required disabled={true} id="type_of_account" label="Tipo de cuenta" variant="outlined" autoFocus value={decode[0].accountType} />
              </Grid>

              <Grid item xs={11.5} sm={5} md={2.9} lg={2.9} xl={3} mt={1}>
                <InputNumber label="Número de cuenta" size={'small'} required disabled={true} value={decode[0].accountNumber}/>
              </Grid>
              <Grid item xs={11.5} sm={5} md={2.9} lg={2.9} xl={3} mt={1}>
                <InputNumber label="Valor a retirar" size="small" value={withdrawalValue} onChange={handleInputChange}/>
              </Grid>
            </Grid>

            <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={3.5}>
                  <TypographyDetails text="Monto max. a retirar:" fontSize={"15px"} />
                </Grid>
                <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                  <TypographyDetails text={<FormatPrice price={`${decode[0].withdrawal_credit}`} />} />
                </Grid>
              </Grid>
              <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={5}>
                </Grid>
                <Grid item className='end' xs={12} sm={6} md={6} lg={5} xl={6}>
                  <ButtonActiosn360 label="Solicitar desembolso" onClick={WithdrawalRequest} backgroundColor={"#035533"} />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ModalView>
      </Modal>
    </div>
  );
}
