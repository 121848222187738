import styled from "styled-components";
import { Grid } from "@mui/material";

export const HeaderProfile = styled(Grid)`
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 1rem;

  .icon {
    color: #035533;
  }
`;
