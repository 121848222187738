import { CardContent, CardHeader, Card } from "@mui/material";
import styled from "styled-components";

export const CardPublic = styled(Card)`
  width: 48.9%;
  height: auto; 


  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: 4%;
  }

  /* Dimensiones 100% */
  @media (max-width: 1451px) {
    width: 45%;
  }

  @media (max-width: 1310px) {
    width: 100%;
  }

  @media (max-width: 380px) {
    height: auto !important;
  }

  @media (max-width: 1496px) and (max-height: 716px) {
    height: 42vh !important;
  }

  @media (max-width: 1240px) and (max-height: 572px) {
    height: 55vh !important;
  }

  @media (max-width: 840px) {
    width: 100%;
  }
  @media (max-width: 599px) {
    width: 100%;
  }

  @media (max-width: 460px) {
    width: 100%;
  }

  /* Dimensiones 125% */
  @media (max-width: 1452px) and (max-height: 620px),
    (max-width: 1400px) and (max-height: 620px),
    (max-width: 740px) and (max-height: 620px) {
    width: 100%;
    height: 22%;
  }

  /* Dimensiones 150% */
  @media (max-width: 1196px) and (max-height: 477px),
    (max-width: 899px) and (max-height: 477px),
    (max-width: 715px) and (max-height: 556px) {
    width: 100%;
    height: 20%;
  }
`;

export const HeaderCard = styled(CardHeader)`
  display: flex;
  height: 15px;
  flex-direction: row;

  .GridHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50px;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 3% 0% 0% 2%;
    font-size: 14px;
  }

  .Icon {
    font-size: 15px;
  }
`;

export const BodyCard = styled(CardContent)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2%;

  .Grid {
    border: 1px solid gray;
    border-radius: 0.3rem;
    width: 25%;
    display: flex;
    justify-content: center;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .containerFormPublication{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .containerLabel {
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon {
    color: #6b6b6b;
    font-size: 50px;
  }

  .img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 2;

  }
`;
