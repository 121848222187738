import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import Swal from 'sweetalert2';
import axios from 'axios';
import ReplayIcon from '@mui/icons-material/Replay';
import { InputHidden, ModalActions } from './Style';
import useMediaQuery from '@mui/material/useMediaQuery';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridHeaderII } from '../../moduleDashboard/Styled';
import ProfileMenu from '../../../../Components/menu/Profile';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import FormatPrice from '../../../../../Utilities/FormatPrices';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { TittleModule } from '../../registrationEstablishment/Styled';
import InputDate from '../../../../../components/elements/Inputs/InputDate';
import { LineDivider } from '../../../../../components/elements/lines/Styled';
import { Box, IconButton, Grid, Modal, Tooltip, Typography } from '@mui/material';
import ComboBox360 from '../../../../../components/elements/ComboBox/comboBox360';
import ModalViewDetailsSaleEstablishment from '../modals/ViewSaleDetailsForEstablishment';
import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';
import ButtonActiosn360 from '../../../../../components/elements/Buttons/ButtonActions360';
import { useAuthContext } from '../../../../../Contexts/AuthContext';

const LiquidationSalesEstablishments = () => {
  const [salesEstablishment, setSalesEstablishment] = useState([]);
  const [selectedSalesId, setSelectdSalesId] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [searchedRows, setSearchedRows] = useState([]);
  const [isModalOpenTransactions, setIsModalOpenTransactions] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [establishment, setEstablishment] = useState(null);
  const { token } = useAuthContext();

  const isSmallScreen = useMediaQuery('(max-width: 1050px)');
  const filterDataEstablishment = {
    fechaInicio,
    fechaFinal,
    establishment,
    selectedSalesId
  }

  if (typeof (fechaInicio) === 'string') {
    if (fechaInicio.length === 0) {
      setFechaInicio(null)
    }
  }

  if (typeof (fechaFinal) === 'string') {
    if (fechaFinal.length === 0) {
      setFechaFinal(null)
    }
  }

  const fetchDateSales = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getTotalValuePurchases`, {
        firtsDate: fechaInicio,
        secondDate: fechaFinal,
        NIT: establishment || null,
      },{
        headers: {
          Authorization: token,
        },
      });
      if (response.data.length > 0) {
        const sortedCompras = response.data.sort((a, b) => new Date(b.purchase_date) - new Date(a.purchase_date));
        setSalesEstablishment(sortedCompras);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Hubo un error al traer la información. No hay datos disponibles.',
        confirmButtonColor: '#035533'
      });
    }
  };

  const handleViewModal = (nit) => {
    setSelectdSalesId(nit);
    setIsModalOpenTransactions(true);
  };

  const handleExportAllData = () => {
    exportData(salesEstablishment);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    handleReload();
  };

  const handleOpenModal = () => {
    setModalOpen(true)
    fetchDateSales();
  };

  const handleReload = () => {
    fetchDateSales()
  }

  const searchRows = () => {
    if (salesEstablishment && Array.isArray(salesEstablishment) && salesEstablishment.length > 0) {
      const filteredData = salesEstablishment.filter((row) => {
        return (
          (row.sales && row.sales.toString().includes(searchCriteria)) ||
          (row.business_name && row.business_name.includes(searchCriteria)) ||
          (row.valor_total_compras && row.valor_total_compras.toString().includes(searchCriteria))
        );
      });

      setSearchedRows(filteredData);
      exportData(filteredData);
    }
  };

  const exportData = (data) => {
    if (data.length > 0) {
      const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
      });
      const csv = generateCsv(csvConfig)(data);
      download(csvConfig)(csv);
    }
  };

  const applyFilters = () => {
    if (searchCriteria === '') {
      return salesEstablishment;
    } else if (salesEstablishment && Array.isArray(salesEstablishment) && salesEstablishment.length > 0) {
      return salesEstablishment.filter((row) => {
        return (
          (row.business_name && row.business_name.toLowerCase().includes(searchCriteria))

            (row.business_name && row.business_name.toUpperCase().includes(searchCriteria))

            (row.sales && row.sales.toString().includes(searchCriteria)) ||
          (row.business_name && row.business_name.includes(searchCriteria)) ||
          (row.valor_total_compras && row.valor_total_compras.toString().includes(searchCriteria))
        );
      });
    }
    return [];
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'NIT',
        header: 'NIT',
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell, row }) => (
          <Box component="span"
            sx={(theme) => ({
              fontWeight: 'bold',

              display: "flex",
              justifyContent: "start",
              marginLeft: '25%'
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'business_name',
        header: 'Establecimiento',
        size: 100,
        muiTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ cell, row }) => (
          <Box component="span"
            sx={(theme) => ({
              fontWeight: 'bold',

              display: "flex",
              justifyContent: "start",
              marginLeft: '25%'
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'valor_total_compras',
        header: 'Valor de liquidación',
        size: 1,
        Cell: ({ cell, row }) => (
          <Box component="span"
            sx={(theme) => ({
              fontWeight: 'bold',

              display: "flex",
              justifyContent: "center",
              marginRight: '25%'
            })}
          >
            {<FormatPrice price={cell.getValue()} />}
          </Box>
        ),
      },
      {
        accessorKey: 'cantidad_compras',
        header: 'Cant. ventas',
        size: 1,
        Cell: ({ cell, row }) => (
          <Box component="span"
            sx={(theme) => ({
              fontWeight: 'bold',

              display: "flex",
              justifyContent: "start",
              marginLeft: '25%'
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        size: 1,
        Cell: ({ row }) => {
          return (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
              <Tooltip title="Ver detalle">
                <IconButton style={{ color: '#035533' }} onClick={() => handleViewModal(row.original.NIT)}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    fetchDateSales();
  }, []);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <ModalActions className="ModalStyle">
          <Grid item className='GridHeader' xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className='TypographyHeader'>Filtrar total ventas generales por periodo y establecimiento</Typography>
            <LineDivider color="#035533" width="96%" />
          </Grid>

          <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} m={2}>
            <ComboBox360 label="Establecimiento" type={'establecimiento'} selectedValue={establishment} setSelectedValue={setEstablishment}/>
          </Grid>
          <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} m={2}>
            <InputDate label={""} value={fechaInicio} onChange={(e) => setFechaInicio(e.target.value)} />
          </Grid>
          <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} m={2}>
            <InputDate label={""} value={fechaFinal} onChange={(e) => setFechaFinal(e.target.value)} />
          </Grid>
          <Grid item m={2} textAlign={"end"}>
            <ButtonActiosn360 label="Buscar ventas" backgroundColor={"#035533"} onClick={handleCloseModal} />
          </Grid>
        </ModalActions>
      </Modal>

      <div>
        <GridHeaderII container xl={12} pb={1}>
          <TittleModule >Lista de ventas generales</TittleModule>
          <ProfileMenu />
        </GridHeaderII>
        <Grid item display={"flex"} xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container textAlign={"end"} item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} m={1}>
              <ButtonActiosn360 label="Filtrar por fechas" onClick={handleOpenModal} backgroundColor={'#035533'} startIcon={<ContentPasteSearchRoundedIcon />} />
            </Grid>
          </Grid>
        </Grid>

        <InputHidden type="text" placeholder="Criterio de búsqueda (Fecha)" value={searchCriteria} onChange={(e) => setSearchCriteria(e.target.value)} />
        <MaterialReactTable columns={columns} data={searchedRows.length > 0 ? searchedRows : applyFilters()} enableGlobalFilterModes
          initialState={{
            showGlobalFilter: false,
            globalFilter: searchCriteria,
            density: 'compact'
          }}
          renderTopToolbarCustomActions={() => (
            <Grid item display={"flex"} xs={3} sm={3} md={3} lg={3} xl={3}>
              <Tooltip arrow placement="bottom" title="Reload">
                <IconButton onClick={() => handleReload()}>
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
              <Grid item m={1}>
                <ButtonActiosn360 label={isSmallScreen ? "..." : "Tabla"} onClick={handleExportAllData} backgroundColor={'#035533'} startIcon={<FileDownloadIcon />} />
              </Grid>
              <Grid item m={1}>
                <ButtonActiosn360 label={isSmallScreen ? "..." : "Buscados"} onClick={searchRows} backgroundColor={'#035533'} startIcon={<SystemUpdateAltIcon />} />
              </Grid>
            </Grid>
          )}
          positionGlobalFilter="right"
          muiSearchTextFieldProps={{
            placeholder: `Buscar en ${salesEstablishment.length} registros`,
            sx: { minWidth: '100px' },
            variant: 'standard',
            value: searchCriteria,
            onChange: (e) => {
              setSearchCriteria(e.target.value);
            },
          }}
          onFilterChange={(newFilter) => {
            setSearchCriteria(newFilter);
          }}
        />

        {selectedSalesId !== null && (
          <ModalViewDetailsSaleEstablishment isModalOpenTransactions={isModalOpenTransactions} setIsModalOpenTransactions={setIsModalOpenTransactions}
            selectedSalesId={selectedSalesId} filterDataEstablishment={filterDataEstablishment} data={salesEstablishment} />
        )}
      </div>
    </Grid>
  );
};

export default LiquidationSalesEstablishments;