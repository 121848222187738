import { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

import { Chart as ChartJS, BarElement } from 'chart.js';
import { useAuthContext } from '../../../Contexts/AuthContext';

export default function DiagramBarsEstablishment() {
  const [statisticsSalesEstablishment, setStatisticsSalesEstablishment] = useState([])
  const { token } = useAuthContext();

  const totalSales = statisticsSalesEstablishment.map(item => item.total_ventas);
  const establishment = statisticsSalesEstablishment.map(item => item.establecimiento);

  const ResultsSales = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/TotalPurchaseByEstablishment`, {
        headers: {
          authorization: token,
        },
      }
      );
      setStatisticsSalesEstablishment(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    ResultsSales()
  }, [])

  const misoptions = {
    responsive: true,
    animation: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: { min: 100000, max: 1000000, ticks: { color: '#013f58' }},
      x: { ticks: { color: '#013f58' }}
    }
  };

  const midata = {
    labels: establishment,
    datasets: [
      {
        label: 'Ingreso total del establecimiento',
        data: totalSales,
        backgroundColor: [
          '#0e7a4d',
          '#32faa5',],
        borderColor: [
          '#0e7a4d',
          '#32faa5',
        ]
      }
    ]
  };

  return <Bar data={midata} options={misoptions} />
}

ChartJS.register(BarElement);


