import styled from 'styled-components';
import Button from '@mui/material/Button';

export const StyledButton360 = styled(Button)`
  flex: 1;
  height: 100%;
  width: 100%;
  font-size: 25px !important;
  background-color: #035533 !important;

  @media (max-width: 455px) {
    width: 100%;
    height: 60px;
    font-size: 18px !important;
  }
`;

export const StyledButton360Outlined = styled(Button)`
  flex: 1;
  height: 100%;
  width: 100%;
  font-size: 25px !important;
  margin-right: 5px;
  color: #035533 !important;

  @media (max-width: 455px) {
    width: 100%;
    height: 60px;
    font-size: 18px !important;
  }
`;

export const StyledButtonActiosn360 = styled(Button)`
  flex: 1;
  font-size: 15px ;
  background-color: #035533;

  &:hover {
    background-color: #035533 !important;
  }

`;


