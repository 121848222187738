import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../../Contexts/AuthContext';
import { HOME, STORE } from '../routes/path';

export default function PublicRoute() {
    const {token, role} = useAuthContext();
    if (!token && !role) {
        return <Outlet />
    } else if(token && role === "administrador"){
        return <Navigate to={HOME} />;
    } else if (token && role === "cajero"){
        return <Navigate to={STORE} />;
    } else if (token && role === "administradorEstablecimiento"){
        return <Navigate to={HOME} />;
    } 
}