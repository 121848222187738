import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

import { ModalView } from './Styled';
import { Modal, Grid, Typography } from '@mui/material';
import { useAuthContext } from '../../../../../Contexts/AuthContext'
import { LineDivider } from '../../../../../components/elements/lines/Styled';
import ButtonActiosn360 from '../../../../../components/elements/Buttons/ButtonActions360';
import TypographyDetails from '../../../../../components/elements/labels/TypographyDetails'

export default function ModalViewDetailsCashiersBranch({ selectedCashierId, isModalOpenCashier, setIsModalOpenCashier }) {
  const [cashierData, setCashierData] = useState(null);
  const { token } = useAuthContext();

  useEffect(() => {
    async function fetchCashierData() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getSingleCashier/${selectedCashierId}`, {
          headers: {
            authorization: token,
          },
        });
        setCashierData(response.data);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Hubo un error al trer la informacion. No hay datos disponibles.',
          confirmButtonColor: '#035533',
        });
      }
    }

    if (selectedCashierId && isModalOpenCashier) {
      fetchCashierData();
    }
  }, [selectedCashierId, isModalOpenCashier]);

  const handleCloseModal = () => {
    setIsModalOpenCashier(false);
  };

  return (
    <div>
      <Modal open={isModalOpenCashier} onClose={handleCloseModal}>
        <ModalView className="ModalStyle">
          {cashierData && (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Grid item className='GridHeader'>
                <Typography className='TypographyHeader'>Información general</Typography>
                <LineDivider color="#035533" width="98%" />
              </Grid>

              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={3}>
                    <TypographyDetails text="Sucursal de establ.:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={9}>
                    <TypographyDetails text={cashierData[0].business_name} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={5} xl={5} >
                    <TypographyDetails text="Correo electrónico:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6} >
                    <TypographyDetails text={cashierData[0].cashier_email} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={5} xl={5}>
                    <TypographyDetails text="Dirección:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={cashierData[0].establishment_address} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
               <Grid item className='start' xs={12} sm={6} md={6} lg={5} xl={5}>
                 <TypographyDetails text="Estado del cajero:" fontSize={"15px"} />
               </Grid>
               <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                 <TypographyDetails text={cashierData[0].status} fontColor="gray" />
               </Grid>
             </Grid>
              </Grid>

              <Grid item className='GridHeader'>
                <Typography className='TypographyHeader'>Contactos</Typography>
                <LineDivider color="#035533" width="98%" />
              </Grid>

              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Num. identificación:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={cashierData[0].cashier_id} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={5} xl={5}>
                    <TypographyDetails text="Nombre:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={cashierData[0].cashier_name} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Dirección:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={cashierData[0].cashier_address} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={5} xl={5}>
                    <TypographyDetails text="Número de teléfono:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={cashierData[0].cashier_phone} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={5} xl={5}>
                    <TypographyDetails text="Correo electrónico:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={cashierData[0].personal_email} fontColor="gray" />
                  </Grid>
                  
                </Grid>
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={5}>
                  </Grid>
                  <Grid item className='end' xs={12} sm={12} md={12} lg={5} xl={6} mt={5}>
                    <ButtonActiosn360 label="CERRAR" onClick={() => handleCloseModal()} backgroundColor={"#035533"} />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </ModalView>
      </Modal>
    </div>
  );
}
