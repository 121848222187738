import "swiper/css";
import "./styles.css";
import "swiper/css/pagination";
import { Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Card, CardMedia, Grid, Typography } from "@mui/material";
import { ContEstab, ContSlider, ContaImage, Establishments, FlexRow } from "../../../pages/client/forms/Styled";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useAuthContext } from "../../../Contexts/AuthContext";


export default function SliderCategoryEstablishment() {
  const [establishment, setEstablishment] = useState([]);
  const [categories, setCategories] = useState([]);
  const { role } = useAuthContext();
  let { id } = useParams();
  const city = localStorage.getItem('ciudad') || null;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCategories/${role}`)
        setCategories(response.data);
      } catch (error) { }
    };

    const fetchEstablishment = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/getEstablishmentOnCategorySub/${id}`, {
          city: city
        });
        setEstablishment(response.data);
      } catch (error) {
      }
    };

    fetchEstablishment();
    fetchCategories()
  }, [city, id]);

  return (
    <>
      <Swiper slidesPerView={3} spaceBetween={30} pagination={{ clickable: true }} modules={[Pagination]} className="mySwiper">
        <ContSlider>
          {categories.map((category, index) => (
            <Grid item m={1} mb={2} key={index}>
              <ContaImage key={index}>
                <Link to={`/client/subcategories/${category.category_id}`} key={index}>
                  <Card sx={{ margin: "0px 0px 5px 10px", borderRadius: "100px", boxShadow: "0px 2px 5px 2px #0000001b" }}>
                    <CardMedia className="cardMedia" image={`${process.env.REACT_APP_API_URL}/${category.image}`} title={category.alt} />
                  </Card>
                </Link>
                <div className="ProductDescription">
                  <Typography sx={{ marginTop: "10px" }} variant="body2" color="text.secondary">
                    {category.category_name}
                  </Typography>
                </div>
              </ContaImage>
            </Grid>
          ))
          }
        </ContSlider>
      </Swiper >
      <ContEstab>
        <Establishments>
          <FlexRow className="EstablishmentName">
            <h1>Establecimientos</h1>
          </FlexRow>
        </Establishments>
      </ContEstab>
      <Grid container item className="containeEstabishment" xs={12} sm={12} md={12} lg={12} xl={12} ml={5}>
        {establishment.map((est, index) => (
          <ContaImage key={index}>
            <Link to={est.url}>
              <Card sx={{ boxShadow: "0px 2px 5px 2px #0000001b" }}>
                <CardMedia className="cardMediaEst" image={`${process.env.REACT_APP_API_URL}/${est.image}`} title={est.alt} />
              </Card>
            </Link>
            <div className="ProductDescription">
              <Typography sx={{ marginTop: "10px" }} variant="body2" color="text.secondary">
                {est.business_name}
              </Typography>
            </div>
          </ContaImage>
        ))
        }
      </Grid>
    </>
  );
}
