import { useAuthContext } from '../../Contexts/AuthContext';
import { Navigate, Outlet } from 'react-router-dom';
import { PUBLIC } from '../routes/path';

export default function ClientRoute() {
    const {token, role} = useAuthContext();
    if (token && role === "cliente") {
        return <Outlet />
    }

    return <Navigate to={PUBLIC} />;

}