import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { Grid, Typography } from '@mui/material';
import ProfileMenu from '../../../Components/menu/Profile';
import FormatPrice from '../../../../Utilities/FormatPrices';
import DiagramPie from '../../../Components/diagrams/PieAdmin';
import DiagramBars from '../../../Components/diagrams/BarsAdmin';
import { useAuthContext } from '../../../../Contexts/AuthContext';
import { TittleModule } from '../registrationEstablishment/Styled';
import { formatNumber } from '../../../../Utilities/NumericFormat';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import CardInfoDashboard from '../../../Components/card/CardInfoDashboard';
import TypographyH4 from '../../../../components/elements/labels/TypographyH4';
import DiagramPieBranch from '../../../Components/diagrams/PieAdminBranch';
import DiagramPieEstablishment from '../../../Components/diagrams/PieAdminEstablishment';
import { CardDiagram, DivDashboard1, DivDashboard2, GridHeaderII, GridMarca } from './Styled';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import DiagramBarsAdminEstablishment from '../../../Components/diagrams/BarsAdminEstablishment';
import DiagramBarsAdminBranch from '../../../Components/diagrams/BarsAdminBranch';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)
export default function Etatistics({ type }) {
  const { token, logout } = useAuthContext();
  const [establishments, setEstablishments] = useState([]);
  const [infoEstablishments, setInfoEstablishments] = useState([0]);
  const [infoBranch, setInfoBranch] = useState([0]);

  const tokenDec = localStorage.getItem('token');
  const decodedToken = jwtDecode(tokenDec);

  const getEstablishments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getEstablishments/${null}`, {
        headers: {
          authorization: token,
        },
      });
      setEstablishments(response.data);
    } catch (error) {
    }
  };

  const getInfoEstablishments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/TotalPurchases/${decodedToken.establishment_id}`, {
        headers: {
          authorization: token,
        },
      });
      setInfoEstablishments(response.data);
    } catch (error) {
    }
  };

  const getInfoBranch = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getTotalValuePurchases`,{
        NIT : decodedToken.NIT
      }, {
        headers: {
          authorization: token,
        },
      });
      setInfoBranch(response.data);
    } catch (error) {
    }
  };

  const autorizationAdmin = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/AuthAdmin`, {
        headers: {
          authorization: token,
        },
      });
    } catch (error) {
      if (error.response.status === 401) {
        logout()
      }
    }
  }

  const autorizationAdminEst = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/AuthAdminEst`, {
        headers: {
          authorization: token,
        },
      });
    } catch (error) {
      if (error.response.status === 401) {
        logout()
      }
    }
  }

  const autorizationAdminBranch = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/authSuperAdminEst`, {
        headers: {
          authorization: token,
        },
      });
    } catch (error) {
      if (error.response.status === 401) {
        logout()
      }
    }
  }

  useEffect(() => {  
    if (window.localStorage.getItem("role") === "administrador") {
      getEstablishments()
      autorizationAdmin()
    } else if (window.localStorage.getItem("role") === "administradorEstablecimiento") {
      autorizationAdminEst()
      getInfoEstablishments()
    } else if (window.localStorage.getItem("role") === "superAdministradorEstablecimiento") {
      autorizationAdminBranch()
      getInfoBranch()
    }
  }, [])

  return (
    type === "administrador" ? (
      <Grid height={'auto'} >
        <GridHeaderII >
          <TittleModule >Estadisticas lenddi</TittleModule>
          <ProfileMenu />
        </GridHeaderII>

        <GridMarca container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DivDashboard1 container item xs={11.5} sm={12} md={12} lg={7} xl={7}>
            <Grid container item className='GridCards' xs={12} sm={12} md={12} lg={12} xl={12}>
              <CardInfoDashboard typeCard={'registered'} label={'administrador'} />
              <CardInfoDashboard typeCard={'withQuote'} label={'administrador'} />
              <CardInfoDashboard typeCard={'pending'} label={'administrador'} />
              <CardInfoDashboard typeCard={'disabled'} label={'administrador'} />
            </Grid>

            <Grid container item className='GridBar' xs={12} sm={12} md={12} lg={12} xl={12}>
              <CardDiagram type="bar">
                <DiagramBars user={'admin'} />
              </CardDiagram>
            </Grid>
          </DivDashboard1>

          <DivDashboard2 container item xs={11.5} sm={12} md={12} lg={5} xl={4.8}>
            <Grid container item className='GridCard' xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
              <CardDiagram type="establishment" width="100%">
                <Grid container className='info'>
                  <Grid container >
                    <Typography className='TypographyHeader'> <BusinessRoundedIcon className="icon" /> Establecimientos registrados</Typography>
                  </Grid>
                  <Grid container className='item'>
                    <TypographyH4 fontColor='gray' text={formatNumber(establishments.length)} mt={1} />
                  </Grid>
                </Grid>
              </CardDiagram>
            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CardDiagram type="pie">
                <DiagramPie user={'admin'} />
              </CardDiagram>
            </Grid>
          </DivDashboard2>
        </GridMarca>
      </Grid>
    ) : type === "administradorEstablecimiento" ? (
      <Grid>
        <GridHeaderII>
          <TittleModule >Estadisticas sucursal</TittleModule>
          <ProfileMenu />
        </GridHeaderII>

        <GridMarca container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DivDashboard1 container item xs={12} sm={12} md={12} lg={7} xl={7}>
            <Grid container item className='GridCards' xs={12} sm={11} md={12} lg={12} xl={12} >
              <CardInfoDashboard typeCard={'registeredCashier'} label={'administradorSucursal'} />
              <CardInfoDashboard typeCard={'activeCashier'} label={'administradorSucursal'} />
              <CardInfoDashboard typeCard={'inactiveCashier'} label={'administradorSucursal'} />
              <CardInfoDashboard typeCard={'sales'} label={'administradorSucursal'} sales={infoEstablishments[0].Cantidad_total_compras} />
            </Grid>

            <Grid container item className='GridBar' xs={12} sm={12} md={12} lg={12} xl={12}>
              <CardDiagram type="bar">
                < DiagramBarsAdminBranch />
              </CardDiagram>
            </Grid>
          </DivDashboard1>

          <DivDashboard2 container item xs={12} sm={12} md={12} lg={5} xl={4.8}>
            <Grid container item className='GridCard' xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
              <CardDiagram type="establishmentII" width="100%">
                <Grid container className='info'>
                  <Grid container >
                    <Typography className='TypographyHeader'> <BusinessRoundedIcon className="icon" /> Total valor de ventas</Typography>
                  </Grid>
                  <Grid container className='item'>
                    <TypographyH4 fontColor='gray' text={<FormatPrice price={infoEstablishments[0].Valor_total_compras} />} ml={2.5} mt={1} />
                  </Grid>
                </Grid>
              </CardDiagram>
            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CardDiagram type="pie">
                <DiagramPieBranch />
              </CardDiagram>
            </Grid>
          </DivDashboard2>
        </GridMarca>
      </Grid>
    ) : (
      <Grid>
        <GridHeaderII>
          <TittleModule >Estadisticas establecimiento</TittleModule>
          <ProfileMenu />
        </GridHeaderII>

        <GridMarca container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <DivDashboard1 container item xs={12} sm={12} md={12} lg={7} xl={7}>
            <Grid container item className='GridCards' xs={12} sm={11} md={12} lg={12} xl={12} >
              <CardInfoDashboard typeCard={'registeredBranch'} label={'administradorEstablecimiento'} />
              <CardInfoDashboard typeCard={'activeBranch'} label={'administradorEstablecimiento'} />
              <CardInfoDashboard typeCard={'inactiveBranch'} label={'administradorEstablecimiento'} />
              <CardInfoDashboard typeCard={'salesBranch'} label={'administradorEstablecimiento'} sales={infoBranch[0].cantidad_compras} />
            </Grid>

            <Grid container item className='GridBar' xs={12} sm={12} md={12} lg={12} xl={12}>
              <CardDiagram type="bar">
                <DiagramBarsAdminEstablishment />
              </CardDiagram>
            </Grid>
          </DivDashboard1>

          <DivDashboard2 container item xs={12} sm={12} md={12} lg={5} xl={4.8}>
            <Grid container item className='GridCard' xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
              <CardDiagram type="establishmentII" width="100%">
                <Grid container className='info'>
                  <Grid container >
                    <Typography className='TypographyHeader'> <BusinessRoundedIcon className="icon" /> Total valor de ventas</Typography>
                  </Grid>
                  <Grid container className='item'>
                    <TypographyH4 fontColor='gray' text={<FormatPrice price={infoBranch[0].valor_total_compras} />} ml={2.5} mt={1} />
                  </Grid>
                </Grid>
              </CardDiagram>
            </Grid>

            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CardDiagram type="pie">
                <DiagramPieEstablishment />
              </CardDiagram>
            </Grid>
          </DivDashboard2>
        </GridMarca>
      </Grid>
    )
  )
}