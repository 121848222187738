import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { PUBLIC } from '../router/routes/path';


export const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const [role, setRole] = useState(window.localStorage.getItem("role") || null);
  const [token, setToken] = useState(window.localStorage.getItem("token") || null);
  const navigate = useNavigate()

  const login = useCallback(function (newToken, newRole) {
    if (newToken) {
      window.localStorage.setItem("token", newToken);
      setToken(newToken);
    }
    if (newRole) {
      window.localStorage.setItem("role", newRole);
      setRole(newRole);
    }
  }, []);

  const logout = useCallback(function () {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("role");
    setToken(null);
    setRole(null);
  }, []);

  const validate = useCallback(
    async function (token, role) {
      if (!window.localStorage.getItem("token") || !window.localStorage.getItem("role")) {
        logout();
      }

      let endpoint = null
      if (role === "administradorEstablecimiento") {
        endpoint = "/AuthAdminEst";
      } else if (role === "administrador") {
        endpoint = "/AuthAdmin";
      } else if (role === "cajero") {
        endpoint = "/authCashier";
      } else if (role === "cliente") {
        endpoint = "/AuthClient"
      } else if (role === "/authSuperAdminEst") {
        endpoint = "/authSuperAdminEst"
      }

      if (endpoint) {
        try {
          await axios.get(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
            headers: { authorization: token }
          })

        } catch (error) {
          logout();
          navigate(PUBLIC)
        }
      }

    },
    [logout]
  );


  if (token && role) {
    validate(token, role)
  }

  useEffect(() => {
    if (token && role === "cliente") {
      navigate('/client');
    }
  }, []);

  const value = useMemo(
    () => ({ validate, login, logout, token, role }),
    [validate, role, token, login, logout]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthContextProvider.propTypes = {
  children: PropTypes.object,
};

export function useAuthContext() {
  return useContext(AuthContext);
}
