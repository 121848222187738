import "swiper/css";
import "./styles.css";
import "swiper/css/pagination";
import { Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Card, CardMedia, Grid, Tooltip, Typography } from "@mui/material";
import { ContEstablecimiento, ContPromotion, ContaImage, ContaImageEstablecimiento } from "../../../pages/client/forms/Styled";
import { Link } from "react-router-dom";
import StarRed from '../../../Assets/images/iconos/StarRed.svg'

export default function SliderEstablishmentPromo({ promotions }) {
  return (
    <>
      <Swiper slidesPerView={3} spaceBetween={30} pagination={{ clickable: true }} modules={[Pagination]} className="mySwiper">
        <ContEstablecimiento item xs={12} sm={12} md={12} lg={12} xl={12} ml={5}>
            {promotions.map((promotion, index) => (
              <Grid className="containerPromotion" container item xs={5} sm={3.5} md={2.5} lg={2} xl={1.5} key={index}>
                <Grid container item className="containeEstabishment" xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                  <Tooltip title={promotion.establishment}>
                    <ContaImageEstablecimiento>
                      <ContPromotion style={{padding:"0rem 7rem 0rem 0rem"}}>
                        <img src={StarRed} alt="Promoción" />
                        <Typography variant="button" className="textDescount" mb={2}>{promotion.discount} %</Typography>
                        <Typography variant="body2" className="textPromo" mt={2}  >De descuento</Typography>
                      </ContPromotion>
                      <Link to={promotion.url}>
                        <Card sx={{ boxShadow: "0px 2px 5px 2px #0000001b" }}>
                          <CardMedia className="cardMediaEst" image={`${process.env.REACT_APP_API_URL}/${promotion.image}`} title={promotion.alt} />
                        </Card>
                      </Link>
                        <Grid container item alignItems={'flex-start'}>
                        <Typography sx={{ marginTop: "5px" }} variant="button" color="text.secondary">
                          {promotion.promotion_name}
                        </Typography>
                      </Grid>
                      <Grid container item alignItems={'flex-start'}>
                        <Typography variant="body2" color="text.secondary">
                          $ {promotion.promotion_price}
                        </Typography>
                      </Grid>
                    </ContaImageEstablecimiento>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </ContEstablecimiento>
      </Swiper>
    </>
  );
}
