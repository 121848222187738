import React from 'react';
import { StyledTypography } from './Styled';

function TypographyH4({ text, mt, ml, fontColor, sx }) {
  return (
    <StyledTypography variant='h4' mt={mt} ml={ml} style={sx} sx={{
      '@media (max-width: 600px)': {
        fontSize: '22px !important'
      },
      '@media (max-width: 1250px) and (max-height: 836px) ': {
        padding: '0% 0% 3% 0%',
        fontSize: '30px !important',
      },
      '@media (max-width: 1470px) and (max-height: 668px) ': {
        fontSize: '22px !important',
      },
      '@media (max-width: 1223px) and (max-height: 556px) ': {
        fontSize: '18px !important',
      }
    }} color={fontColor}>
      {text}
    </StyledTypography>
  );
}

export default TypographyH4;