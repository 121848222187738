import React from 'react';
import { StyledTypographyTitle } from './Styled';

function TypographyH2({ text, mt, ml, fontColor }) {
  return (
    <StyledTypographyTitle variant='h2' mt={mt} ml={ml} sx={{

      '@media (max-width: 900px)': {
        fontSize: '50px !important',
      },
      '@media (max-width: 600px)': {
        fontSize: '35px !important',
      },
      '@media (max-width: 455px)': {
        fontSize: '40px !important',
      },
    }} color={fontColor}>
      {text}
    </StyledTypographyTitle>
  );
}

export default TypographyH2;