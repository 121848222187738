import "swiper/css";
import "./styles.css";
import "swiper/css/pagination";
import { Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Card, CardMedia, Fab, Grid, Typography } from "@mui/material";
import { ContEstablecimiento, ContaImageEstablecimiento } from "../../../pages/client/forms/Styled";
import { Link } from "react-router-dom";

export default function SliderSubcategoryEstablishment({ subCategories }) {
  return (
    <>
      <Swiper slidesPerView={3} spaceBetween={30} pagination={{ clickable: true }} modules={[Pagination]} className="mySwiper">
        <ContEstablecimiento>
          <Grid container className="containerSubcategoriesList" ml={3} mt={-3}>
            {subCategories.map((subcategory, index) => (
              <Grid item m={1} mb={2} key={index}>
                <Link to={`/client/establishmentXsubcategories/${subcategory.subcategory_id}`} key={index}>
                  <Fab variant="extended" size="small" color="red">
                    {subcategory.subcategory_name}
                  </Fab>
                </Link>
              </Grid>
            ))}
          </Grid>

          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            {subCategories.map((establishment, index) => (
              <>
                <Typography className="typography" variant="body2" color="#035533">
                  {establishment.subcategory_name}
                </Typography> 
                <Grid container className="containerSubcategoriesName" item key={index}>
                  {establishment.establishments.length > 0 && establishment.establishments.map((est, index) => (
                    <Grid item className="containeEstabishment" key={index} >
                      <ContaImageEstablecimiento>
                        <Link to={est.url}>
                          <Card sx={{ boxShadow: "0px 2px 5px 2px #0000001b" }}>
                            <CardMedia className="cardMediaEst" image={`${process.env.REACT_APP_API_URL}/${est.image}`} title={est.alt} />
                          </Card>
                        </Link>
                        <Grid item alignItems={'center'} >
                          <Typography sx={{ marginTop: "10px" }} variant="body2" color="text.secondary">
                            {est.business_name}
                          </Typography>
                        </Grid>
                      </ContaImageEstablecimiento>
                    </Grid>
                  ))}
                </Grid>
              </>
            ))}
          </Grid>
        </ContEstablecimiento>
      </Swiper>
    </>
  );
}
