import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

import { TittleModule } from '../Styled';
import ReplayIcon from '@mui/icons-material/Replay';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridHeaderII } from '../../moduleDashboard/Styled';
import ProfileMenu from '../../../../Components/menu/Profile';
import ModalViewDetailsBranch from '../modals/ViewDetailsBranch';
import { useAuthContext } from '../../../../../Contexts/AuthContext';
import { Box, IconButton, Grid, Tooltip, Typography } from '@mui/material';
import ModalViewDetailsAdminsBranch from '../modals/ViewDetailsAdminsBranch';
import ModalViewDetailsCashiersBranch from '../modals/ViewDetailsCashiersBranch';


const ListBranchs = () => {
  const { token } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenAdmin, setIsModalOpenAdmin] = useState(false);
  const [isModalOpenCashier, setIsModalOpenCashier] = useState(false);
  const [establishments, setEstablishments] = useState([]);
  const [selectedEstablishmentId, setSelectedEstablishmentId] = useState(null);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [selectedCashierId, setSelectedCashierId] = useState(null);
  const tokenDec = localStorage.getItem('token');
  const decodedToken = jwtDecode(tokenDec);

  const getEstablishments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/selectEstablishmentBranch/${decodedToken.NIT}`, {
        headers: {
          authorization: token,
        },
      });
      setEstablishments(response.data);
    } catch (error) {
    }
  };

  const handleViewModalBranch = (idBranch) => {
    setSelectedEstablishmentId(idBranch);
    setIsModalOpen(true);
  };

  const handleViewModalAdminBranch = (idAdmin) => {
    setSelectedAdminId(idAdmin);
    setIsModalOpenAdmin(true);
  };

  const handleViewModalCashierBranch = (idAdmin) => {
    setSelectedCashierId(idAdmin);
    setIsModalOpenCashier(true);
  };

  const handleReload = () => {
    getEstablishments()
  }



  useEffect(() => {
    getEstablishments();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'establishment_id',
        header: 'Id sucursal'
      },

      {
        accessorKey: 'establishment_branch',
        header: 'Nombre / dirección',
      },
      {
        accessorKey: 'status',
        header: 'Estado del establecimiento',
        Cell: ({ cell, row }) => (
          <Box
            Establishments="span"
            sx={(theme) => ({
              color:
                row.original.status === 'activo'
                  ? theme.palette.success.dark
                  : row.original.status === 'pendiente'
                    ? 'rgb(255, 174, 0)'
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              fontWeight: 'bold',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        )
      },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        size: 1,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            <Tooltip title="Ver detalle">
              <IconButton style={{ color: '#035533' }} onClick={() => handleViewModalBranch(row.original.establishment_id)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={11}>
        <GridHeaderII container xl={12} mt={-20} pb={2}>
          <TittleModule >Lista de sucursales</TittleModule>
          <ProfileMenu />
        </GridHeaderII>

        <MaterialReactTable columns={columns} data={establishments}
          renderTopToolbarCustomActions={() => (
            <>
              <Tooltip arrow placement="bottom" title="Reload">
                <IconButton onClick={() => handleReload()}>
                  <ReplayIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          enableExpandAll={false}
          muiDetailPanelProps={() => ({
            sx: (theme) => ({
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? 'rgba(255,210,244,0.1)'
                  : 'rgba(0,0,0,0.1)',
            }),
          })}
          initialState={{
            density: 'compact'
          }}
          muiExpandButtonProps={({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
            sx: {
              transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
              transition: 'transform 0.2s',
            },
          })}
          positionActionsColumn="last"
          renderDetailPanel={({ row }) =>
            row.original.business_name ? (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <GridHeaderII item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <TittleModule >Lista de administradores</TittleModule>
                  </GridHeaderII>
                  <Box sx={{ display: 'flex', flexDirection: 'column', margin: 'auto', gridTemplateColumns: '1fr 1fr', width: '100%', }} >
                    {row.original.adminsEst.length === 0 &&
                      <Typography variant="body1" color="textSecondary">
                        El establecimiento no tiene administradores registrados
                      </Typography>
                    }
                    {row.original.adminsEst.map((admin) => (
                      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", justifyContent: "space-between" }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} pl={5}>
                          <Typography key={admin.id_admin}>{admin.id_admin} - {admin.adminName}</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} justifyContent={'center'}>
                          <Typography color={admin.status === "activo" ? "#035533" : "error"} fontWeight={500}>{admin.status}</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Box sx={{ display: 'flex', justifyContent: "center" }}>
                            <>
                              <Tooltip title="Ver detalle">
                                <IconButton style={{ color: '#035533' }} onClick={() => handleViewModalAdminBranch(admin.id_admin)}>
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          </Box >
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <GridHeaderII item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <TittleModule >Lista de cajeros</TittleModule>
                  </GridHeaderII>
                  <Box sx={{ display: 'flex', flexDirection: 'column', margin: 'auto', gridTemplateColumns: '1fr 1fr', width: '100%', }} >
                    {row.original.cashiers.length === 0 &&
                      <Typography variant="body1" color="textSecondary">
                        El establecimiento no tiene cajeros registrados
                      </Typography>
                    }
                    {row.original.cashiers.map((cashier) => (
                      <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", justifyContent: "space-between" }}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} pl={5}>
                          <Typography key={cashier.cashier_id}>{cashier.cashier_id} - {cashier.cashier_name}</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} justifyContent={'center'}>
                          <Typography color={cashier.status === "activo" ? "#035533" : "error"} fontWeight={500}>{cashier.status}</Typography>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <Box sx={{ display: 'flex', justifyContent: "center" }}>
                            <>
                              <Tooltip title="Ver detalle">
                                <IconButton style={{ color: '#035533' }} onClick={() => handleViewModalCashierBranch(cashier.cashier_id)}>
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          </Box >
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Grid>
              </>
            ) : null
          }
        />
      </Grid>
      {selectedEstablishmentId !== null && (
        <ModalViewDetailsBranch isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedEstablishmentId={selectedEstablishmentId} />
      )}

      {selectedAdminId !== null && (
        <ModalViewDetailsAdminsBranch isModalOpenAdmin={isModalOpenAdmin} setIsModalOpenAdmin={setIsModalOpenAdmin} selectedAdminId={selectedAdminId} />
      )}

      {selectedCashierId !== null && (
        <ModalViewDetailsCashiersBranch isModalOpenCashier={isModalOpenCashier} setIsModalOpenCashier={setIsModalOpenCashier} selectedCashierId={selectedCashierId} />
      )}
    </Grid>
  );
};

export default ListBranchs;