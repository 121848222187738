import styled from "styled-components";
import { Box } from "@mui/material";

export const ModalView = styled(Box)`
  position: absolute; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 55vh;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 1% 2% 0% 2%;
  }

  .bodyCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1% 0% 1.5% 2%;
  }

  .bodyCard2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1% 0% 1.5% 2%;
  }

  .blockItem{
    display: flex;

    .start{
      display: flex;
      justify-content: start;
    }
    .end{
      display: flex;
      justify-content: end;
    }
  }

  .containerItem{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 1200px) {
    height: 60vh;
  }

  @media (max-width: 900px) {
    height: 67vh;
  }


  @media (max-width: 600px) {
    height: 85vh;
    width: 90%;
  }
`;

export const DivModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
