import { useEffect } from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import { useAuthContext } from '../../Contexts/AuthContext';
import { LOGINCLIENT } from '../../router/routes/path';

export const Logout1 = () => {
    const { logout, token, role } = useAuthContext();
    const location = useLocation();

    useEffect(() => {
        if (token) {
            logout();
        }
    }, [location.pathname, role, token, logout]);
}

export const Logout2 = () => {
    const { logout, token, role } = useAuthContext();
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        if (token) {
            logout();

        } else if (!token) {
            navigate(LOGINCLIENT)
        }
    }, [location.pathname, role, token, logout]);
}

