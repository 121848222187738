import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { TittleModule } from '../Styled';
import axios from 'axios';

import ReplayIcon from '@mui/icons-material/Replay';
import { ConstructionOutlined, Edit as EditIcon } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridHeaderII } from '../../moduleDashboard/Styled';
import ProfileMenu from '../../../../Components/menu/Profile';
import RegistrationEstablishment from '../RegistrationEstablishment'
import { useAuthContext } from '../../../../../Contexts/AuthContext';
import ModalViewDetailsEstablishment from '../modals/ViewDetailsEstablishment'
import { Box, IconButton, Grid, Switch, Button, Tooltip, Typography } from '@mui/material';
import ModalViewDetailsBranch from '../modals/ViewDetailsBranch';
import RegistrationBranch from '../RegistrationBranch';

const ListEstablishments = () => {
  const { token } = useAuthContext();
  const [setSwitchState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [establishments, setEstablishments] = useState([]);
  const [branchs, setBranchs] = useState([]);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [editingEstablishmentId, setEditingEstablishmentId] = useState(null);
  const [editingBranchId, setEditingBranchId] = useState(null);
  const [selectedEstablishmentId, setSelectedEstablishmentId] = useState(null);
  const [showRegistrationEstablishments, setShowRegistrationEstablishments] = useState(false);
  const [showRegistrationBrands, setShowRegistrationBrands] = useState(false);

  const getEstablishments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getEstablishmentsAndBranch`, {
        headers: {
          authorization: token,
        },
      });
      setEstablishments(response.data);
    } catch (error) {
    }
  };

  const handleUpdateState = async (row) => {
    try {
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(`${process.env.REACT_APP_API_URL}/changeEstablishmentStatus/${row.original.establishment_id}`, null, config);
      setEstablishments((prevEstablishments) => {
        return prevEstablishments?.map((establishment) => {
          if (establishment.status === row.original.status) {
            return { ...establishment };
          } else {
            return establishment;
          }
        });
      }, response.data);
      handleReload()
      setSwitchState(response.data.status === 'activo');

    } catch (error) {
    }
  };
  const handleUpdateStateBranch = async (row) => {
    try {
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(`${process.env.REACT_APP_API_URL}/changeEstablishmentStatus/${row}`, null, config);
      setBranchs((prevBranch) => {
        return prevBranch?.map((branch) => {
          if (branch.status === row.original.Branchs.status) {
            return { ...branch };
          } else {
            return branch;
          }
        });
      }, response.data);
      handleReload()
      setSwitchState(response.data.status === 'activo');
    } catch (error) {
    }
  };

  const handleEditEstablishment = (nitEstablecimiento) => {
    setEditingEstablishmentId(nitEstablecimiento);
    setShowRegistrationEstablishments(true);
    setIsModalOpen(false);
  };

  const handleEditBranch = (nitSucursal) => {
    setEditingBranchId(nitSucursal);
    setShowRegistrationBrands(true);
    setIsModalOpen(false);
  };

  const handleViewModal = (nitEstablecimiento) => {
    setSelectedEstablishmentId(nitEstablecimiento);
    setSelectedEstablishment(nitEstablecimiento)
    setShowRegistrationEstablishments(false);
    setIsModalOpen(true);
  };

  const handleViewModalBranch = (nitEstablecimiento) => {
    setSelectedEstablishmentId(nitEstablecimiento);
    setSelectedBranch(nitEstablecimiento)
    setShowRegistrationBrands(false);
    setIsModalOpen(true);
  };

  const handleReload = () => {
    getEstablishments()
  }



  useEffect(() => {
    getEstablishments();
    handleUpdateState();
    handleUpdateStateBranch();
  }, []);

  useEffect(() => {
    const establishmentToEdit = establishments.find(establishment => establishment.establishment_id === editingEstablishmentId);
    setSelectedEstablishment(establishmentToEdit);
  }, [editingEstablishmentId, establishments]);

  useEffect(() => {
    const branchToEdit = establishments.find(establishment => {
      return establishment.Branchs.some(branch => branch.establishment_id === editingBranchId);
    });
    setSelectedBranch(branchToEdit);
  }, [editingBranchId, establishments]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'NIT',
        header: 'Nit'
      },

      {
        accessorKey: 'business_name',
        header: 'Nombre de razón social',
      },
      {
        accessorKey: 'address',
        header: 'Dirección',
      },
      {
        accessorKey: 'status',
        header: 'Estado del establecimiento',
        Cell: ({ cell, row }) => (
          <Box
            Establishments="span"
            sx={(theme) => ({
              color:
                row.original.status === 'activo'
                  ? theme.palette.success.dark
                  : row.original.status === 'pendiente'
                    ? 'rgb(255, 174, 0)'
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              fontWeight: 'bold',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        )
      },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        size: 1,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            <Tooltip title="Editar">
              <Button style={{ color: '#035533' }}>
                <EditIcon onClick={() => handleEditEstablishment(row.original.establishment_id)} />
              </Button>
            </Tooltip>
            <Tooltip title="Ver detalle">
              <IconButton style={{ color: '#035533' }} onClick={() => handleViewModal(row.original.establishment_id)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Switch checked={row.original.status === "activo"} color={row.original.status === "activo" ? "success" : "error"} onClick={() => { handleUpdateState(row) }} />
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
      {showRegistrationEstablishments ? (
        <RegistrationEstablishment establishmentId={editingEstablishmentId} selectedEstablishment={selectedEstablishment} />
      ) :  showRegistrationBrands ? (
        <RegistrationBranch branchId={editingBranchId} selectedBranch={selectedBranch} />
      ) : (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={11}>
            <GridHeaderII container xl={12} mt={-20} pb={2}>
              <TittleModule >Lista de establecimientos</TittleModule>
              <ProfileMenu />
            </GridHeaderII>

            <MaterialReactTable columns={columns} data={establishments}
              renderTopToolbarCustomActions={() => (
                <>
                  <Tooltip arrow placement="bottom" title="Reload">
                    <IconButton onClick={() => handleReload()}>
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              enableExpandAll={false}
              muiDetailPanelProps={() => ({
                sx: (theme) => ({
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(255,210,244,0.1)'
                      : 'rgba(0,0,0,0.1)',
                }),
              })}
              initialState={{
                density: 'compact'
              }}
              muiExpandButtonProps={({ row, table }) => ({
                onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
                sx: {
                  transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                  transition: 'transform 0.2s',
                },
              })}
              positionActionsColumn="last"
              renderDetailPanel={({ row }) =>
                row.original.business_name ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <GridHeaderII item xs={12} sm={12} md={12} lg={12} xl={12} >
                      <TittleModule >Lista de sucursales</TittleModule>
                    </GridHeaderII>
                    <Box sx={{ display: 'flex', flexDirection: 'column', margin: 'auto', gridTemplateColumns: '1fr 1fr', width: '100%', }} >
                      {row.original.Branchs.length === 0 &&
                        <Typography variant="body1" color="textSecondary">
                          El establecimiento no tiene sucursales registradas
                        </Typography>
                      }
                      {row?.original?.Branchs?.map((branch) => (
                        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", justifyContent: "space-between" }}>
                          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} pl={5}>
                            <Typography key={branch.establishment_id}>{branch.establishment_branch}</Typography>
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} lg={2} xl={2} justifyContent={'center'}>
                            <Typography color={branch.status === "activo" ? "#035533" : "error"} fontWeight={500}>{branch.status}</Typography>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Box sx={{ display: 'flex', justifyContent: "center" }}>
                              <>
                                <Button style={{ color: '#035533' }}>
                                  <Tooltip title="Editar">
                                    <EditIcon onClick={() => handleEditBranch(branch.establishment_id)} />
                                  </Tooltip>
                                </Button>
                                <Tooltip title="Ver detalle">
                                  <IconButton style={{ color: '#035533' }} onClick={() => handleViewModalBranch(branch.establishment_id)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={branch.status === 'activo' ? 'Desactivar' : 'Activar'} >
                                  <Switch checked={branch.status === "activo"} color={branch.status === "activo" ? "success" : "error"} onClick={() => handleUpdateStateBranch(branch.establishment_id)} />
                                </Tooltip>
                              </>
                            </Box >
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  </Grid>
                ) : null
              }
            />
          </Grid>
        </>
      )}
      {selectedEstablishmentId !== null && (
        <ModalViewDetailsEstablishment isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedEstablishmentId={selectedEstablishmentId} />
      )}

      {selectedEstablishmentId !== null && (
        <ModalViewDetailsBranch isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedEstablishmentId={selectedEstablishmentId} />
      )}
    </Grid>
  );
};

export default ListEstablishments;