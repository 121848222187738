import React, { useEffect, useState } from 'react';
import { CssBaseline, Grid, Box, Typography, IconButton, InputAdornment } from "@mui/material";
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { createTheme, ThemeProvider } from "@mui/material/styles";

import axios from 'axios';
import Swal from 'sweetalert2'
import validator from 'validator';

import { GridHeaderII } from '../moduleDashboard/Styled';
import { GridBtn } from '../quotaAllocation/tables/Style';
import ProfileMenu from '../../../Components/menu/Profile.jsx';
import { useAuthContext } from '../../../../Contexts/AuthContext';
import ComboBox360 from '../../../../components/elements/ComboBox/comboBox360';
import { LineDivider } from '../../../../components/elements/lines/Styled.jsx';
import { StyledInputText } from '../../../../components/elements/Inputs/Styled';
import ButtonActiosn360 from '../../../../components/elements/Buttons/ButtonActions360';
import { CardBody2, CardBody3, GridBody, TittleModule } from '../registrationEstablishment/Styled';
import ComboBoxWithAlias from '../../../../components/elements/ComboBox/comboBoxRoles.jsx';


export default function UsersRegister({ superAdminsId, adminsId, cashierId, selectedAdmins, selectedAdminsBranch, selectedCashier, headerVisibility }) {
  const { token } = useAuthContext();
  const [errors, setErrors] = useState({});
  const [/* open */, setOpen] = useState(false);
  const [ubicacion, setUbicacion] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [/* selectedcashier */, setSelectedcashier] = useState("");
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [selectedTipoDocumento, setSelectedTipoDocumento] = useState("");
  const [selectedEstablishment, setSelectedEstablishment] = useState("");
  const [selectedCiudad, setSelectedCiudad] = useState("");
  const [inputEnabled, /* setInputEnabled */] = useState(false);
  const [inputPassEnabled, setInputPassEnabled] = useState(false);
  const [direccion, setDireccion] = useState({
    tipoVia: "",
    primerNumeroVia: "",
    segundoNumeroVia: "",
    tercerNumeroVia: "",
  });
  const [cashier, setCashier] = useState({
    cashier_id: "",
    cashier_name: "",
    personal_email: "",
    cashier_phone: "",
    general_details: "",
    cashier_email: "",
    cashier_password: "",
    confirm_cashier_password: "",
  });
  const handleEnableFieldsPass = () => {
    setInputPassEnabled(true);
  };

  const validateForm = () => {
    const errors = {};
    if (!cashier.personal_email || !validator.isEmail(cashier.personal_email)) {
      errors.personal_email = 'El Correo no es válido.';
    }
    if (!cashier.cashier_email || !validator.isEmail(cashier.cashier_email)) {
      errors.cashier_email = 'El Correo no es válido.';
    }
    if (!selectedEstablishment) {
      errors.selectedEstablishment = 'El campo Establecimiento es requerido.';
    }
    if (!selectedDepartamento) {
      errors.selectedDepartamento = 'El campo Departamento es requerido.';
    }
    if (!selectedCiudad) {
      errors.selectedCiudad = 'El campo Ciudad es requerido.';
    }
    if (!selectedTipoDocumento) {
      errors.selectedTipoDocumento = 'El campo Tipo de documento es requerido.';
    }
    if (!selectedRole) {
      errors.selectedRole = 'El campo Tipo de documento es requerido.';
    }
    if (!direccion.tipoVia || !direccion.tipoVia.trim()) {
      errors.direccionTipoVia = 'Campo requerido.';
    }
    if (!direccion.primerNumeroVia || !direccion.primerNumeroVia.trim()) {
      errors.direccionPrimerNumeroVia = 'Campo requerido.';
    }
    if (!direccion.segundoNumeroVia || !direccion.segundoNumeroVia.trim()) {
      errors.direccionSegundoNumeroVia = 'Campo requerido.';
    }
    if (!direccion.tercerNumeroVia || !direccion.tercerNumeroVia.trim()) {
      errors.direccionTercerNumeroVia = 'Campo requerido.';
    }

    const stringFields = ['cashier_name', 'general_details', 'cashier_password', 'confirm_cashier_password'];
    stringFields.forEach((field) => {
      if (!cashier[field] || !cashier[field].trim()) {
        errors[field] = `Campo requerido.`;
      }
    });

    const numericFields = ['cashier_id', 'cashier_phone'];
    numericFields.forEach((field) => {
      if (!cashier[field] || !/^\d{1,10}$/.test(cashier[field])) {
        errors[field] = `Lim. 10 dígitos.`;
      }
    });

    if (cashier.cashier_password !== cashier.confirm_cashier_password) {
      errors.confirm_cashier_password = 'Las contraseñas no coinciden.';
    }

    return errors;
  };

  const validateFormUpdate = () => {
    const errors = {};
    if (!cashier.personal_email || !validator.isEmail(cashier.personal_email)) {
      errors.personal_email = 'El Correo no es válido.';
    }
    if (!cashier.cashier_email || !validator.isEmail(cashier.cashier_email)) {
      errors.cashier_email = 'El Correo no es válido.';
    }
    if (!selectedDepartamento) {
      errors.selectedDepartamento = 'El campo Departamento es requerido.';
    }
    if (!selectedCiudad) {
      errors.selectedCiudad = 'El campo Ciudad es requerido.';
    }
    if (!direccion.tipoVia || !direccion.tipoVia.trim()) {
      errors.direccionTipoVia = 'Campo requerido.';
    }
    if (!direccion.primerNumeroVia || !direccion.primerNumeroVia.trim()) {
      errors.direccionPrimerNumeroVia = 'Campo requerido.';
    }
    if (!direccion.segundoNumeroVia || !direccion.segundoNumeroVia.trim()) {
      errors.direccionSegundoNumeroVia = 'Campo requerido.';
    }
    if (!direccion.tercerNumeroVia || !direccion.tercerNumeroVia.trim()) {
      errors.direccionTercerNumeroVia = 'Campo requerido.';
    }

    const stringFields = ['cashier_name', 'general_details'];
    stringFields.forEach((field) => {
      if (!cashier[field] || !cashier[field].trim()) {
        errors[field] = `Campo requerido.`;
      }
    });

    const numericFields = ['cashier_phone'];
    numericFields.forEach((field) => {
      if (!cashier[field] || !/^\d{1,10}$/.test(cashier[field])) {
        errors[field] = `Lim. 10 dígitos.`;
      }
    });

    if (cashier.cashier_password !== cashier.confirm_cashier_password) {
      errors.confirm_cashier_password = 'Las contraseñas no coinciden.';
    }
    return errors;
  };

  const clearForm = () => {
    setErrors({});
    setUbicacion("");
    setSelectedCiudad("");
    setSelectedDepartamento("");
    setSelectedRole("");
    setSelectedTipoDocumento("");
    setSelectedcashier("");
    setShowPassword(false);
    setShowConfirmPassword(false);

    setDireccion({
      tipoVia: "",
      primerNumeroVia: "",
      segundoNumeroVia: "",
      tercerNumeroVia: "",
    });

    setCashier({
      cashier_id: "",
      cashier_name: "",
      personal_email: "",
      cashier_phone: "",
      general_details: "",
      cashier_email: "",
      cashier_password: "",
      confirm_cashier_password: "",
    });
  };

  const updateUbicacion = () => {
    const { tipoVia, primerNumeroVia, segundoNumeroVia, tercerNumeroVia } = direccion;
    const ciudad = selectedCiudad || "";
    const departamento = selectedDepartamento ? selectedDepartamento.label : "";
    const domicilio = `${tipoVia} ${primerNumeroVia} ${segundoNumeroVia} ${tercerNumeroVia}`;
    const region = `${domicilio} - ${ciudad} / ${departamento}`;
    setUbicacion(region);
  };

  const handleCreateUser = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/createCashierAndAdminEstablishment`, {
          user_id_type: selectedTipoDocumento?.label || "",
          user_id: cashier.cashier_id,
          user_name: cashier.cashier_name,
          user_email: cashier.cashier_email,
          user_phone: cashier.cashier_phone,
          user_address: ubicacion,
          establishment_id: selectedEstablishment,
          general_details: cashier.general_details,
          personal_email: cashier.personal_email,
          user_password: cashier.cashier_password,
          confirm_user_password: cashier.confirm_cashier_password,
          role: selectedRole || "",
        }, {
          headers: {
            authorization: token,
          },
        });
        Swal.fire(
          'Bien hecho!',
          'Establecimiento creado exitosamente!',
          'success'
        )
        setOpen(true);
        clearForm()
      } catch (error) {
        setOpen(true);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'El usuario ya existe, número de documento registrado!',
          confirmButtonColor: '#035533',
        })
      }
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Verifique que los campos sean diligenciados!',
        confirmButtonColor: '#035533',
      })
      setErrors(validationErrors);
    }
  }

  const handleUpdateCashier = async () => {
    const validationErrors = validateFormUpdate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/updateCashier/${cashierId}`, {
          cashier_name: cashier.cashier_name,
          cashier_email: cashier.cashier_email,
          cashier_phone: cashier.cashier_phone,
          cashier_address: ubicacion,
          general_details: cashier.general_details,
          personal_email: cashier.personal_email,
          cashier_password: cashier.cashier_password,
          confirm_cashier_password: cashier.confirm_cashier_password,
        }, {
          headers: {
            authorization: token,
          },
        });
        Swal.fire(
          'Actualizacion exitosa!',
          'Establecimiento actualizado exitosamente!',
          'success'
        )
        setOpen(true);
        setCashier(response.data)
        clearForm()
      } catch (error) {
        setOpen(true);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Verifique que los campos sean diligenciados!',
        confirmButtonColor: '#035533',
      })
      setErrors(validationErrors);
    }
  };

  const handleUpdateAdmin = async () => {
    const validationErrors = validateFormUpdate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/updateSuperAdminEst/${superAdminsId}`, {
          superAdminName: cashier.cashier_name,
          personalEmail: cashier.cashier_email,
          superAdminPhone: cashier.cashier_phone,
          superAdminAddress: ubicacion,
          general_details: cashier.general_details,
          superAdminEmail: cashier.personal_email,
          superAdminPassword: cashier.cashier_password,
          confirmSuperAdminPassword: cashier.confirm_cashier_password,
        }, {
          headers: {
            authorization: token,
          },
        });
        Swal.fire(
          'Actualizacion exitosa!',
          'Establecimiento actualizado exitosamente!',
          'success'
        )
        clearForm()
        setOpen(true);
        setCashier(response.data)
      } catch (error) {
        setOpen(true);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Hubo un error al intentar actualizar el establecimiento. Verifique los campos son requeridos.',
          confirmButtonColor: '#035533',
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Verifique que los campos sean diligenciados!'
      })
      setErrors(validationErrors);
    }
  };

  const handleUpdateAdminBranch = async () => {
    const validationErrors = validateFormUpdate();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.put(`${process.env.REACT_APP_API_URL}/updateEstablishmentAdmin/${adminsId}`, {
          adminEst_name: cashier.cashier_name,
          adminEst_email: cashier.cashier_email,
          adminEst_phone: cashier.cashier_phone,
          adminEst_address: ubicacion,
          general_details: cashier.general_details,
          personal_email: cashier.personal_email,
          adminEst_password: cashier.cashier_password,
          confirm_adminEst_password: cashier.confirm_cashier_password,
        }, {
          headers: {
            authorization: token,
          },
        });
        Swal.fire(
          'Actualizacion exitosa!',
          'Establecimiento actualizado exitosamente!',
          'success'
        )
        clearForm()
        setOpen(true);
        setCashier(response.data)
      } catch (error) {
        setOpen(true);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Hubo un error al intentar actualizar el establecimiento. Verifique los campos son requeridos.',
          confirmButtonColor: '#035533',
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Verifique que los campos sean diligenciados!'
      })
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    if (selectedCashier) {
      setCashier(selectedCashier);
      setDireccion(selectedCashier)
    } else if (selectedAdminsBranch) {
      setCashier(selectedAdminsBranch)
      setDireccion(selectedAdminsBranch)
    } else if (selectedAdmins) {
      setCashier(selectedAdmins)
      setDireccion(selectedAdmins)
    }
  }, [selectedCashier, selectedAdminsBranch, selectedAdmins]);

  return (
    <ThemeProvider theme={createTheme}>
      <CssBaseline />
      <GridBody >
        {headerVisibility !== 'headerVisibility' && !superAdminsId && (
          <GridHeaderII container item xl={12} pb={adminsId ? 16 : (cashierId ? 10 : 3)} mt={adminsId ? "-10rem" : (cashierId ? "-9rem" : "-3.5rem")}>
            {adminsId ? <TittleModule> </TittleModule> : (cashierId ? <TittleModule>Lista de cajeros</TittleModule> : <TittleModule>Registro de usuarios </TittleModule>)}
            {adminsId ? "" : (cashierId) ? <ProfileMenu /> : <ProfileMenu />}
          </GridHeaderII>
        )}
        <CardBody2>
          <Grid item className='GridHeader' mt={1}>
            <Typography className='TypographyHeader'>Información general</Typography>
            <LineDivider color="#035533" width="98%" />
          </Grid>

          <Box component="form" noValidate onSubmit={(e) => { e.preventDefault(); }}>
            <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} spacing={2} ml={1} mt={0.0}>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.9} xl={1.6} >
                <ComboBox360 label="Tipo de documento" selectedValue={selectedTipoDocumento} setSelectedValue={setSelectedTipoDocumento} value={cashier.cashier_name}
                  error={errors.selectedTipoDocumento} helperText={errors.selectedTipoDocumento && errors.selectedTipoDocumento} disabled={selectedAdmins || selectedAdminsBranch || selectedCashier ? !inputEnabled : inputEnabled} />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.9} xl={2} >
                <StyledInputText size='small' name="identific ation" type='number' id="identification" label="Número de identificación" variant="outlined" autoFocus
                  value={cashier.cashier_id} onChange={(e) => setCashier({ ...cashier, cashier_id: e.target.value })} error={errors.cashier_id}
                  helperText={errors.cashier_id && errors.cashier_id} disabled={selectedAdmins || selectedAdminsBranch || selectedCashier ? !inputEnabled : inputEnabled} />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.9} xl={2.9} >
                <StyledInputText size='small' name="name" required id="name" label="Nombre completo" variant="outlined" autoFocus value={cashier.cashier_name}
                  onChange={(e) => setCashier({ ...cashier, cashier_name: e.target.value })} error={errors.cashier_name} helperText={errors.cashier_name && errors.cashier_name} />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.9} xl={2} >
                <StyledInputText size='small' name="email" required id="email" label="Correo electrónico" variant="outlined" autoFocus value={cashier.personal_email}
                  onChange={(e) => setCashier({ ...cashier, personal_email: e.target.value })} error={errors.personal_email}
                  helperText={errors.personal_email && errors.personal_email} />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.9} xl={1.6} >
                <StyledInputText size='small' name="phone" required type='number' id="phone" label="Número de teléfono" variant="outlined" autoFocus value={cashier.cashier_phone}
                  onChange={(e) => setCashier({ ...cashier, cashier_phone: e.target.value })} error={errors.cashier_phone}
                  helperText={errors.cashier_phone && errors.cashier_phone} />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.9} xl={1.5} >
                <ComboBox360 label="Establecimiento o sucursal" selectedValue={selectedEstablishment} setSelectedValue={setSelectedEstablishment} error={errors.selectedcashier}
                  helperText={errors.selectedcashier && errors.selectedcashier} disabled={selectedAdmins || selectedAdminsBranch || selectedCashier ? !inputEnabled : inputEnabled} />
              </Grid>
            </Grid>
          </Box>
        </CardBody2>

        <CardBody2>
          <Grid item className='GridHeader' mt={1}>
            <Typography className='TypographyHeader'>Dirección del usuario</Typography>
            <LineDivider color="#035533" width="98%" />
          </Grid>

          <Box component="form" noValidate onSubmit={(e) => {
            e.preventDefault();
          }}>
            <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} ml={1} spacing={2} mt={0.0}>
              <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.6} >
                <ComboBox360 label="Departamento" selectedValue={selectedDepartamento} setSelectedValue={setSelectedDepartamento} onBlur={updateUbicacion}
                  error={errors.selectedDepartamento} helperText={errors.selectedDepartamento && errors.selectedDepartamento} />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.8} xl={1.5} >
                <ComboBox360 label="Ciudad" departamento={selectedDepartamento} selectedValue={selectedCiudad} setSelectedValue={setSelectedCiudad} onBlur={updateUbicacion}
                  error={errors.selectedCiudad} helperText={errors.selectedCiudad && errors.selectedCiudad} />
              </Grid>

              <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.5}>
                <StyledInputText size='small' name="tipoVia" required id="tipoVia" label="Tipo de vía" variant="outlined" autoFocus value={direccion.tipoVia}
                  onChange={(e) => setDireccion({ ...direccion, tipoVia: e.target.value })} onBlur={updateUbicacion} error={errors.direccionTipoVia}
                  helperText={errors.direccionTipoVia && errors.direccionTipoVia} />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.8} xl={1.5}>
                <StyledInputText size='small' name="primerNumeroVia" required id="primerNumeroVia" label="Primer número de vía" variant="outlined" autoFocus
                  value={direccion.primerNumeroVia} onChange={(e) => setDireccion({ ...direccion, primerNumeroVia: e.target.value })} onBlur={updateUbicacion}
                  error={errors.direccionPrimerNumeroVia} helperText={errors.direccionPrimerNumeroVia && errors.direccionPrimerNumeroVia} />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.5}>
                <StyledInputText size='small' name="segundoNumeroVia" required id="segundoNumeroVia" label="Segundo número de vía" variant="outlined" autoFocus
                  value={direccion.segundoNumeroVia} onChange={(e) => setDireccion({ ...direccion, segundoNumeroVia: e.target.value })} onBlur={updateUbicacion}
                  error={errors.direccionSegundoNumeroVia} helperText={errors.direccionSegundoNumeroVia && errors.direccionSegundoNumeroVia} />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.9} lg={2.9} xl={1.5}>
                <StyledInputText size='small' name="tercerNumeroVia" required id="tercerNumeroVia" label="Tercer número de vía" variant="outlined" autoFocus
                  value={direccion.tercerNumeroVia} onChange={(e) => setDireccion({ ...direccion, tercerNumeroVia: e.target.value })} onBlur={updateUbicacion}
                  error={errors.direccionTercerNumeroVia} helperText={errors.direccionTercerNumeroVia && errors.direccionTercerNumeroVia} />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.9} xl={2.5} >
                <StyledInputText size='small' name="quota" required id="quota" label="Información adicional" variant="outlined" autoFocus value={cashier.general_details}
                  onChange={(e) => setCashier({ ...cashier, general_details: e.target.value })} error={errors.general_details}
                  helperText={errors.general_details && errors.general_details} />
              </Grid>
            </Grid>
          </Box>
        </CardBody2>

        <CardBody3>
          <Grid item className='GridHeader' mt={1}>
            <Typography className='TypographyHeader'>Credencial de acceso</Typography>
            <LineDivider color="#035533" width="98%" />
          </Grid>

          <Box component="form" noValidate onSubmit={(e) => {
            e.preventDefault();
          }}>
            <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={12} xl={12} ml={0.5} spacing={2} mt={0.0}>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.9} xl={2.9} >
                <ComboBoxWithAlias label="Rol" selectedValue={selectedRole} setSelectedValue={setSelectedRole}
                  error={errors.selectedRole} helperText={errors.selectedRole && errors.selectedRole} disabled={selectedAdmins || selectedAdminsBranch || selectedCashier ? !inputEnabled : inputEnabled} />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.9} xl={2.9}>
                <StyledInputText size='small' name="userName" required id="userName" label="Usuario" placeholder="ejemplo@ejemplo.com" variant="outlined" value={cashier.cashier_email}
                  onChange={(e) => setCashier({ ...cashier, cashier_email: e.target.value })} error={errors.cashier_email}
                  helperText={errors.cashier_email && errors.cashier_email} />
              </Grid>

              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.9} xl={2.9}>
                <StyledInputText size='small' name="pass" required id="pass" label="Contraseña" variant="outlined" type={showPassword ? 'text' : 'password'}
                  value={cashier.cashier_password} onChange={(e) => setCashier({ ...cashier, cashier_password: e.target.value })}
                  error={errors.cashier_password} helperText={errors.cashier_password && errors.cashier_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" className={showPassword ? 'show-password-icon' : 'hide-password-icon'}>
                          {showPassword ? (
                            <VisibilityRoundedIcon style={{ color: "#035533" }} />
                          ) : (
                            <VisibilityOffRoundedIcon style={{ color: "#035533" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={selectedAdmins || selectedAdminsBranch || selectedCashier ? !inputPassEnabled : inputPassEnabled}
                />
              </Grid>
              <Grid item xs={10.5} sm={5.5} md={2.8} lg={2.9} xl={2.9}>
                <StyledInputText size='small' name="pass2" required id="pass2" label="Confirmar contraseña" variant="outlined" type={showConfirmPassword ? 'text' : 'password'} value={cashier.confirm_cashier_password} onChange={(e) => setCashier({ ...cashier, confirm_cashier_password: e.target.value })}
                  error={errors.confirm_cashier_password} helperText={errors.confirm_cashier_password && errors.confirm_cashier_password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end"
                          className={showConfirmPassword ? 'show-password-icon' : 'hide-password-icon'} >
                          {showConfirmPassword ? (
                            <VisibilityRoundedIcon style={{ color: "#035533" }} />
                          ) : (
                            <VisibilityOffRoundedIcon style={{ color: "#035533" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={selectedAdmins || selectedAdminsBranch || selectedCashier ? !inputPassEnabled : inputPassEnabled}
                />
              </Grid>

              {selectedAdmins || selectedAdminsBranch || selectedCashier && (
                <Grid item xs={9.8} sm={9.2} md={11.2} lg={11.6} xl={11.6} textAlign={"end"}>
                  <ButtonActiosn360 label="Quiero cambiar la contraseña" className='buttonAction360' backgroundColor={'#035533'} onClick={handleEnableFieldsPass} />
                </Grid>
              )}
            </Grid>
          </Box>
        </CardBody3>

        <GridBtn mt={1}>
          <Grid container item xs={12} sm={5.8} md={3} lg={1.5} xl={1.5}>
            <ButtonActiosn360 label="CREAR" className='buttonAction360' backgroundColor={selectedAdmins || selectedAdminsBranch || selectedCashier ? "grey" : "#035533"} disabled={selectedAdminsBranch || selectedCashier} onClick={handleCreateUser} />
          </Grid>

          {selectedAdmins &&
            <Grid container item xs={12} sm={5.8} md={3} lg={1.5} xl={1.5}>
              <ButtonActiosn360 label="GUARDAR" className='buttonAction360' backgroundColor={'#035533'} onClick={handleUpdateAdmin} />
            </Grid>
          }
          {selectedCashier &&
            <Grid container item xs={12} sm={5.8} md={3} lg={1.5} xl={1.5}>
              <ButtonActiosn360 label="GUARDAR" className='buttonAction360' backgroundColor={'#035533'} onClick={handleUpdateCashier} />
            </Grid>
          }
          {selectedAdminsBranch &&
            <Grid container item xs={12} sm={5.8} md={3} lg={1.5} xl={1.5}>
              <ButtonActiosn360 label="Actualizar" className='buttonAction360' backgroundColor={'#035533'} onClick={handleUpdateAdminBranch} />
            </Grid>
          }
        </GridBtn>
      </GridBody>
    </ThemeProvider>
  );
}