import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

import { DivModal, ModalView2 } from './Styled';
import { useAuthContext } from '../../../../Contexts/AuthContext';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { LineDivider } from '../../../../components/elements/lines/Styled';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import { StyledInputText } from '../../../../components/elements/Inputs/Styled';
import ButtonActiosn360 from '../../../../components/elements/Buttons/ButtonActions360';
import { Typography, Modal, Grid, Box, MenuItem, IconButton, InputAdornment } from '@mui/material';

export default function ModalInfoAdmin({ selectedAdminId, isModalOpenAdmin, setIsModalOpenAdmin }) {
  const { token } = useAuthContext();
  const [errors, setErrors] = useState({});
  const [/* isEditable */, setIsEditable] = useState(false);
  const [isAllEditable, setIsAllEditable] = useState(false);
  const [isNameEmailEditable, setIsNameEmailEditable] = useState(false);
  const [isChangeCredentialsVisible, setIsChangeCredentialsVisible] = useState(true);
  const [isPasswordEditable, setIsPasswordEditable] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [adminData, setAdminData] = useState({
    name: "",
    email: "",
    pass: "",
    confirmPass: "",
  });

  const handleEditNameEmailClick = () => {
    setIsNameEmailEditable(true);
    setIsPasswordEditable(false);
    setIsChangeCredentialsVisible(false);
  };

  const handleEditAllClick = () => {
    setIsNameEmailEditable(true);
    setIsPasswordEditable(true);
    setIsChangeCredentialsVisible(false);
    setIsAllEditable(true);
  };

  const handleSaveButtonClick = () => {
    if (validateForm()) {
      updateAdminData();
    }
    setIsEditable(false);
    setIsNameEmailEditable(false);
    setIsPasswordEditable(false);
    setIsChangeCredentialsVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalOpenAdmin(false);
    setIsEditable(false);
    setIsNameEmailEditable(false);
    setIsPasswordEditable(false);
    setIsChangeCredentialsVisible(false);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateForm = () => {
    const errors = {};
    const stringFields = ['name', 'email', 'pass', 'confirmPass'];
    stringFields.forEach((field) => {
      if (!adminData[field] || !adminData[field].trim()) {
        errors[field] = 'El campo es requerido.';
      }
    });
    if (isNameEmailEditable && !/^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/.test(adminData.name)) {
      errors.name = 'El campo nombre solo puede contener letras y espacios.';
    }
    if (isNameEmailEditable && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(adminData.email)) {
      errors.email = 'Por favor, ingrese un email válido.';
    }
    if (isPasswordEditable) {
      if (adminData.pass.length < 6) {
        errors.pass = 'El campo es requerido.';
      }
      if (adminData.pass !== adminData.confirmPass) {
        errors.confirmPass = 'Las contraseñas no coinciden.';
      }
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const fetchAdminData = async () => {
    try {
      const idAdmin = selectedAdminId;
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getAdmin/${idAdmin}`, {
        headers: {
          authorization: token,
        },
      });
      const infoAdministrator = response.data[0];
      setAdminData({
        name: infoAdministrator.adminName,
        email: infoAdministrator.adminEmail,
        pass: infoAdministrator.adminPassword,
        confirmPass: infoAdministrator.confirmPassword,
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'Hubo un error al traer la información. No hay datos disponibles.',
        confirmButtonColor: '#035533',
      });
    }
  };

  const updateAdminData = async () => {
    try {
      const idAdmin = selectedAdminId;
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/UpdateAdmin/${idAdmin}`,
        {
          adminName: adminData.name,
          adminEmail: adminData.email,
          adminPassword: adminData.pass,
          confirmPassword: adminData.confirmPass,
        },
        {
          headers: {
            authorization: token,
          },
        }
      );

      if (response.status === 200) {
        const infoAdministrator = response.data;
        setAdminData({
          name: infoAdministrator.adminName,
          email: infoAdministrator.adminEmail,
          pass: infoAdministrator.adminPassword,
          confirmPass: infoAdministrator.confirmPassword,
        });
        handleCloseModal();
        Swal.fire(
          'Bien hecho!',
          'Información del administrador actualizado exitosamente!',
          'success'
        );
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'Hubo un error al actualizar la información. No hay datos disponibles.',
        confirmButtonColor: '#035533',
      });
    }
  };

  useEffect(() => {
    if (selectedAdminId && isModalOpenAdmin) {
      fetchAdminData();
    }
  }, [selectedAdminId, isModalOpenAdmin]);

  useEffect(() => {
    setIsEditable(false);
    setIsNameEmailEditable(false);
    setIsPasswordEditable(false);
    setIsChangeCredentialsVisible(true);
    setIsAllEditable(false);
    setShowPassword(false);
    setShowConfirmPassword(false);
  }, [isModalOpenAdmin]);

  return (
    <Modal open={isModalOpenAdmin} onClose={handleCloseModal}>
      <ModalView2 className="ModalStyle">
        {adminData && (
          <DivModal>
            <Grid item className="GridHeader">
              <Typography className="TypographyHeader">Información del administrador de la compañía</Typography>
              <LineDivider color="#035533" width="98%" />
            </Grid>

            <Box component="form" noValidate onSubmit={(e) => e.preventDefault()}>
              <Grid container item className="bodyCard" xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5}>
                <Grid item xs={12} sm={12} md={2.8} lg={2.8} xl={2.8} mt={2}>
                  <StyledInputText type="text" name="name" required id="name" label="Nombre" value={adminData.name} 
                    onChange={(e) => setAdminData({ ...adminData, name: e.target.value })}  error={errors.name}  
                    helperText={errors.name && errors.name} disabled={!isAllEditable && !isNameEmailEditable} />
                </Grid>
                <Grid item xs={12} sm={12} md={2.8} lg={2.8} xl={2.8} mt={2}>
                  <StyledInputText type="email" name="email" required id="email" label="Email" value={adminData.email} 
                    onChange={(e) => setAdminData({ ...adminData, email: e.target.value })}  error={errors.email ? true : false} 
                    helperText={errors.email ? errors.email : ''} disabled={!isAllEditable && !isNameEmailEditable} />
                </Grid>
                <Grid item xs={12} sm={12} md={2.8} lg={2.8} xl={2.8} mt={2}>
                <StyledInputText type={showPassword ? 'text' : 'password'} name="pass" required id="pass" label="Contraseña" value={adminData.pass} 
                    onChange={(e) => setAdminData({ ...adminData, pass: e.target.value })} error={errors.pass} helperText={errors.pass && errors.pass} 
                    disabled={!isAllEditable && !isPasswordEditable}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" className={showPassword ? 'show-password-icon' : 'hide-password-icon'} >
                            {showPassword ? (
                              <VisibilityRoundedIcon style={{ color: "#035533" }} />
                            ) : (
                              <VisibilityOffRoundedIcon style={{ color: "#035533" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2.8} lg={2.8} xl={2.8} mt={2}>
                <StyledInputText type={showConfirmPassword ? 'text' : 'password'} name="pass2" required id="pass2" label="Confirmar Contraseña" 
                    value={adminData.confirmPass} onChange={(e) => setAdminData({ ...adminData, confirmPass: e.target.value })}
                    error={errors.confirmPass && errors.confirmPass} helperText={errors.confirmPass && errors.confirmPass} disabled={!isAllEditable && !isPasswordEditable}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleConfirmPasswordVisibility} edge="end" className={showConfirmPassword ? 'show-password-icon' : 'hide-password-icon'} >
                            {showConfirmPassword ? <VisibilityRoundedIcon style={{ color: "#035533" }} /> : <VisibilityOffRoundedIcon style={{ color: "#035533" }} />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            <Grid container item className="bodyCard" xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} mt={4}>
              {isChangeCredentialsVisible && (
                <>
                  <Grid container item xs={0} sm={0} md={3} lg={2} xl={2} mb={2}></Grid>
                  <Grid container item xs={12} sm={12} md={2.8} lg={2.8} xl={2.8} mb={2.8}>
                    <ButtonActiosn360 label="Actualizar" onClick={handleEditNameEmailClick} backgroundColor={'#035533'} />
                  </Grid>
                </>
              )}
              {!isChangeCredentialsVisible && (
                <>
                  <MenuItem onClick={handleEditAllClick} sx={{ textDecoration: 'none', color: '#035533' }}>
                    <Typography textAlign="center" ml={1}>
                      Cambiar contraseña
                    </Typography>
                  </MenuItem>
                  <Grid container item xs={3} sm={2} md={1.5} lg={1.5} xl={2} mb={2}>
                    <ButtonActiosn360 label="Guardar" onClick={handleSaveButtonClick} backgroundColor={'#035533'} />
                  </Grid>
                </>
              )}
            </Grid>
          </DivModal>
        )}
      </ModalView2>
    </Modal>
  );
}
