import styled from "styled-components";

export const ContHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 0rem 0rem 1.5rem 1.5rem;
  background-color: #035533;
  @media (max-width: 798px) {
    width: 100%;
  }`

export const ContCredimg = styled.img`
width: 20rem;
padding: 1rem 0;
padding-right: 1rem;
@media (max-width: 798px) {
  width: 60%;
}
`;