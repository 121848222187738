import { css } from 'styled-components';

export const Iphone12ProMediaQuery = css`
  @media (max-width: 390px) and (max-height: 844px) {
    margin-left: -20px !important;
  }
`;

export const Iphone14ProMediaQuery = css`
  @media (max-width: 393px) and (max-height: 852px) {
    margin-left: -20px !important;
  }
`;

export const Iphone678Plus = css`
  @media (max-width: 414px) and (max-height: 736px) {
    margin-left: -20px !important;
  }
`;