import styled from 'styled-components';

export const LineDivider = styled.hr`
  width: ${props => props.width || '100%'} ;
  border: none;
  border-bottom: 1px solid ${props => props.color || 'grey'};
`;






