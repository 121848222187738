/* Función para convertir un valor a pesos colombianos */

const FormatId = ({id}) => {
  const intId = parseInt(id)
  return (
    intId.toLocaleString("es-CO", {
      minimumFractionDigits: 0,
    })
   );
}
 
export default FormatId;