import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

import { ModalActions } from './Styled';
import { Grid, Typography, Modal } from "@mui/material";
import { useAuthContext } from '../../../../../Contexts/AuthContext';
import { LineDivider } from '../../../../../components/elements/lines/Styled';
import { StyledInputText2 } from '../../../../../components/elements/Inputs/Styled';
import ButtonActiosn360 from '../../../../../components/elements/Buttons/ButtonActions360';


export default function ModalMassivePorcentage({ rowSelection, modalOpenPorcentage, setModalOpenPorcentage }) {
  const { token } = useAuthContext();
  const [errors, setErrors] = useState({});
  const [porcentage, setPorcentage] = useState({
    identificacion: "",
    porcentaje: null,
    nombre_cliente: ""
  });

  const nombresClientes = {}

  const validateForm = () => {
    const errors = {};
    if (!porcentage || porcentage.porcentaje === 0 || !/^\d+$/.test(porcentage.porcentaje)) {
      errors.porcentage = 'El porcentaje de desembolso es un campo requerido.';
    }
    setErrors(errors);
    return errors;
  }

  const handleCloseModal = () => {
    setModalOpenPorcentage(false);
  };

  const handleInputChange = (campo, valor) => {
    const nombreCliente = nombresClientes[valor] || "";
    setPorcentage((prevAllocation) => ({
      ...prevAllocation,
      [campo]: valor,
      customer_name: nombreCliente,
    }));
  };

  const assignMassivePorcentage = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setModalOpenPorcentage(true);
      return;
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/updateMultipleWithdrawal`, 
      [ rowSelection,
        { porcentage: porcentage.porcentaje }
      ], {
        headers: {
          authorization: token,
        },
      });
      setPorcentage(response.data);
      setModalOpenPorcentage(false);
      Swal.fire({
        icon: 'success',
        title: '¡porcentaje de retiro de crédito asignado con éxito!',
        text: '¡Felicidades, asignación de  de crédito exitosa!',
        confirmButtonColor: '#035533',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      });
      window.localStorage.setItem("setResetClients", true);
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (error) {
      handleCloseModal()
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Hubo un error al asignar porcentaje de retiro. El usuario ya tiene un porcentaje de retiro de crédito.',
        confirmButtonColor: '#035533',
      });
    }
  };

  useEffect(() => {
    if (modalOpenPorcentage) {
      setPorcentage({
        identificacion: "",
        porcentaje: 0,
        nombre_cliente: ""
      });
    }
  }, [modalOpenPorcentage]);

  return (
    <Modal open={modalOpenPorcentage} onClose={handleCloseModal}>
      <ModalActions className="ModalStyle">
        <Grid item className='GridHeader' xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className='TypographyHeader'>Asignar porcentaje de desembolso de crédito</Typography>
          <LineDivider color="#035533" width="96%" />
        </Grid>
        {porcentage && (
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} >
              <Grid item xs={11} sm={11} md={11} lg={7} xl={7} m={2}>
                <StyledInputText2 type='number'label="Porcentaje del desembolso" value={porcentage.porcentaje}
                  onChange={(e) => handleInputChange("porcentaje", e.target.value)} error={errors.porcentage ? true : false} helperText={errors.porcentage ? errors.porcentage : ''} />
              </Grid>
              <Grid container item xs={11} sm={11} md={11} lg={3} xl={3} m={2} justifyContent={'center'} >
                <ButtonActiosn360 backgroundColor={'#035533'} label="GUARDAR" onClick={assignMassivePorcentage} />
              </Grid>
            </Grid>
        )}
      </ModalActions>
    </Modal >


  );
}