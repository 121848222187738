import React, { useState, useEffect } from 'react';
import { Modal, Grid } from '@mui/material';
import { StyledInputText } from '../../../components/elements/Inputs/Styled';
import { ModalEdit, ImgCheck } from './Styled';
import ButtonActions360 from '../../../components/elements/Buttons/ButtonActions360';

import { useAuthContext } from '../../../Contexts/AuthContext';
import Swal from 'sweetalert2';
import axios from 'axios';
import { ConfirmationVoucher } from '../Transactions/Styled';
import TypographyH4II from '../../../components/elements/labels/TypographyH4II';
import mensajeExitoso from '../../../Assets/images/fondos svg/MensajeExitoso.svg';

export default function ModalInvoice({ selectedPurchaseId, selectedPurchaseIdInvoice, isModalOpen, setIsModalOpen, handleReload, buy }) {
  const [invoice, setInvoice] = useState("");
  const [errors, setErrors] = useState({});
  const { token } = useAuthContext();

  const validateForm = () => {
    const validationErrors = {};

    if (!invoice) {
      validationErrors.invoice = 'El número de factura es un campo requerido.';
    }

    setErrors(validationErrors);
    return validationErrors;
  };

  const updatePurchaseInvoice = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const idCompra = selectedPurchaseId || selectedPurchaseIdInvoice;
        await axios.put(`${process.env.REACT_APP_API_URL}/updateInvoice/${idCompra}`, { invoice: invoice },
          {
            headers: {
              authorization: token,
            },
          }
        );
        setIsModalOpen(false);
        if (selectedPurchaseIdInvoice) {
          Swal.fire({
            title: '¡Bien hecho!',
            text: '¡Actualización de factura exitosa!',
            icon: 'success',
            showConfirmButton: false,
            confirmButtonColor: '#035533',
          });
             
          window.location.reload()
        } else {
          Swal.fire({
          title: '¡Bien hecho!',
          text: '¡Compra exitosa!',
          icon: 'success',
          showConfirmButton: false,
          confirmButtonColor: '#035533',
        });
        }
        window.location.reload()
      } catch (error) {
        if (error.response.status === 409) {
          setIsModalOpen(false);
          Swal.fire({
            title: "Upsss",
            text: "El número de la factura ya existe",
            icon: "error",
            showCancelButton: true,
            confirmButtonColor: "#035533",
            cancelButtonColor: "#d33",
            confirmButtonText: "Corregir",
            cancelButtonText: "Cancelar"
          }).then((result) => {
            if (result.isConfirmed) {
              setIsModalOpen(true);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              window.location.reload();
            }
          });
        }
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const handleFinishPurchase = () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      updatePurchaseInvoice();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setInvoice("");
  };

  useEffect(() => {
    if (selectedPurchaseId && isModalOpen) {
      setInvoice("");
    }
  }, [selectedPurchaseId, isModalOpen]);

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <ModalEdit className="ModalStyle">
        <Grid item className="GridHeader" xs={12} sm={12} md={12} lg={12} xl={12}>
          <ConfirmationVoucher item>
            <ImgCheck src={mensajeExitoso} alt="Mi SVG" />
            <TypographyH4II text={selectedPurchaseIdInvoice ? "¡Actualizar factura!" : "¡Compra exitosa!"} fontColor="#035533" />
          </ConfirmationVoucher>
        </Grid>

        <Grid item className="GridBody" xs={10} sm={10} md={12} lg={10} xl={12}>
          <Grid container justifyContent="center" mt={2}>
            <Grid container >
              <StyledInputText name="invoice" required id="invoice" label="Número de factura" variant="outlined" autoFocus value={invoice} onChange={(e) => setInvoice(e.target.value)} error={errors.invoice ? true : false} helperText={errors.invoice ? errors.invoice : ''} />
            </Grid>
            <Grid container mt={2} justifyContent={"center"} alignItems={"center"}>
              <ButtonActions360
                label={selectedPurchaseIdInvoice ? "Actualizar n° factura" : "Terminar compra"}
                onClick={handleFinishPurchase}
                backgroundColor={"#035533"}
              />
            </Grid>
          </Grid>
        </Grid>
      </ModalEdit>
    </Modal>
  );
}