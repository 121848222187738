import React from "react";
import { StyledInputText2 } from "./Styled"; 

const InputText2 = ({ label, value, onChange, required, disabled }) => {

  return (
    <>
      {required ? (
        <StyledInputText2 required disabled={disabled} id="outlined-multiline-flexible" variant="outlined" label={label} value={value} onChange={onChange} />
      ) : (
        <StyledInputText2
        disabled={disabled} id="outlined-multiline-flexible" variant="outlined" label={label} value={value} onChange={onChange} />
      )}
    </>
  );
};

export default InputText2;
