import styled, { css } from "styled-components";
import { Card, Grid, Typography } from "@mui/material";

const cardBodyStyles = css`
  margin-bottom: 0.5%;
  width: 100%;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 0.5% 2% 0% 1%;
  }

  /* Dimensiones comunes */
  @media (max-width: 1535px) {
    width: 100%;
  }
`;

export const CardBody = styled(Card)`
  ${cardBodyStyles};
  height: auto;
  padding-bottom: 2rem;

  .bodyCard{
    padding-left: 1rem;
  }  
`;

export const CardBody2 = styled(Card)`
  height: auto;
  padding-bottom: 2rem;
  margin-top: 0.5rem;

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 0.5% 2% 0% 1%;
  }
`;

export const CardBody3 = styled(Card)`
  height: auto;
  padding-bottom: 2rem;
  margin-top: 0.5rem;

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 0.5% 2% 0% 1%;
  }
`;

const chooseCard = (props) => {
  switch (props.variant) {
    case 'CardBody':
      return CardBody;
    case 'CardBody2':
      return CardBody2;
    case 'CardBody3':
      return CardBody3;
    default:
      return CardBody;
  }
}

export const ConditionalCard = styled(chooseCard)`
  /* Aquí puedes definir estilos adicionales para el componente */
`;

export const GridBody = styled(Grid)`
  width: 100%;
  height: 100%;
`;

export const TittleModule = styled(Typography)`
  margin: 20px 0px 10px 0px !important;
  font-size: 13px;
  color: #035533;
  font-weight: 550 !important;
`;

export const GridBtn = styled(Grid)`
  display: flex;
  justify-content: end;
  align-items: flex-end;
  gap: 10px;

  .buttonAction360 {
    width: 20%;
    align-items: flex-end;
  }
`;

export const CardTable = styled(Card)`
  margin: 30px 0px 0px 0px !important;
  height: auto;
`;
