import React, { useEffect, useState } from 'react';

import Tabs from '@mui/material/Tabs';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { HeaderSidebar } from '../../home/Styled';
import { Grid, Tooltip, Box } from '@mui/material';
import { BoxImg } from '../../client/forms/Styled';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { BoxSidebar, Item, TabItem, TextItem } from './Styled';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import DomainAddRoundedIcon from '@mui/icons-material/DomainAddRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import Lenddi from '../../../Assets/images/Logos/LenddiBlanco.svg';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#035533',
    },
  },
});
export default function ItemsSidebarII({ setActiveTab }) {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  const handleResize = () => {
    setOpen(window.innerWidth >= 900);
  };
 
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.localStorage.setItem("movil", JSON.stringify(!open));
  }, [open]);

  const getTabIcon = (index) => {
    const icons = {
      1: <HomeRoundedIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      2: <DomainAddRoundedIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      3: <BusinessRoundedIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      4: <PeopleAltRoundedIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      5: <RecentActorsIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      7: <AddBusinessRoundedIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
      8: <PriceCheckIcon sx={{ color: "white", marginLeft: "0.5rem" }} />,
    };
    return icons[index] || null;
  };

  const getTabContent = (index, label) => {
    return (
      <Item>
        {open ?
          <>
            {getTabIcon(index)}
            <TextItem className='typographyItem'>{label}</TextItem>
          </>
          :
          <Tooltip title={label} placement="right">
            <div>
              {getTabIcon(index)}
            </div>
          </Tooltip>
        }
      </Item>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <MuiDrawer variant="permanent" open={open}>
          <BoxSidebar>
            <Tabs orientation="vertical" value={value} onChange={handleChange} className='orientationTab' indicatorColor="secondary">
              <HeaderSidebar>
              <Grid container alignItems={'center'} mt={2}>
                  {open ? <BoxImg src={Lenddi} alt="Lenddi" /> : <BoxImg src={Lenddi} alt="Lenddi" />}
                </Grid>
              </HeaderSidebar>
    
              {open && <Typography className='typographyTab'> PRINCIPAL </Typography>}
              <TabItem label={getTabContent(1, 'Dashboard')} />

              {open && <Typography className='typographyTab'> CAJEROS </Typography>}
              <TabItem label={getTabContent(5, 'Lista de cajeros')} />

              {open && <Typography className='typographyTab'> MOSTRARIO </Typography>}
              <TabItem label={getTabContent(7, 'CATÁLOGO DE PUBLICIDAD')} />

              {open && <Typography className='typographyTab'> FACTURACION </Typography>}
              <TabItem label={getTabContent(8, 'Lista de ventas')} />
            </Tabs>
          </BoxSidebar>
        </MuiDrawer>
      </Box>
    </ThemeProvider>
  );
}
