import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import LogoutIcon from '@mui/icons-material/Logout';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Lenddi from "../../../Assets/images/Logos/LenddiBlanco.svg";
import { useAuthContext } from "../../../Contexts/AuthContext.jsx";
import { ContLogout, FondoLogout } from "../../store/forms/Styled.jsx";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Fab, Grid } from "@mui/material";
import { BackgrundInfoArreglado, ContCredimg, ContEstab, ContHeaderArreglado, ContPurchase, ContPurchaseMain, ContainerButtonWhts, Establishments, FlexRowStart } from "./Styled.jsx";
import ProfileMenu from "../../Components/menu/Profile.jsx";
import SliderSubcategoryEstablishment from "../../../components/elements/slider/SliderSubcategoryEstablishment.jsx";
import SelectCity from "../../../components/elements/ComboBox/comboCity.jsx";

export default function SubcategoriesEstablishment() {
  const { logout } = useAuthContext();
  const [subCategoriesData, setSubCategoriesData] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [activeLogout, setActiveLogout] = useState(false);
  const [companyData, setCompanyData] = useState([{ company_name: "compañia", company_phone: "0" }])
  const navigate = useNavigate()
  let { id } = useParams();
  const city = localStorage.getItem('ciudad') || null;

  const fetchSubcategories = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/getEstablishmentOnCategorySub/${id}`, {
        city: city
      })
      setSubCategoriesData(response.data);
      setSubCategories(response.data.establishmentsBySubcategory);
    } catch (error) {
    }
  };

  const fetchCompanyData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCompanyToClient`);
      setCompanyData(response.data);
    } catch (error) {
    }
  };

  const Logout = () => {
    logout()
    setActiveLogout(!activeLogout)
    setTimeout(() => {
      navigate('/loginClient');
    }, [1000]);
  }

  useEffect(() => {
    fetchCompanyData();
    fetchSubcategories()
  }, [city])

  return (
    <ContPurchaseMain $actLogout={activeLogout}>
      <FondoLogout>
        <ContLogout>
          <LogoutIcon />
          <h3>¿Quieres cerrar sesión?</h3>
          <button className='button Confirm' onClick={() => Logout()}>SI</button>
          <button className='button Cancel' onClick={() => setActiveLogout(!activeLogout)}>NO</button>
        </ContLogout>
      </FondoLogout>
      <ContPurchase>
        <BackgrundInfoArreglado>
          <ContHeaderArreglado>
            <Grid container item xs={11} sm={11} md={5} lg={4} xl={3} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}> {/* en pc el width: 10% */}
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
              <Link to={`/client`}>
                  <ContCredimg src={Lenddi} />
                </Link>
              </Grid>
              <Grid item xs={5} sm={4} md={7} lg={7} xl={7} >
               <SelectCity />
              </Grid>
            </Grid>
            <ProfileMenu />
          </ContHeaderArreglado>
        </BackgrundInfoArreglado>

        <ContEstab>
          <Establishments>
            <FlexRowStart className="EstablishmentName">
              <Link to={`/client/`}>
                <KeyboardArrowLeftIcon />
                <h1>{subCategoriesData.category}</h1>
              </Link>
            </FlexRowStart>
          </Establishments>
          <SliderSubcategoryEstablishment subCategories={subCategories}/>
        </ContEstab>

        <ContainerButtonWhts>
          <Fab style={{ backgroundColor: "#24d163" }} aria-label="edit">
            <a href={`https://wa.me/${companyData[0].company_phone}?text=Estoy%20interesado%20en%20asesoria%20con%20${companyData[0].company_name}`} target="_blank" rel="noreferrer" style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <WhatsAppIcon alt="Chatea con nosotros" className="whatsapp-button" sx={{ color: "white", fontSize: "28px" }} />
            </a>
          </Fab>
        </ContainerButtonWhts>
      </ContPurchase>

    </ContPurchaseMain>
  );
}
