import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';
import axios from 'axios';

import ModalInvoice from '../modal/invoice';
import Pagination from '@mui/material/Pagination';
import { MaterialReactTable } from 'material-react-table';
import FormatPrice from '../../../Utilities/FormatPrices';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuthContext } from '../../../Contexts/AuthContext';
import Tittle from '../../../components/elements/labels/Tittle';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ModalViewDetailsPurchase from '../modal/ViewDetailsPurchase';
import { LineDivider } from '../../../components/elements/lines/Styled';
import TypographyH3 from '../../../components/elements/labels/TypographyH3';
import TypographyH5 from '../../../components/elements/labels/TypographyH5';
import TypographyH6 from '../../../components/elements/labels/TypographyH6';
import TypographyH6II from '../../../components/elements/labels/TypographyH6II';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Button, Grid, Box, IconButton, Tooltip, Modal } from "@mui/material";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { BodyHeaderList, BoxContainer, ContainerBodyVoucher, ContainerHeaderList, ContainerHeaderListHistory, ContentTableHome, DivListTransactions, ItemVoucher, ListTransactions, ModalView, PriceDetailBuy } from './Styled';

export default function ListTransaccions() {
  const [/* Buy */, setBuy] = useState({});
  const { token, logout, role } = useAuthContext();
  const [compras, setCompras] = useState([]);
  const [details, setDetails] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [/* statusPurchase */, setStatusPurchase] = useState({});
  const [isModalOpenInvoice, setIsModalOpenInvoice] = useState(false);
  const [modalOpenInvoiceClient, setModalOpenInvoiceClient] = useState(false);
  const [selectedPurchaseId, SetselectedPurchaseId] = useState(null);
  const [/* selectedCompraId */, setSelectedCompraId] = useState(null);
  const [selectedPurchaseIdInvoice, setSelectedPurchaseIdInvoice] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const decoded = jwtDecode(token);
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCompras = compras.slice(indexOfFirstItem, indexOfLastItem);
  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewDetails = (idCompra) => {
    setSelectedCompraId(idCompra);
    fetchDetails(idCompra);
  };

  const handleBackToList = () => {
    setSelectedCompraId(null);
    setShowDetail(false);
  };

  const fetchBuy = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getLastpurchase/${decoded.id}`, {
        headers: {
          authorization: token,
        },
      });
      setBuy(response.data)
    } catch (error) {
    }
  };

  const fetchListPurchase = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/GetPurchaseByCashier/${decoded.id_user}`, {
        headers: {
          authorization: token,
        },
      })
      if (response.data.length > 0) {
        const sortedCompras = response.data.sort((a, b) => new Date(b.purchase_date) - new Date(a.purchase_date));
        setCompras(sortedCompras);
      }
    } catch (error) {

      if (error.response.status === 401 && role !== 'cajero') {
        logout()
      }
    }
  };

  const fetchListBuys = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/GetPurchaseForClient/${decoded.id_user}`);
      if (response.data.length > 0) {
        const sortedCompras = response.data.sort((a, b) => new Date(b.purchase_date) - new Date(a.purchase_date));
        setCompras(sortedCompras);
      }
    } catch (error) {
      if (error.response.status === 401 && role !== 'cliente') {
        logout()
      }
    }
  };

  const fetchDetails = async (idCompra) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getOnePurchase/${idCompra}`, {
        headers: {
          authorization: token,
        },
      });
      setModalOpenInvoiceClient(true)
      setDetails(response.data);
    } catch (error) { }
  };

  const handleViewModal = async (idCompra, confirm) => {
    SetselectedPurchaseId(idCompra)
    setIsModalOpen(true)
  }


  const handleCloseModal = () => {
    setModalOpenInvoiceClient(false);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'purchase_id',
        header: 'Id venta',
        size: 10
      },
      {
        accessorKey: 'purchase_date',
        header: 'Fecha',
        size: 30
      },
      {
        accessorKey: 'customer_name',
        header: 'Nombre del cliente',
        size: 150
      },
      {
        accessorKey: 'value',
        header: 'Valor de compra',
        size: 0,
        Cell: ({ cell }) => (
          <Box component="span" sx={{ fontWeight: 'bold', p: '0.25rem', display: "flex", justifyContent: "end", marginLeft: '45px' }} >
            {<FormatPrice price={cell.getValue()} />}
          </Box>
        )
      },
      {
        accessorKey: 'status',
        header: 'Estado de la venta',
        size: 30,
        Cell: ({ cell, row }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color:
                row.original.status === 'Exitoso'
                  ? theme.palette.success.dark
                  : row.original.status === 'pendiente'
                    ? 'rgb(255, 174, 0)'
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              fontWeight: 'bold',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        size: 1,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex' }}>
            <Tooltip title="Ver detalle de venta" arrow>
              <IconButton style={{ color: '#037847' }} onClick={() => handleViewModal(row.original.purchase_id)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (decoded.id && role !== 'cliente') {
      fetchBuy();
    }

    if (decoded.id_user) {
      fetchListPurchase();
    }

    if (role !== 'cajero') {
      fetchListBuys();
    }
  }, [decoded.id]);

  return (
    <>
      {role === "cajero" ? (
        <BoxContainer>
          <Grid className='containerHeader'container item xs={12} sm={12} md={12} lg={12} xl={8.5}>
            <ContainerHeaderList style={{ marginBottom: "2rem" }}>
              <BodyHeaderList>
                <Grid >
                  <Link to="/store/" >
                    <Button onClick={handleBackToList} startIcon={<ArrowBackIosNewRoundedIcon style={{ fontSize: 35, color: 'white' }} />} />
                  </Link>
                </Grid>
                <Grid  >
                  <Tittle text="Historial de transacciones" className="tittle" />
                </Grid>
                <Grid pl={8} />
              </BodyHeaderList>
            </ContainerHeaderList>
          </Grid>
          <ContentTableHome>
            <MaterialReactTable columns={columns} data={compras}
              initialState={{
                density: 'compact'
              }}
            />
            {selectedPurchaseId !== null && (
              <ModalViewDetailsPurchase isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedPurchaseId={selectedPurchaseId} />
            )}

            {selectedPurchaseIdInvoice !== null && (
              <ModalInvoice isModalOpen={isModalOpenInvoice} setIsModalOpen={setIsModalOpenInvoice} selectedPurchaseIdInvoice={selectedPurchaseIdInvoice} />
            )}
          </ContentTableHome>
        </BoxContainer>
      ) : (
        <ContainerBodyVoucher style={{ width: "100%" }}>
          <Grid item className='containerGrid' xs={12} sm={12} md={12} lg={12} xl={12}>
            <DivListTransactions>
              <ContainerHeaderListHistory>
                <BodyHeaderList>
                  <Grid mt={1.2}>
                    <Link to="/client/" >
                      <Button onClick={handleBackToList} startIcon={<ArrowBackIosNewRoundedIcon style={{ fontSize: 35, color: 'white' }} />} />
                    </Link>
                  </Grid>
                  <Grid mt={1.5} >
                    <Tittle text="Historial de transacciones" className="tittle" />
                  </Grid>
                  <Grid pl={8} />
                </BodyHeaderList>
              </ContainerHeaderListHistory>
              {showDetail ? null : (
                currentCompras.map((compra, index) => (
                  <Grid key={index} item width={"95%"} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListTransactions >
                      <Grid className='boxButton'>
                        <Grid className='date'>
                          <TypographyH6 text={compra.fecha_compra} fontColor="#868b89" mt={1} ml={1} />
                          <TypographyH5 text={""} fontColor="gray" mt={1} ml={1} />
                        </Grid>

                        <Button onClick={() => handleViewDetails(compra.id_compra)} sx={{ color: "gray" }}>
                          <Grid container alignItems="center" className='tittleTransaction'>
                            <Grid item>
                              <TypographyH5 text={compra.establecimiento} fontColor="#49514e" mt={1} />
                            </Grid>
                            <Grid container item className='see'>
                              <ArrowCircleUpIcon sx={{ paddingRight: "10px" }} />
                              <TypographyH6II text={"Valor de credito"} fontColor="#037847" />
                            </Grid>
                          </Grid>
                          <Grid container alignItems="center" className='tittleTransaction2'>
                            <Grid container item className='see'>
                              <RemoveRedEyeOutlinedIcon sx={{ paddingRight: "10px" }} />
                              <TypographyH6II text={"Ver detalle"} fontColor="#037847" />
                            </Grid>
                            <Grid item>
                              <TypographyH5 text={<FormatPrice price={compra.valor_compra} />} fontColor="#49514e" mt={1} />
                            </Grid>
                          </Grid>
                        </Button>
                      </Grid>
                    </ListTransactions>

                    <Modal open={modalOpenInvoiceClient} onClose={handleCloseModal}>
                      <ModalView className="ModalStyle">
                        <Grid item className='GridHeader' xs={12} sm={12} md={12} lg={12} xl={12}>
                          <TypographyH6II text="Detalle de transacción" fontColor="#037847" sx={{ display: "flex", justifyContent: "center", marginTop: 2 }} />
                          <LineDivider color="#767c7a" width="96%" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={10} sx={{ display: "flex", justifyContent: "center" }}>
                          <ItemVoucher sx={{ width: "27%" }}>
                            <TypographyH6II text="Nombre:" fontColor="#037847" mt={1} />
                            <TypographyH6II text="C.C:" fontColor="#037847" mt={1} />
                            <TypographyH6II text="Fecha:" fontColor="#037847" mt={1} />
                            <TypographyH6II text="Hora:" fontColor="#037847" mt={1} />
                            <TypographyH6II text="Almacen:" fontColor="#037847" mt={1} />
                            <TypographyH6II text="Valor de credito:" fontColor="#037847" mt={1} />
                          </ItemVoucher>
                          <ItemVoucher sx={{ width: "70%", alignItems: "flex-end" }}>
                            <TypographyH6II text={details.customer_name2} fontColor="#767c7a" mt={1} />
                            <TypographyH6II text={details.customer_id} fontColor="#767c7a" mt={1} />
                            <TypographyH6II text={details.purchase_date} fontColor="#767c7a" mt={1} />
                            <TypographyH6II text={details.purchase_time} fontColor="#767c7a" mt={1} />
                            <TypographyH6II text={details.business_name} fontColor="#767c7a" mt={1} />
                            <TypographyH6II text={<FormatPrice price={details.value} />} fontColor="#767c7a" mt={1} />
                          </ItemVoucher>
                        </Grid>
                        <LineDivider color="#49514e" width="96%" />
                        <PriceDetailBuy>
                          <TypographyH6 text="Total pagado:" fontColor="#767c7a" mt={2} />
                          <TypographyH3 text={<FormatPrice price={details.value} />} fontColor="#037847" />
                        </PriceDetailBuy>
                      </ModalView>
                    </Modal>
                  </Grid>
                ))
              )}

              {!showDetail && (
                <Pagination sx={{ marginTop: 3 }} count={Math.ceil(compras.length / itemsPerPage)} page={currentPage} onChange={handlePageChange} />
              )}
            </DivListTransactions>
          </Grid>
        </ContainerBodyVoucher>
      )}
    </>
  )
}