import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ModalView } from './Styled';
import { Modal, Grid, Typography } from '@mui/material';
import FormatPrice from '../../../../../Utilities/FormatPrices';
import { useAuthContext } from '../../../../../Contexts/AuthContext';
import { LineDivider } from '../../../../../components/elements/lines/Styled';
import ButtonActiosn360 from '../../../../../components/elements/Buttons/ButtonActions360';
import TypographyDetails from '../../../../../components/elements/labels/TypographyDetails';

export default function ModalViewDetailsSale({ selectedSaleId, isModalOpen, setIsModalOpen }) {
  const [saleData, setSaleData] = useState(null);
  const { token } = useAuthContext();

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  useEffect(() => {
    async function fetchsaleData() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getOnePurchase/${selectedSaleId}`, {
          headers: {
            authorization: token,
          },
        });
        setSaleData(response.data);
      } catch (error) {
       }
    }

    if (selectedSaleId && isModalOpen) {
      fetchsaleData();
    }
  }, [selectedSaleId, isModalOpen]);

  return (
    <div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <ModalView className="ModalStyle">
          {saleData && (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Grid item className='GridHeader'>
                <Typography className='TypographyHeader'>Información compra</Typography>
                <LineDivider color="#035533" width="98%" />
              </Grid>

              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={11.5} xl={11.5} >
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Ref. compra:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={saleData.purchase_id} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Ref. factura:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={saleData.invoice} fontColor="gray" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={11.5} xl={11.5} >
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={8} xl={8}>
                    <TypographyDetails text="Valor de compra:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={4} xl={4}>
                    <TypographyDetails text={<FormatPrice price={saleData.value} />} fontColor="gray" />
                  </Grid>
                </Grid>
                
              </Grid>

              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={11.5} xl={11.5} >
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Cliente:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={`${saleData.customer_id} - ${saleData.customer_name} `} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Cajero:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={saleData.cashier_name} fontColor="gray" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={11.5} xl={11.5} >
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Establecimiento:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={saleData.business_name} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Fecha:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={saleData.purchase_date} fontColor="gray" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item className='bodyCard' xs={12} sm={12} md={12} lg={11.5} xl={11.5} >
                <Grid container item className='blockItem' xs={11.5} sm={11.5} md={11.5} lg={12} xl={12} mt={3}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={9} xl={9}>
                    <TypographyDetails
                      text={saleData.status}
                      fontSize={"25px"}
                      fontColor={
                        saleData.status === 'Exitoso'
                          ? 'green'
                          : saleData.status === 'Rechazado'
                            ? 'red'
                            : saleData.status === 'pendiente'
                              ? 'orange'
                              : 'black' // Puedes ajustar el color predeterminado según tus necesidades
                      }
                    />
                  </Grid>

                  <Grid item className='end' xs={12} sm={12} md={12} lg={3} xl={3}>
                    <ButtonActiosn360 label="CERRAR" onClick={() => handleCloseModal()} backgroundColor={"#035533"} />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </ModalView>
      </Modal>
    </div>
  );
}
