import React from 'react';
import { StyledButton360 } from './Styled.jsx';

const Button360 = ({ label, onClick, size }) => {
  return (
    <StyledButton360 variant="contained" onClick={onClick} sx={{ fontSize: `${size}` }}>
      {label}
    </StyledButton360>
  );
}

export default Button360;
