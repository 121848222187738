import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Grid, Button } from '@mui/material';
import { LineDivider } from '../../../../../components/elements/lines/Styled';
import ButtonActiosn360 from '../../../../../components/elements/Buttons/ButtonActions360';
import axios from 'axios';
import { useAuthContext } from '../../../../../Contexts/AuthContext';
import Swal from 'sweetalert2';
import { ModalView } from './Styled';
import { StyledInputText } from '../../../../../components/elements/Inputs/Styled';
import ComboBox360 from '../../../../../components/elements/ComboBox/comboBox360';

const ModalEditAdminBranch = ({ isOpen, onClose, adminsBranchId, selectedAdminsBranch }) => {
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [isPasswordEditable, setIsPasswordEditable] = useState(false);
  const [selectedCiudad, setSelectedCiudad] = useState("");
  const { token } = useAuthContext();
  const [ubicacion, setUbicacion] = useState({ domicilio: "" });
  const [direccion, setDireccion] = useState({
    tipoVia: "",
    primerNumeroVia: "",
    segundoNumeroVia: "",
    tercerNumeroVia: "",
  });
  const [formData, setFormData] = useState({
    cashier_id: "",
    cashier_name: "",
    personal_email: "",
    cashier_phone: "",
    cashier_address: "",
    general_details: "",
    cashier_email: "",
    cashier_password: "",
    confirm_cashier_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordFields = () => {
    setIsPasswordEditable(!isPasswordEditable);
  };

  const updateUbicacion = () => {
    const { tipoVia, primerNumeroVia, segundoNumeroVia, tercerNumeroVia } = direccion;
    const domicilio = `${tipoVia} ${primerNumeroVia} ${segundoNumeroVia} ${tercerNumeroVia}`;
    setUbicacion(domicilio);
  };

  const clearForm = () => {
    setFormData({
      cashier_id: "",
      cashier_name: "",
      personal_email: "",
      cashier_phone: "",
      cashier_address: "",
      general_details: "",
      cashier_email: "",
      cashier_password: "",
      confirm_cashier_password: "",
    });

    setDireccion({
      tipoVia: "",
      primerNumeroVia: "",
      segundoNumeroVia: "",
      tercerNumeroVia: "",
    });
  }

  const handleUpdateAdmin = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/updateEstablishmentAdmin/${adminsBranchId}`, {
        adminEst_name: formData.cashier_name,
        adminEst_email: formData.cashier_email,
        adminEst_phone: formData.cashier_phone,
        adminEst_address: ubicacion,
        general_details: formData.general_details,
        personal_email: formData.personal_email,
        adminEst_password: formData.cashier_password,
        confirm_adminEst_password: formData.confirm_cashier_password,
      }, {
        headers: {
          authorization: token,
        },
      });
      Swal.fire(
        'Actualizacion exitosa!',
        'Establecimiento actualizado exitosamente!',
        'success'
      )
      clearForm();
      onClose(true);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Hubo un error al intentar actualizar el administrador. Verifique que todos los campos requeridos estén llenos.',
        confirmButtonColor: '#035533',
      });
    }
  };

  useEffect(() => {
    setFormData(selectedAdminsBranch || {
      cashier_id: "",
      cashier_name: "",
      personal_email: "",
      cashier_phone: "",
      cashier_address: "",
      cashier_email: "",
      superAdminPassword: "",
      confirmSuperAdminPassword: "",
    });
  }, [selectedAdminsBranch]);

  return (
    <Modal open={isOpen} onClose={() => onClose(false)}>
      <ModalView className="ModalStyle">
        <Grid item className='GridHeader'>
          <Typography className='TypographyHeader'>Actualizar administrador de sucursal</Typography>
          <LineDivider color="#035533" width="98%" />
        </Grid>
        <Grid container item className='bodyCard' xs={11} sm={11.3} md={11.5} lg={11.5} xl={11.5} spacing={1} mt={0.1}>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Tipo identificación" name="cashier_id_type" disabled value={formData.cashier_id_type || ''} onChange={handleChange} fullWidth margin="normal" size='small' />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="N° Identificación" name="cashier_id" disabled value={formData.cashier_id || ''} onChange={handleChange} fullWidth margin="normal" size='small' />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Nombre" name="cashier_name" value={formData.cashier_name || ''} onChange={handleChange} fullWidth margin="normal" size='small' />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Correo personal" name="personal_email" value={formData.personal_email || ''} onChange={handleChange} fullWidth margin="normal" size='small' />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Teléfono" name="cashier_phone" value={formData.cashier_phone || ''} onChange={handleChange} fullWidth margin="normal" size='small' />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Establecimiento" name="business_name" disabled value={formData.business_name || ''} onChange={handleChange} fullWidth margin="normal" size='small' />
          </Grid>
        </Grid>

        <Grid item className='GridHeader'>
          <Typography className='TypographyHeader'>Dirección de residencia</Typography>
          <LineDivider color="#035533" width="98%" />
        </Grid>
        <Grid container item className='bodyCard' xs={11} sm={11.3} md={11.5} lg={11.5} xl={11.5} spacing={1} mt={0.1}>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} mt={2}>
            <ComboBox360 label="Departamento" selectedValue={selectedDepartamento} setSelectedValue={setSelectedDepartamento} onBlur={updateUbicacion} />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} mt={2}>
            <ComboBox360 label="Ciudad" departamento={selectedDepartamento} selectedValue={selectedCiudad} setSelectedValue={setSelectedCiudad} onBlur={updateUbicacion} size="small" />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Tipo de via" name="tipoVia" value={direccion.tipoVia} onChange={(e) => setDireccion({ ...direccion, tipoVia: e.target.value })} fullWidth margin="normal" onBlur={updateUbicacion} size="small" />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Primer número de via" name="primerNumeroVia" value={direccion.primerNumeroVia} onChange={(e) => setDireccion({ ...direccion, primerNumeroVia: e.target.value })} fullWidth margin="normal" onBlur={updateUbicacion} size="small" />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Segundo número de via" name="segundoNumeroVia" value={direccion.segundoNumeroVia} onChange={(e) => setDireccion({ ...direccion, segundoNumeroVia: e.target.value })} fullWidth margin="normal" onBlur={updateUbicacion} size="small" />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Tercer número de via" name="tercerNumeroVia" value={direccion.tercerNumeroVia} onChange={(e) => setDireccion({ ...direccion, tercerNumeroVia: e.target.value })} fullWidth margin="normal" onBlur={updateUbicacion} size="small" />
          </Grid>
          <Grid item xs={5.8} sm={6} md={6} lg={6} xl={6} >
            <StyledInputText label="Información adicional" name="informationAditional" value={formData.informationAditional} onChange={handleChange} fullWidth margin="normal" size='small' />
          </Grid>
        </Grid>

        <Grid item className='GridHeader'>
          <Typography className='TypographyHeader'>Credenciales de acceso</Typography>
          <LineDivider color="#035533" width="98%" />
        </Grid>
        <Grid container item className='bodyCard' xs={11} sm={11.3} md={11.5} lg={11.5} xl={11.5} spacing={1} mt={0.1}>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Rol" name="role" disabled value={formData.role || ''} onChange={handleChange} fullWidth margin="normal" size='small' />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Correo del establecimiento" name="cashier_email" value={formData.cashier_email || ''} onChange={handleChange} fullWidth margin="normal" size='small' />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Contraseña" disabled={!isPasswordEditable} name="superAdminPassword"  onChange={handleChange} fullWidth margin="normal" size='small' />
          </Grid>
          <Grid item xs={5.8} sm={5.8} md={5.8} lg={3} xl={3} >
            <StyledInputText label="Confirmar Contraseña" disabled={!isPasswordEditable} name="confirmSuperAdminPassword" value={formData.confirmSuperAdminPassword || ''} onChange={handleChange} fullWidth margin="normal" size='small' />
          </Grid>
        </Grid>


        <Grid container item className='bodyCard' xs={11} sm={11.3} md={11.5} lg={11.5} xl={11.5} spacing={1} mt={0.1}>
          <Grid item xs={0} sm={0} md={0} lg={5.8} xl={9}>
            <Button onClick={togglePasswordFields}> Cambiar contraseña</Button>
          </Grid>
          <Grid container item xs={12} sm={12} md={5.8} lg={3} xl={3} >
            <ButtonActiosn360 label="GUARDAR" className='buttonAction360' backgroundColor={'#035533'} onClick={handleUpdateAdmin} />
          </Grid>
        </Grid>
      </ModalView>
    </Modal>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '0.5rem',
  boxShadow: 24,
  p: 4,
};

export default ModalEditAdminBranch;
