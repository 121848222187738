// @flow 
import { useNavigate, useParams  } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { CLIENT } from '../../router/routes/path';
import { ContCredimg, ContHeader } from './Styled';
import { useAuthContext } from '../../Contexts/AuthContext';
import InputText from '../../components/elements/Inputs/InputText';
import lenddi from "../../Assets/images/Logos/LenddiBlanco.svg";
import MarcaAguaLenddi from '../../Assets/images/Logos/MarcaAguaLenddi.png'
import { Dialog, DialogTitle, DialogContent, IconButton, Grid } from '@mui/material';
import { jwtDecode } from "jwt-decode";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const Client = () => {
  const navigate = useNavigate();
  const { login, logout } = useAuthContext()
  const [/* idClient */, setIdClient] = useState({})
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  let { token } = useParams();

  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      setIdClient(decodedToken.id_user);
      handleEnviarId(decodedToken.id_user);
    }
  }, []);

  const handleEnviarId = async (decodedToken) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/confirmCustomer`, { id: +decodedToken });
      login(response.data.token, response.data.role);
      navigate(CLIENT);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        logout();
      }
    }
  };

  return (
    <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display:"flex", justifyContent:"center" }}>
      <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
        <React.Fragment>
          <ContHeader>
            <ContCredimg src={lenddi} />
          </ContHeader>
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height: "100vh", overflow: "hidden", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <Grid item xs={11.3} sm={11.3} md={11.3} lg={11.3} xl={10.7}>
              <img src={MarcaAguaLenddi} alt="" style={{ width: "100%" }} />
            </Grid>
          </Grid>

          <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle id="responsive-dialog-title" color={'#035533'}> Ingrese su número de identificación </DialogTitle>
            <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, zIndex: -1, color: (theme) => theme.palette.grey[500], }} >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <InputText label="Núm. Identificación" onChange={(e) => setIdClient(e.target.value)} />
            </DialogContent>
          </BootstrapDialog>
        </React.Fragment>
      </Grid>
    </Grid >
  )
};