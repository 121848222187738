import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export const AntTabs = styled(Tabs)({
  '& .Mui-selected': {
    color: '#035533',
  },
});

export const AntTab = styled(Tab)({
  '&.Mui-selected': {
    color: '#035533 !important',
  },
  '@media (max-width: 900px)': {
    '& .MuiTypography-body1': {
      fontSize: '5px',
    },
  },
});