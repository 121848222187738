import React from 'react';
import { StyledTypography } from './Styled';

function TypographyH5({ text, mt, fontColor, ml, sx }) {
  return (
    <StyledTypography variant='h5' ml={ml} mt={mt} style={sx} sx={{
      '@media (max-width: 565px)': {
        fontSize: '18px !important',
      },
      '@media (max-width: 430px)': {
        fontSize: '15px !important'
      }
    }} color={fontColor}>
      {text}
    </StyledTypography>
  );
}

export default TypographyH5;