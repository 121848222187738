import styled from "styled-components";
import { Grid, Paper } from "@mui/material";

export const TableClients = styled(Paper)`
  width: 100%;
  height: auto;
  overflow: hidden;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 2% 2% 0% 2%;
  }
`;

export const GridBtn = styled(Grid)`
  display: flex;
  justify-content: end;
  gap: 10px;


  .buttonAction360{
    width: 20%;
    height: 15vh;
    align-items: flex-end;
    
    /* Dimensiones 100% */
    @media (max-width: 1526px) and (max-height: 836px) {
        font-size: 11px;
    }

    @media (max-width: 1356px) and (max-height: 836px) {
      font-size: 10px;
    }

    @media (max-width: 1274px) and (max-height: 836px) {
      font-size: 8px;
    }

    /* Dimensiones 125% */
    @media (max-width: 1360px) and (max-height: 620px) {
        font-size: 10px;
    }
    @media (max-width: 1280px) and (max-height: 620px) {
      font-size: 9px;
    }
    @media (max-width: 1190px) and (max-height: 620px) {
        font-size: 8px;
    }
    @media (max-width: 1106px) and (max-height: 620px) {
        font-size: 7px;
    }

    /* Dimensiones 150% */
    @media (max-width: 1190px) and (max-height: 477px) {
        font-size: 7px;
    }
    @media (max-width: 1107px) and (max-height: 477px) {
        font-size: 7px;
    }
  }
`