import React from 'react';
import { StyledTypography } from './Styled';

function TypographyH4II({ text, mt, ml, fontColor, sx }) {
  return (
    <StyledTypography variant='h4' mt={mt} ml={ml} style={sx} sx={{
      '@media (max-width: 600px)': {
        fontSize: '25px !important',
      }
    }} color={fontColor}>
      {text}
    </StyledTypography>
  );
}

export default TypographyH4II;