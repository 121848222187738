import { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Edit as EditIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Box, Typography, Button, Grid, Switch, Tooltip, Modal } from '@mui/material';
import { GridHeaderII } from '../../../moduleDashboard/Styled';
import { TittleModule } from '../../../registrationEstablishment/Styled';
import ButtonActiosn360 from '../../../../../../components/elements/Buttons/ButtonActions360';
import { LineDivider } from '../../../../../../components/elements/lines/Styled';
import { ModalActions } from '../../modals/Styled';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { StyledInputText2 } from '../../../../../../components/elements/Inputs/Styled';
import axios from 'axios';
import { useAuthContext } from '../../../../../../Contexts/AuthContext';
import Swal from 'sweetalert2';
import ProfileMenu from '../../../../../Components/menu/Profile';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const ListCategories = () => {
  const { token, role } = useAuthContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [listCategory, setListCategory] = useState({});
  const [/* selectedImage */, setSelectedImage] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [modalTitle, setModalTitle] = useState("Crear nueva categoría");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setFile(file);
      setSelectedFileName(file.name);
    }
  };

  const handleOpenModalCategory = () => {
    setIsModalOpen(true);
    setCategory("");
    setModalTitle("Crear nueva categoría");
  };

  const handleOpenModalSubcategory = (categoryId) => {
    setIsModalOpen(true);
    setCategory(categoryId);
    setModalTitle("Crear nueva subcategoría");
  };

  const handleOpenModalEditCategory = (rowData) => {
    setIsModalOpen(true);
    setModalTitle("Editar categoría");
    setCategory(rowData.category_name);
    setSelectedCategoryId(rowData.category_id);
    setSelectedImage(rowData.image);
    setSelectedFileName(rowData.image);
  };

  const handleOpenModalEditSubcategory = (rowData, subcategoryId) => {

    setIsModalOpen(true);
    setModalTitle("Editar subcategoría");
    setSelectedSubCategoryId(subcategoryId);
    setSelectedCategoryId(rowData.category_id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formData = new FormData();
  formData.append("category_name", category);
  formData.append("image", file);

  const handleGetCategory = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCategories/${role}`, {
        headers: {
          "authorization": token,
        },
      })
      setListCategory(response.data)
    } catch (error) {
    }
  }

  const handleCreateCategory = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/createCategory`, formData, {
        headers: {
          "authorization": token,
        },
      })
      handleGetCategory()
      setCategory("")
      handleCloseModal()
      Swal.fire(
        'Bien hecho!',
        'Categoria de productos creada exitosamente!',
        'success'
      )
    } catch (error) {
    }
  }

  const handleCreateSubCategory = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/createSubcategory`, {
        category_id: category,
        subcategory_name: subCategory,
      }, {
        headers: {
          "authorization": token,
        },
      })
      handleGetCategory()
      setSubCategory("")
      handleCloseModal()
      Swal.fire(
        'Bien hecho!',
        'subCategoria de productos creada exitosamente!',
        'success'
      )
    } catch (error) {
    }
  }

  const handleUpdateCategory = async (categoryId) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/updateCategory/${categoryId}`, formData, {
        headers: {
          "authorization": token,
        },
      })
      handleGetCategory()
      setCategory("")
      handleCloseModal()
      Swal.fire(
        'Bien hecho!',
        'Categoria de productos actualizada exitosamente!',
        'success'
      )
    } catch (error) {
    }
  }

  const handleUpdateStateCategory = async (rowData) => {
    try {
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/updateCategoryStatus/${rowData.category_id}`, null, config);
      setListCategory((prevCategory) => {
        return prevCategory?.map((category) => {
          if (category.Status === rowData.Status) {
            return { ...category };
          } else {
            return category;
          }
        });
      }, response.data);
      handleGetCategory()
    } catch (error) {
    }
  };

  const handleUpdateSubCategory = async (subCategoryId, categoryId) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/updateSubcategory/${subCategoryId}`, {
        category_id: categoryId,
        subcategory_name: subCategory
      }, {
        headers: {
          "authorization": token,
        },
      })
      handleGetCategory()
      setSubCategory("")
      handleCloseModal()
      Swal.fire(
        'Bien hecho!',
        'Categoria de productos actualizada exitosamente!',
        'success'
      )
    } catch (error) {
    }
  }

  const handleUpdateStateSubCategory = async (rowData, subcategoryId) => {
    try {
      const config = {
        headers: {
          Authorization: token,
        },
      };
      await axios.put(`${process.env.REACT_APP_API_URL}/updateSubcategoryStatus/${subcategoryId}`, null, config);
      handleGetCategory()
    } catch (error) {
    }
  };

  useEffect(() => {
    handleGetCategory()
    if (modalTitle === "Crear nueva categoría") {
      handleCreateCategory();
    } else if (modalTitle === "Crear nueva subcategoría") {
      handleCreateSubCategory();
    }
  }, []);


  const renderActions = (row, type, subcategoryId, subCategoryStatus) => (
    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
      {type === "categorias" ?
        <>
          <Button style={{ color: '#035533' }}>
            <Tooltip title="Editar">
              <EditIcon onClick={() => handleOpenModalEditCategory(row.original)} />
            </Tooltip>
          </Button>
          <Tooltip title={row.original.Status === 'activo' ? 'Desactivar' : 'Activar'} >
            <Switch checked={row.original.Status === "activo"} color={row.original.Status === "activo" ? "success" : "error"} onClick={() => handleUpdateStateCategory(row.original)} />
          </Tooltip></>
        :
        <>
          <Button style={{ color: '#035533' }}>
            <Tooltip title="Editar">
              <EditIcon onClick={() => handleOpenModalEditSubcategory(row.original, subcategoryId)} />
            </Tooltip>
          </Button>
          <Tooltip title={row.original.Status === 'activo' ? 'Desactivar' : 'Activar'} >
            <Switch checked={subCategoryStatus === "activo"} color={subCategoryStatus === "activo" ? "success" : "error"} onClick={() => handleUpdateStateSubCategory(row.original, subcategoryId)} />
          </Tooltip>
        </>
      }
    </Box >
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'category_name',
        header: 'Categoria',
        size: 550,
      },
      {
        accessorKey: 'Status',
        header: 'Estado de categoria',
        size: 530,
        Cell: ({ cell, row }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color:
                row.original.Status === 'activo'
                  ? theme.palette.success.dark
                  : row.original.Status === 'pendiente'
                    ? 'rgb(255, 174, 0)'
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              fontWeight: 'bold',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        )
      },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        size: 300,
        Cell: ({ row }) => renderActions(row, "categorias")
      },
    ],
    [],
    //end
  );

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <ModalActions className="ModalStyle">
          <Grid item className='GridHeader' xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className='TypographyHeader'>{modalTitle}</Typography>
            <LineDivider color="#035533" width="96%" />
          </Grid>
          {modalTitle === "Crear nueva categoría" || modalTitle === "Editar categoría" ? (
            <>
              <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} m={2}>
                <StyledInputText2 required label="Categoria" value={category} onChange={(e) => setCategory(e.target.value)} />
                <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} mt={2}>
                  <Tooltip title={selectedFileName || "Seleccionar archivo"} arrow>
                    <Button variant="contained" component="label" startIcon={<CloudUploadIcon />} style={{ textTransform: 'none', backgroundColor: '#035533' }}>
                      {selectedFileName ? "Imagen cargada" : "Subir imagen"}
                      <VisuallyHiddenInput type="file" onChange={handleImageChange} />
                    </Button>
                  </Tooltip>
                </Grid>
                {modalTitle === "Crear nueva categoría" ? (
                  <Grid item m={2} textAlign={"end"}>
                    <ButtonActiosn360 label="CREAR" backgroundColor={"#035533"} onClick={handleCreateCategory} />
                  </Grid>
                ) : (
                  <Grid item m={2} textAlign={"end"}>
                    <ButtonActiosn360 label="ACTUALIZAR" backgroundColor={"#035533"} onClick={() => handleUpdateCategory(selectedCategoryId)} />
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} m={2}>
                <StyledInputText2 required label="Subcategoría" value={subCategory} onChange={(e) => setSubCategory(e.target.value)} />
                {modalTitle === "Crear nueva subcategoría" ? (
                  <Grid item m={2} textAlign={"end"}>
                    <ButtonActiosn360 label="CREAR" backgroundColor={"#035533"} onClick={() => handleCreateSubCategory()} />
                  </Grid>
                ) : (
                  <Grid item m={2} textAlign={"end"}>
                    <ButtonActiosn360 label="ACTUALIZAR" backgroundColor={"#035533"} onClick={() => handleUpdateSubCategory(selectedSubCategoryId, selectedCategoryId)} />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </ModalActions>
      </Modal>
      
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} m={1}>
        <ButtonActiosn360 label="NUEVA CATEGORIA" onClick={handleOpenModalCategory} backgroundColor={'#035533'} startIcon={<AddIcon />} />
      </Grid>
      <MaterialReactTable
        columns={columns}
        data={listCategory}
        enableExpandAll={false}
        muiDetailPanelProps={() => ({
          sx: (theme) => ({
            backgroundColor:
              theme.palette.mode === 'dark'
                ? 'rgba(255,210,244,0.1)'
                : 'rgba(0,0,0,0.1)',
          }),
        })}
        initialState={{
          density: 'compact'
        }}
        muiExpandButtonProps={({ row, table }) => ({
          onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
          sx: {
            transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
            transition: 'transform 0.2s',
          },
        })}
        renderDetailPanel={({ row }) =>
          row.original.category_name ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <GridHeaderII item xs={12} sm={12} md={12} lg={12} xl={12} >
                <TittleModule >Lista de subCategorias</TittleModule>
                <Grid item m={2} textAlign={"end"}>
                  <ButtonActiosn360 label="Crear subcategoría" backgroundColor={"#035533"} onClick={() => handleOpenModalSubcategory(row.original.category_id)} />
                </Grid>
              </GridHeaderII>
              <Box sx={{ display: 'flex', flexDirection: 'column', margin: 'auto', gridTemplateColumns: '1fr 1fr', width: '100%', }} >
                {row?.original?.subcategories.map((subcategory) => (
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <Typography key={subcategory.subcategory_id}>{subcategory.subcategory_name}</Typography>
                    </div>
                    <div>
                      {renderActions(row, "subcategorias", subcategory.subcategory_id, subcategory.Status)}
                    </div>
                  </div>
                ))}
              </Box>
            </Grid>
          ) : null
        }
      />
    </Grid>
  );
};

export default ListCategories;
