import React from 'react';
import { StyledTypography } from './Styled';

function TypographyDetails({ text, mt, fontColor, fontSize, sx }) {
  return (
    <StyledTypography fontSize={fontSize} mt={mt} sx={{
      '@media (max-width: 414px)': {
        fontSize: '15px !important',
      }
    }} color={fontColor}>
      {text}
    </StyledTypography>
  );
}

export default TypographyDetails;