import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';

import { ModalView } from './Styled';
import { Modal, Grid, Typography } from '@mui/material';
import FormatPrice from '../../../Utilities/FormatPrices';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { LineDivider } from '../../../components/elements/lines/Styled';
import ButtonActiosn360 from '../../../components/elements/Buttons/ButtonActions360';
import TypographyDetails from '../../../components/elements/labels/TypographyDetails';

export default function ModalViewDetailsPurchase({ selectedPurchaseId, isModalOpen, setIsModalOpen }) {
  const [dataPurchase, setDataPurchase] = useState(null);
  const { token } = useAuthContext();

  useEffect(() => {
    async function fetchDataPurchase() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/getOnePurchase/${selectedPurchaseId}`, {
          headers: {
            authorization: token,
          },
        });
        setDataPurchase(response.data);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Hubo un error al trer la informacion. No hay datos disponibles.',
          confirmButtonColor: '#035533',
        });
      }
    }

    if (selectedPurchaseId && isModalOpen) {
      fetchDataPurchase();
    }
  }, [selectedPurchaseId, isModalOpen]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <ModalView className="ModalStyle">
          {dataPurchase && (
            <>
              <Grid item className='GridHeader' mt={2}>
                <Typography className='TypographyHeader'>Información detallada de la venta</Typography>
                <LineDivider color="#035533" width="98%" />
              </Grid>

              <Grid container item className='bodyCard' xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5}>
                <Grid container item className='blockItem' xs={10} sm={10} md={10} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Id venta:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={dataPurchase.purchase_id} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={10} sm={10} md={10} lg={5.5} xl={5.5}>
                <Grid item className='start' xs={12} sm={4} md={4} lg={4.5} xl={4}>
                    <TypographyDetails text="Fecha y hora:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={8} md={8} lg={7.5} xl={8}>
                    <TypographyDetails text={dataPurchase.purchase_date_complete} fontColor="gray" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item className='bodyCard' xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5}>
                <Grid container item className='blockItem' xs={10} sm={10} md={10} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Id cliente:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={dataPurchase.customer_id} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={10} sm={10} md={10} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TypographyDetails text="Cliente:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={8} md={8} lg={8} xl={8}>
                    <TypographyDetails text={dataPurchase.name} fontColor="gray" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item className='bodyCard' xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5}>
                <Grid container item className='blockItem' xs={10} sm={10} md={10} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>

                    <TypographyDetails text="Establecimiento:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={dataPurchase.business_name} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={10} sm={10} md={10} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Cajero:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={dataPurchase.cashier_name} fontColor="gray" />
                  </Grid>
                </Grid>
              </Grid>

              
              <Grid container item className='bodyCard' xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5}>
              <Grid container item className='blockItem' xs={10} sm={10} md={10} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Núm. Factura:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={dataPurchase.invoice} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={10} sm={10} md={10} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Valor:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={<FormatPrice price={dataPurchase.value} />} fontColor="gray" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item className='bodyCard' xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5}>
              <Grid container item className='blockItem' xs={10} sm={10} md={10} lg={5.5} xl={5.5}>
                  <Grid item className='start' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text="Estado:" fontSize={"15px"} />
                  </Grid>
                  <Grid item className='end' xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TypographyDetails text={dataPurchase.status} fontColor="gray" />
                  </Grid>
                </Grid>
                <Grid container item className='blockItem' xs={10} sm={10} md={10} lg={5.5} xl={5.5}>
                </Grid>
              </Grid>

              <Grid container item className='bodyCard' xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} mt={5}>
                <Grid container item className='blockItem' xs={11.5} sm={5.9} md={7} lg={7} xl={8}>
                </Grid>
                <Grid container item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <ButtonActiosn360 label="CERRAR" onClick={() => handleCloseModal()} backgroundColor={"#035533"} />
                </Grid>
              </Grid>
              </>
          )}
        </ModalView>
      </Modal>
    </div>
  );
}
