import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid } from '@mui/material'
import { TittleModule } from './Styled';
import TableClientsQuotes from './tables/ClientsQuotes'
import { GridHeaderII } from '../moduleDashboard/Styled';
import ProfileMenu from '../../../Components/menu/Profile';
import { useAuthContext } from '../../../../Contexts/AuthContext';

export default function QuotaAllocation() {
  const nombresClientes = {}
  const { token } = useAuthContext();
  const [clientes, setClientes] = useState([]);
  const [/* error */, setError] = useState();

  async function GetClients() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/viewAssigned`, {
        headers: {
          authorization: token,
        },
      });
      setClientes(response.data.map(cliente => cliente.customer_id));
      response.data.forEach(cliente => {
        nombresClientes[cliente.customer_id] = cliente.customer_name;
      });
    } catch (error) {
      setError(error);
    }
  }

  useEffect(() => {
    GetClients();
  }, []);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
      <GridHeaderII container xl={12}>
        <TittleModule>Gestión de cupos</TittleModule>
        <ProfileMenu />
      </GridHeaderII>

      <TableClientsQuotes data={clientes} />
    </Grid>
  );
}