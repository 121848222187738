import "swiper/css";
import "./styles.css";
import "swiper/css/pagination";
import { Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Card, CardMedia, Typography } from "@mui/material";
import { ContaImage, ContEstablecimiento, } from "../../../pages/client/forms/Styled";
import { Link } from "react-router-dom";
export default function SliderEstablishmentXSubcategories({ establishment }) {
  return (
    <>
      <Swiper slidesPerView={3} spaceBetween={30} pagination={{ clickable: true }} modules={[Pagination]} className="mySwiper">
        <ContEstablecimiento style={{marginLeft:"1.5rem"}}>
          {establishment.map((establishmentSubcategory, index) => (
            <ContaImage key={index}>
              <Link to={establishmentSubcategory.url} key={index}>
                <Card sx={{ margin: "0px 0px 5px 10px", boxShadow: "0px 2px 5px 2px #0000001b" }}>
                  <CardMedia className="cardMediaEst" image={`${process.env.REACT_APP_API_URL}/${establishmentSubcategory.image}`} title={establishmentSubcategory.alt} />
                </Card>
              </Link>
              <div className="ProductDescription">
                <Typography sx={{ marginTop: "10px" }} variant="body2" color="text.secondary">
                  {establishmentSubcategory.business_name}
                </Typography>
              </div>
            </ContaImage>
          ))}
        </ContEstablecimiento>
      </Swiper>
    </>
  );
}
