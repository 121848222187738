import { Grid } from "@mui/material";
import styled from "styled-components";
import backLogin2 from '../../Assets/images/fondos svg/backLogin2.jpg'

export const BoxImg = styled.img`
  height: 50%;
  width: 100%;
  padding-top: 1rem;
  align-items: center;
  text-align: center;
  display: flex;
  @media (width: 1047px) {
    width: 70%;
  }
 
`;

export const ContLogout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 20rem;
  background-color: #fff;
  border-radius: 1rem;
  padding: 1.5rem;
  opacity: 0;
  transition: all .2s ease-in-out;
  * {
    color: #035533;
  }
  svg {
    font-size: 3rem;
  }
  .button {
    width: 100%;
    padding: 1rem 0;
    border-radius: 5px;
    font-size: 1.2rem;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .Confirm {
    background-color: #035533;
    color: #fff;
  }
  .Cancel {
    background: #fff;
    border: 1px solid #035533;
  }
`;

export const FondoLogout = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 100%;
  height: 0;
  transition: all 0.2 ease-in-out;
  background-color: #0000009b;
`;

export const MainDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  transition: all .2s ease-in-out;
  overflow: hidden;
  background-image: url(${backLogin2}); 
  background-size: cover; 
  background-position: center; 
  
  ${FondoLogout} {
    transition: all .2s ease-in-out;
    height: ${({ $actLogout }) => $actLogout ? '100%' : 0};
    ${ContLogout} {
      display: ${({ $actLogout }) => $actLogout ? "flex" : "none"};
      opacity: ${({ $actLogout }) => $actLogout ? 1 : 0};
    }
  }

 
`;

export const ContainerBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  height: 100vh;
  

  @media (max-width: 900px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  height: 13vh;
  background-color: #035533;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  border-radius: 0rem 0rem 1.5rem 1.5rem;

  .button:hover {
    background-color: #035533;
  }

@media (max-width: 768px) {
    width: 100% !important;
    height: 10%;
    border-radius: 0rem 0rem 1.5rem 1.5rem;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 10%;
    border-radius: 0rem 0rem 0rem 0rem;
  }
`;

export const ModuleLogin = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column !important;
  justify-content: center;

  .containerModuleStore {
    display: flex;
    flex-direction: column !important;
  }

  @media (max-width: 414px) {
    width: 80%;
  }
`;
