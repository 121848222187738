import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import TabsTableUsers from '../Components/tabs/TabTableUser';
import ItemsSidebar from '../Components/sidebars/SidebarAdmin';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Etatistics from '../admin/modules/moduleDashboard/Etatistics';
import ItemsSidebarII from '../Components/sidebars/SidebarAdminEstablishment';
import QuotaAllocation from '../admin/modules/quotaAllocation/QuotaAllocation';
import { BodyDashboard, BoxHome, ContainerBody, HeaderDashboard } from './Styled';
import OffertPublication from '../admin/modules/offertPublication/PublicationOffert';
import TableSalesEstablishment from '../admin/modules/billing/tables/SalesBranch';
import SalesEstablishmentGenerals from '../admin/modules/billing/tables/SalesEstablishmentGenerals';
import ListEstablishments from '../../pages/admin/modules/registrationEstablishment/tables/ListEstablisments';
import LiquidationSalesEstablishments from '../admin/modules/billing/tables/liquidationSalesEstablishments';
import CategoryProduct from '../admin/modules/offertPublication/Category/CategoryProduct';
import StepRegisterEstablishment from '../admin/modules/registrationEstablishment/StepEstablishment';
import LenddiBlanco from '../../Assets/images/Logos/LenddiBlanco.svg'
import ListBranchs from '../admin/modules/registrationEstablishment/tables/ListBranch';
import SalesBranchsGenerals from '../admin/modules/billing/tables/SalesEstablishment';
import ItemsSidebarIII from '../Components/sidebars/SidebarSuperAdminEstablishment';
import LiquidationSalesBranch from '../admin/modules/billing/tables/liquidationSalesBranchs';
import ListSalesBranch from '../admin/modules/billing/tables/SalesBranch';
import SalesEstablishment from '../admin/modules/billing/tables/SalesEstablishment';

export default function Dashboard() {
  const renderClients = window.localStorage.getItem("setResetClients");
  const [role] = useState(window.localStorage.getItem("role") || null);
  const render = window.localStorage.getItem("setResetPublication");
  const [activeTab, setActiveTab] = useState(
    render ? 6 : (
      role === "administrador" && window.localStorage.getItem("movil") === "true" ? 1 :
        role === "administrador" && window.localStorage.getItem("movil") === "false" ? 1 : 2
    )
  );
  const [messages, setMessage] = useState(null);

  if (render) {
    setTimeout(() => {
      setActiveTab(6)
    }, 500)
  }

  if (renderClients) {
    setTimeout(() => {
      setActiveTab(7)
      window.localStorage.removeItem("setResetClients");
    }, 100)
  }

  useEffect(() => {
    const setModuleTab = (tab) => {
      if (role === "administrador" && window.localStorage.getItem("movil") === "true") {
        switch (tab) {
          case 1:
            setMessage(<Etatistics type="administrador" />);
            break;
          case 2:
            setMessage(<CategoryProduct />);
            break;
          case 3:
            setMessage(<StepRegisterEstablishment />);
            break;
          case 4:
            setMessage(<ListEstablishments />);
            break;
          case 5:
            setMessage(<TabsTableUsers />);
            break;
          case 6:
            setMessage(<QuotaAllocation />);
            break;
          case 7:
            setMessage(<LiquidationSalesEstablishments />);
            break;
          case 8:
            setMessage(<SalesEstablishmentGenerals />);
            break;
          default:
            setMessage(null);
            break;
        }
      }
      else if (role === "administrador" && window.localStorage.getItem("movil") === "false") {
        switch (tab) {
          case 1:
            setMessage(<Etatistics type="administrador" />);
            break;
          case 2:
            setMessage(<Etatistics type="administrador" />);
            break;
          case 4:
            setMessage(<CategoryProduct />);
            break;
          case 5:
            setMessage(<StepRegisterEstablishment />);
            break;
          case 6:
            setMessage(<ListEstablishments />);
            break;
          case 7:
            setMessage(<TabsTableUsers />);
            break;
          case 9:
            setMessage(<QuotaAllocation />);
            break;
          case 11:
            setMessage(<LiquidationSalesEstablishments />);
            break;
          case 12:
            setMessage(<SalesEstablishmentGenerals />);
            break;
          default:
            setMessage(null);
            break;
        }
      }
      else if (role === "administradorEstablecimiento" && window.localStorage.getItem("movil") === "true") {
        switch (tab) {
          case 1:
            setMessage(<Etatistics type="administradorEstablecimiento" />);
            break;
          case 2:
            setMessage(<TabsTableUsers />);
            break;
          case 3:
            setMessage(<OffertPublication />);
            break;
          case 4:
            setMessage(<ListSalesBranch />);
            break;
          default:
            setMessage(null);
            break;
        }
      }
      else if (role === "administradorEstablecimiento" && window.localStorage.getItem("movil") === "false") {
        switch (tab) {
          case 2:
            setMessage(<Etatistics type="administradorEstablecimiento" />);
            break;
          case 4:
            setMessage(<TabsTableUsers />);
            break;
          case 6:
            setMessage(<OffertPublication />);
            break;
          case 8:
            setMessage(<ListSalesBranch />);
            break;
          default:
            setMessage(null);
            break;
        }
      }
      else if (role === "superAdministradorEstablecimiento" && window.localStorage.getItem("movil") === "true") {
        switch (tab) {
          case 1:
            setMessage(<Etatistics type="superAdministradorEstablecimiento" />);
            break;
          case 2:
            setMessage(<ListBranchs />);
            break;
          case 3:
            setMessage(<LiquidationSalesBranch />);
            break;
          case 4:
            setMessage(<SalesEstablishment />);
            break;
          default:
            setMessage(null);
            break;
        }
      }
      else if (role === "superAdministradorEstablecimiento" && window.localStorage.getItem("movil") === "false") {
        switch (tab) {
          case 2:
            setMessage(<Etatistics type="superAdministradorEstablecimiento" />);
            break;
          case 4:
            setMessage(<ListBranchs />);
            break;
          case 6:
            setMessage(<LiquidationSalesBranch />);
            break;
          case 7:
            setMessage(<SalesEstablishment />);
            break;
          default:
            setMessage(null);
            break;
        }
      }
    }
    setModuleTab(activeTab);
    window.localStorage.removeItem("setResetPublication", false);
  }, [activeTab]);

  return (
    <ThemeProvider theme={createTheme}>
      <BoxHome>
        <List sx={{ padding: 0 }} component="nav">
          {role === "administrador" ? (
            <>
              <ItemsSidebar setActiveTab={setActiveTab} />
            </>
          ) : role === "administradorEstablecimiento" ? (
            <>
              <ItemsSidebarII setActiveTab={setActiveTab} />
            </>
          ) : (
            <>
              <ItemsSidebarIII setActiveTab={setActiveTab} />
            </>
          )}
        </List>

        <ContainerBody>
          <HeaderDashboard />
          <BodyDashboard sx={{ backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[900] }}>
            <Grid container item xs={11} sm={11} md={11} lg={11.5} xl={12} className='gridContainerDashboard'>
              <Grid container item xs={1.8} sm={1.8} md={1.8} lg={1.8} xl={1.9} />
              <Grid container item xs={10.2} sm={10.2} md={10.2} lg={10.2} xl={9.9} className='gridBody'>
                {activeTab === 3 && window.localStorage.getItem("movil") === "true" || activeTab === 5 && window.localStorage.getItem("movil") === "false" && (
                  <img src={LenddiBlanco} alt="Lenddi Blanco" style={{ position: 'absolute', top: '50%', left: '57%', transform: 'translate(-50%, -50%)', opacity: '0.3' }} />
                )}
                {messages}
              </Grid>
            </Grid>
          </BodyDashboard>
        </ContainerBody>
      </BoxHome>
    </ThemeProvider>
  );
}
