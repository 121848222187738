import styled from 'styled-components';
import Autocomplete from "@mui/material/Autocomplete";
import Select from '@mui/material/Select';

export const StyleComboBox = styled(Autocomplete)({
  '& label.Mui-focused, & label': {
    color: '#035533;',
  },
  '& .MuiInput-underline:after': { 
    borderBottomColor: '#000000',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#047445',
    },
    '&:hover fieldset': {
      borderColor: '#2e2e2e',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#035533;',
    },
  },
});


export const StyleMultipleSelect= styled(Select)({
  '& label.Mui-focused, & label': {
    color: '#035533;',
  },
  '& .MuiInput-underline:after': { 
    borderBottomColor: '#000000',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#047445',
    },
    '&:hover fieldset': {
      borderColor: '#2e2e2e',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#035533;',
    },
  },
});