import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import CashierRegister from '../RegisterUser';
import ReplayIcon from '@mui/icons-material/Replay';
import { Edit as EditIcon } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { GridHeaderII } from '../../moduleDashboard/Styled';
import ProfileMenu from '../../../../Components/menu/Profile';
import ModalViewDetailsCashiers from '../modals/ViewDetailsCashiers';
import { useAuthContext } from '../../../../../Contexts/AuthContext';
import FormatId from '../../../../../Utilities/FormatIdentification';
import { TittleModule } from '../../registrationEstablishment/Styled';
import { Box, IconButton, Grid, Switch, Tooltip } from '@mui/material';
import UsersRegister from '../RegisterUser';

const TableCashiers = () => {
  const { token } = useAuthContext();
  const [cashiers, setCashiers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCashier, setSelectedCashier] = useState(null);
  const [editingCashiertId, setEditingCashierId] = useState(null);
  const [selectedCashierId, setSelectedCashierId] = useState(null);
  const [showRegistrationComponent, setShowRegistrationComponent] = useState(false);

  const getCashiers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCashiers`, {
        headers: {
          authorization: token,
        },
      });
      setCashiers(response.data);
    } catch (error) {
    }
  };

  const handleUpdateState = async (row) => {
    try {
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/ChangeCashierStatus/${row.original.cashier_id}`, null, config);
      handleReload()
      setCashiers((prevEstablishments) => {
        return prevEstablishments.map((cashier) => {
          if (cashier.status === row.original.status) {
            return { ...cashier };
          } else {
            return cashier;
          }
        });

      }, response.data);
    } catch (error) {
    }
  };

  const handleEditCashier = (idCashier) => {
    setEditingCashierId(idCashier);
    setShowRegistrationComponent(true);
  };

  const handleViewModal = (cashierId) => {
    setSelectedCashierId(cashierId);
    setSelectedCashier(cashierId)
    setShowRegistrationComponent(false);
    setIsModalOpen(true);
  };

  const handleReload = () => {
    getCashiers();
  }

  useEffect(() => {
    const cashierToEdit = cashiers.find(cashier => cashier.cashier_id === editingCashiertId);
    setSelectedCashier(cashierToEdit);
  }, [editingCashiertId, cashiers]);


  useEffect(() => {
    getCashiers();
    handleUpdateState();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'cashier_id',
        header: 'Número de identificación',
        Cell: ({ cell }) => (
          <Box component="span" >
            {<FormatId id={cell.getValue()} />}
          </Box>
        ),
      },

      {
        accessorKey: 'cashier_name',
        header: 'Nombre del cajero',
      },
      {
        accessorKey: 'business_name',
        header: 'Establecimiento',
        size: 0,
      },
      {
        accessorKey: 'status',
        header: 'Estado del cajero',
        Cell: ({ cell, row }) => (
          <Box
            component="span"
            sx={(theme) => ({
              color:
                row.original.status === 'activo'
                  ? theme.palette.success.dark
                  : row.original.status === 'pendiente'
                    ? 'rgb(255, 174, 0)'
                    : theme.palette.error.dark,
              borderRadius: '0.25rem',
              fontWeight: 'bold',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {cell.getValue()}
          </Box>
        ),
      },
      {
        accessorKey: 'actions',
        header: 'Acciones',
        size: 1,
        Cell: ({ row }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px', }}>
            <Tooltip title="Editar">
              <IconButton style={{ color: '#035533' }}>
                <EditIcon onClick={() => handleEditCashier(row.original.cashier_id)} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Ver detalle">
              <IconButton style={{ color: '#035533' }} onClick={() => handleViewModal(row.original.cashier_id)}>
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Switch defaultChecked={row.original.status === "activo"} color={row.original.status === "activo" ? "success" : "error"} onClick={() => { handleUpdateState(row) }} />
          </Box>
        ),
      },
    ],
    []
  );

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
      {showRegistrationComponent ? (
        <UsersRegister cashierId={editingCashiertId} selectedCashier={selectedCashier} />
      ) : (
        <>
          <GridHeaderII container xl={12} mt={-18} ml={1} pb={10}>
            <TittleModule >Lista de cajeros</TittleModule>
            <ProfileMenu />
          </GridHeaderII>
          <MaterialReactTable columns={columns} data={cashiers}
            renderTopToolbarCustomActions={() => (
              <>
                <Tooltip arrow placement="bottom" title="Reload">
                  <IconButton onClick={() => handleReload()}>
                    <ReplayIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            initialState={{
              density: 'compact'
            }}
            positionActionsColumn="last"
          />
        </>
      )}
      {selectedCashierId !== null && (
        <ModalViewDetailsCashiers isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedCashierId={selectedCashierId} />
      )}
    </Grid>
  );
};

export default TableCashiers;