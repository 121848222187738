import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { Box, Tab, Typography } from "@mui/material";
import { Iphone12ProMediaQuery, Iphone14ProMediaQuery, Iphone678Plus } from './MediaQueries';

const drawerWidth = 300;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  position: "absolute",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Sidebar2 = styled(Box)`
  position: relative;
  white-space: nowrap;
  background-color: #035533;
  height: 100vh;
`;

export const drawerStyles = {
  position: "absolute",
  height: "100%",
};

export const BoxSidebar = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 14.5%;
  background-color: #035533;
  position: fixed;
  z-index: 1000;

  .orientationTab {
    align-items: flex-start;
  }

  .typographyTab {
    margin-left: 18px;
    color: white;
    font-size: 12px;
    margin-top: 15%;

    /* Dimensiones 100% */
    @media (max-width: 1526px) and (max-height: 836px) {
      font-size: 11px;
    }
    @media (max-width: 1239.33px) and (max-height: 571.66px) {
      margin-top: 5%;
    }
  }

  .typographyItem {
    /* Dimensiones 100% */
    @media (max-width: 1526px) and (max-height: 836px) {
      font-size: 11px;
    }

    @media (max-width: 1356px) and (max-height: 836px) {
      font-size: 10px;
    }

    @media (max-width: 1274px) and (max-height: 836px) {
      font-size: 8px;
    }

    /* Dimensiones 125% */
    @media (max-width: 1360px) and (max-height: 620px) {
      font-size: 10px;
    }
    @media (max-width: 1280px) and (max-height: 620px) {
      font-size: 9px;
    }
    @media (max-width: 1190px) and (max-height: 620px) {
      font-size: 8px;
    }
    @media (max-width: 1106px) and (max-height: 620px) {
      font-size: 7px;
    }

    /* Dimensiones 150% */
    @media (max-width: 1190px) and (max-height: 477px) {
      font-size: 7px;
    }
    @media (max-width: 1107px) and (max-height: 477px) {
      font-size: 7px;
    }
  }

  /* Dimensiones 150% */
  @media (max-width: 1196px) and (max-height: 477px) {
    height: 125vh;
  }
`;

export const TabItem = styled(Tab)`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  /* Dimensiones 150% */
  @media (max-width: 900px) and (max-height: 881px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 600px) and (min-height: 881px) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  @media (max-width: 400px) and (min-height: 881px) {
    display: flex;
    justify-content: start !important;
    align-items: flex-start !important;
    margin-left: -10px !important;
  }

  ${Iphone12ProMediaQuery}
  ${Iphone14ProMediaQuery}
  ${Iphone678Plus}

`;

export const Item = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TextItem = styled(Typography)`
  margin-left: 18px;
  font-size: 13px;
  color: white;
`;
