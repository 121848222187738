import { CardContent, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import styled from "styled-components";

export const GridCard = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const GridCardTable = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CardPublication = styled(Card)`
  width: 49.2%;
  height: 32vh;
  :hover {
    background-color: white;
  }


  @media (max-width: 1451px) {
    width: 45% ;
  }

  @media (max-width: 1310px){
    width: 100%;
  }

  @media (max-width: 840px){
    width: 90%;
  }
  @media (max-width: 599px){
    width: 65%;
  }
  @media (max-width: 450px){
    width: 50%;
  }


  /* Dimensiones 125% */
  @media (max-width: 1452px) and (max-height: 620px),
  (max-width: 1400px) and (max-height: 620px),
  (max-width: 740px) and (max-height: 620px){
    width: 100%;
    height: 22%;
  }

  /* Dimensiones 150% */
  @media (max-width: 1196px) and (max-height: 477px),
  (max-width: 899px) and (max-height: 477px),
  (max-width: 715px) and (max-height: 556px) {
    width: 100%;
    height: 20%;
  }
`;

export const ContainerCard = styled(CardContent)`
  display: flex;
  justify-content: center;
  height: 85%;

  .IconButton {
    width: 650px;
    border-radius: 0px;
  }

  .Icon {
    font-size: 40px;
  }
`;

