import React from "react";
import { DivLogo } from "./Styled.jsx";
import lenddin from '../../../Assets/images/Logos/Lenddi.svg'

const LogoAnimated = () => {
  return (
    <div style={{ width: "100%" }}>
      <DivLogo>
        <img src={lenddin} alt="loading" className="loading zoom" />
        <span className="loader">  </span>
      </DivLogo>
    </div>
  );
};

export default LogoAnimated;
