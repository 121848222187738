import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { jwtDecode } from 'jwt-decode';

import { MainDiv } from '../forms/Styled';
import ModalInvoice from '../modal/invoice';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Button, Grid, TextField } from "@mui/material";
import FormatPrice from '../../../Utilities/FormatPrices';
import { useAuthContext } from '../../../Contexts/AuthContext';
import Button360 from '../../../components/elements/Buttons/Button360';
import TypographyH3 from '../../../components/elements/labels/TypographyH3';
import TypographyH5 from '../../../components/elements/labels/TypographyH5';
import TypographyH6II from '../../../components/elements/labels/TypographyH6II';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Button360Outlined from '../../../components/elements/Buttons/Button360Outlined';
import { BodyHeaderVoucher, BodyVoucher, ConfirmationVoucher, ContainerBodyVoucher, ContainerHeaderVoucher, DivTransactions, Voucher } from './Styled';

export default function VoucherPurchase({ compra, idComprae}) {
  const [Buy, setBuy] = useState({});
  const [/* compras */, setCompras] = useState([]);
  const { token, logout, role } = useAuthContext();
  const [editedValue, setEditedValue] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmedValue, setConfirmedValue] = useState(null);
  const [isEditingValue, setIsEditingValue] = useState(false);
  const [/* statusPurchase */, setStatusPurchase] = useState({});
  const [selectedPurchaseId, SetselectedPurchaseId] = useState(null);
  const decoded = jwtDecode(token);

  
  const fetchListBuys = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getPurchaseByCashier/${decoded.id_user}`, {
        headers: {
          authorization: token,
        },
      });
      if (response.data.length > 0) {
        const sortedCompras = response.data.sort((a, b) => new Date(b.purchase_date) - new Date(a.purchase_date));
        setCompras(sortedCompras);
      }
    } catch (error) {
      if (error.response.status === 401 && role !== 'cliente') {
        logout()
      }
    }
  };

  const fetchBuy = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getOnepurchase/${idComprae}`, {
        headers: {
          authorization: token,
        },
      });
      setBuy(response.data)
    } catch (error) {
    }
  };

  const handleViewModal = async (idCompra, confirm) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/confirmPurchase/${idCompra}`,
        { confirm: confirm },
        {
          headers: {
            authorization: token,
          }
        });

      if (response.data === "compra confirmada") {
        setStatusPurchase(response.data)
        SetselectedPurchaseId(idCompra)
        setIsModalOpen(true)
      } else {
        Swal.fire(
          '¡Compra cancelada!',
          'Se ha cancelado su compra de manera exitosa!',
          'info'
        );
        window.location.reload()
      }
    } catch (error) {}
  }

  const handleUpdateVoucher = async (idCompra) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/updatePurchaseValues/${idCompra}`,
        { "value": isEditingValue ? editedValue : Buy.value},
        {
          headers: {
            authorization: token,
          }
        });
        setConfirmedValue(isEditingValue ? editedValue : Buy.value);
      Swal.fire(
        '¡Cambios confirmados!',
        'Se actualizaron los campos exitosamente!',
        'success'
      );

    } catch (error) {
      Swal.fire(
        '¡Error!',
        'Se ha un error al intentar hacer los cambios!',
        'error'
      );
    }

  }

  useEffect(() => {
    if (idComprae) {
      fetchBuy();
    }
    fetchListBuys();
  }, [idComprae]);

  return (
    <MainDiv>
       <ContainerBodyVoucher style={{ width: compra === "compra" ? "100%" : "100%" }}>
        <Grid item className='containerGrid' xs={12} sm={12} md={12} lg={12} xl={12} >
          {compra === "compra" && (
            <DivTransactions>
              <ContainerHeaderVoucher>
                <BodyHeaderVoucher>
                <Grid item className='containerButton' xs={0.5} sm={0.5} md={0.5} lg={0.5} xl={0.5}>
                    <Grid mt={2}>
                      <Button startIcon={<ArrowBackIosNewRoundedIcon className='icon' />} onClick={() => window.location.reload()} />
                    </Grid>
                  </Grid>
                  <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} justifyContent={'center'} mb={6} mr={3}>
                    <ConfirmationVoucher container mt={4}>
                      <TypographyH5 text="Valor a pagar" fontColor="white" />
                      <TypographyH3 text={<FormatPrice price={confirmedValue !== null ? confirmedValue : Buy.value} />} fontColor="white" />
                    </ConfirmationVoucher>
                  </Grid>
                </BodyHeaderVoucher>
              </ContainerHeaderVoucher>
              <Grid container className='gridDetailsVoucher' mt={2} >
                <Voucher item xs={12} sm={12} md={12} lg={12} xl={12} >
                  <BodyVoucher container>
                    <Grid item xs={4} sm={3.6} md={3.6} lg={3.6} xl={3.6} className='containerTittle'>
                      <TypographyH6II text="Almacen:" fontColor="#035533"  fontWeight="600" />
                      <TypographyH6II text="Fecha:" fontColor="#035533"  fontWeight="600" />
                      <TypographyH6II text="Hora:" fontColor="#035533"  fontWeight="600" />
                      <TypographyH6II text="C.C:" fontColor="#035533"  fontWeight="600" />
                      <TypographyH6II text="Nombre:" fontColor="#035533"  fontWeight="600" />
                      <TypographyH6II text="Total del credito:" fontColor="#035533" mt={2} fontWeight="600" />
                    </Grid>
                    <Grid item xs={8} sm={8.4} md={8.4} lg={8.4} xl={8.4} className='containerValue'>
                      <TypographyH6II text={Buy.establishment_NIT} fontColor="gray"/>
                      <TypographyH6II text={Buy.purchase_date} fontColor="gray"/>
                      <TypographyH6II text={Buy.purchase_time} fontColor="gray"/>
                      <TypographyH6II text={Buy.customer_id} fontColor="gray"/>
                      <TypographyH6II text={Buy.customer_name2} fontColor="gray"/>

                      <Grid item xs={12} sm={10} md={8} lg={8} xl={8} sx={{ display: "flex", flexDirection: "row" }} mt={1}>
                        <IconButton onClick={() => setIsEditingValue(!isEditingValue)} fontSize="5px">
                          <EditIcon fontSize="5px" />
                        </IconButton>
                        <TextField id="standard-basic" type='number' label={<FormatPrice price={confirmedValue !== null ? confirmedValue : Buy.value} />} variant="standard" disabled={!isEditingValue} onChange={(e) => setEditedValue(e.target.value)} value={editedValue}
                          InputProps={{ style: { direction: 'rtl', paddingRight: '12px' } }} />
                      </Grid>
      
                      <Button sx={{ color: "#035533", marginTop: "2rem" }} onClick={() => handleUpdateVoucher(Buy.purchase_id)}>Confirmar cambios</Button>
                    </Grid>
                  </BodyVoucher>
                </Voucher>

                <Grid className="grid-button" item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                  <Grid mt={2}>
                    <Button360 onClick={() => handleViewModal(Buy.purchase_id, true)} label="Confirmar compra" />
                  </Grid>
                  <Grid mt={2}>
                    <Button360Outlined onClick={() => handleViewModal(Buy.purchase_id, false)} label="Cancelar" />
                  </Grid>
                </Grid>
              </Grid>

              {selectedPurchaseId !== null && (
                <ModalInvoice isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} selectedPurchaseId={selectedPurchaseId} buy={true}/>
              )}
            </DivTransactions>
          )}
        </Grid>
      </ContainerBodyVoucher>
    </MainDiv>
  )
}