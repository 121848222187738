import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';
import { useAuthContext } from '../../../Contexts/AuthContext';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DiagramPieEstablishment() {
  const [resultsMonthlyIncome, setResultsMonthlyIncome] = useState([])
  const { token } = useAuthContext();

  const monthlyIncome = resultsMonthlyIncome.map(item => item.total_compras);

  const tokenDec = localStorage.getItem('token');
  const decodedToken = jwtDecode(tokenDec);

  const ResultsSales = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/totalValuePurchaseBranchByMonth/${decodedToken.NIT}`, {
        headers: {
          authorization: token,
        },
      }
      );
      setResultsMonthlyIncome(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    ResultsSales()
  }, [])

  var options = {
    responsive: true,
    maintainAspectRatio: false,
  };

  var data = {
    labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    datasets: [
      {
        label: 'Ingreso total del mes',
        data: monthlyIncome,
        backgroundColor: [
          '#0e7a4d',
          '#32faa5',
          '#38baf2',
        ],
      },
    ],
  };

  return <Doughnut data={data} options={options} style={{ width: "95%", height: "50vh", textAlign: "center" }} />
}

