import { useEffect } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import AuthContextProvider from "./Contexts/AuthContext";

import { PUBLIC } from "./router/routes/path";
import { HOME } from "./router/routes/path";
import { STORE } from "./router/routes/path";
import AdminRoute from "./router/rout/AdminRoute";
import ClientRoute from "./router/rout/ClientRoute";
import PublicRoute from "./router/rout/PublicRoute";
import CashierRoute from "./router/rout/CashierRoute";
import { LOGOUT1, LOGOUT2, CLIENT, LOGINCLIENT, LOGOUTCLIENT } from "./router/routes/path";

import Dashboard from "./pages/home/Home";
import { Store } from "./pages/store/Store";
import { Logins } from "./pages/Login/login";
import { Client } from "./pages/client/Client";
import NotFound404 from "./pages/notFound/NotFound";
import { Logout1, Logout2 } from "./pages/Login/logout";
import ListTransaccions from "./pages/store/Transactions/ListTransactions";
import PurchaseCustomer from "./pages/client/forms/PurchaseCustomer";
import CategoriesEstablishment from "./pages/client/forms/CategoriesEstablishment";
import SubcategoriesEstablishment from "./pages/client/forms/SubcategoriesEstablishment";
import EstablishmentXSubcategories from "./pages/client/forms/EstablishmentXSubcategories";
import EstablishmentPromo from "./pages/client/forms/EstablishmentPromo";
import EstablishmentList from "./pages/client/forms/EstablishmentList";


function App() {
  useEffect(() => {
    document.title = "Lenddi";
  }, []);

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Routes>
          {/* Rutas Públicas */}
            <Route path={PUBLIC} element={<PublicRoute />}>
            <Route path="/" element={<Logins />} />
            <Route path={LOGINCLIENT} element={<Client />} />
           
          </Route>

          {/* Rutas del Establecimiento */}
          <Route path={STORE} element={<CashierRoute />}>
            <Route index element={<Store />} />
            <Route path="listTransactions" element={<ListTransaccions />} />
            <Route path={LOGOUT1} element={<Logout1 />} />
          </Route>

          {/* Rutas del Administrador */}
          <Route path={HOME} element={<AdminRoute />}>
            <Route index element={<Dashboard />} />
            <Route path={LOGOUT2} element={<Logout1 />} />
          </Route>

          {/* Rutas del Cliente */}
          <Route path={CLIENT} element={<ClientRoute />}>
            <Route index element={<PurchaseCustomer />} />
            <Route path={`creditList`} element={<ListTransaccions />} />
            <Route path={`establishmentList`} element={<EstablishmentList />} />
            <Route path={`categories/:id`} element={<CategoriesEstablishment />} />
            <Route path={`subcategories/:id`} element={<SubcategoriesEstablishment />} />
            <Route path={`establishmentXsubcategories/:id`} element={<EstablishmentXSubcategories />} />
            <Route path={`promotions/:id`} element={<EstablishmentPromo />} />
            <Route path={LOGOUTCLIENT} element={<Logout2 />} />
          </Route>

          {/* Ruta 404 */}
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
