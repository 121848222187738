import Swal from 'sweetalert2';
import axios from 'axios';
import { DivModal, ModalView } from './Styled';
import { Typography, Modal, Grid, Box } from '@mui/material';
import { LineDivider } from '../../../../components/elements/lines/Styled';
import ButtonActiosn360 from '../../../../components/elements/Buttons/ButtonActions360';
import FormatPrice from '../../../../Utilities/FormatPrices';

import { PDFViewer, PDFDownloadLink, } from "@react-pdf/renderer";
import PdfRequestWithdrawal from './pdf/RequestWithdrawalPdf';
import { useState } from 'react';

const ModalInfoWithdrawal = ({ selectedWithdrawalId, isModalOpenWithdrawal, setIsModalOpenWithdrawal }) => {
  const [requestWithdrawal] = useState(selectedWithdrawalId)

  const confirmWithdrawal = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/confirmWithdrawal/${requestWithdrawal.withdrawal_id}`, {
        confirm: true
      });
      handleCloseModal();
      Swal.fire(
        '¡Bien hecho!',
        '¡Desembolso confirmado exitosamente!',
        'success'
      );
      window.location.reload();
    } catch (error) {
      if (error.response.data === 'su credito no se encuentra activo.') {
        Swal.fire({
          icon: 'error',
          title: 'Ups...',
          text: 'El cupo se encuentra inactivo, consulte con el area encargada el motivo.',
          confirmButtonColor: '#035533',
        });
      }
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'Hubo un error al actualizar la información. No hay datos disponibles.',
        confirmButtonColor: '#035533',
      });
    }
  };

  const confirmDisbursement = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/confirmWithdrawalAndDeduct/${requestWithdrawal.withdrawal_id}`, {
        confirm: true
      });
      handleCloseModal();
      Swal.fire(
        '¡Bien hecho!',
        '¡Desembolso confirmado exitosamente!',
        'success'
      );
      window.location.reload();
    } catch (error) {
      if (error.response.data === 'su credito no se encuentra activo.') {
        Swal.fire({
          icon: 'error',
          title: 'Ups...',
          text: 'El cupo se encuentra inactivo, consulte con el area encargada el motivo.',
          confirmButtonColor: '#035533',
        });
      }
      Swal.fire({
        icon: 'error',
        title: 'Ups...',
        text: 'Hubo un error al actualizar la información. No hay datos disponibles.',
        confirmButtonColor: '#035533',
      });
    }
  };

  const declineWithdrawal = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/confirmWithdrawalAndDeduct/${requestWithdrawal.withdrawal_id}`, {
        confirm: false
      });
      handleCloseModal();
      Swal.fire(
        '¡Bien hecho!',
        '¡Desembolso rechado exitosamente!',
        'info'
      );
      window.location.reload();
    } catch (error) {
      if (error.response.data === 'su credito no se encuentra activo.') {
        Swal.fire({
          icon: 'error',
          title: 'Ups...',
          text: 'El cupo se encuentra inactivo, consulte con el area encargada el motivo.',
          confirmButtonColor: '#035533',
        });
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpenWithdrawal(false);
  };

  return (
    <Modal open={isModalOpenWithdrawal} onClose={handleCloseModal}>
      <ModalView className="ModalStyle">
        <DivModal>
          <Grid item className='GridHeader'>
            <Typography className='TypographyHeader'>Información de desembolso</Typography>
            <LineDivider color="#035533" width="98%" />
          </Grid>
          <Box component="form" noValidate onSubmit={(e) => { e.preventDefault() }}>
            <Grid container item className='bodyCard' xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5}>
              <Typography variant="body1" sx={{ color: '#035533' }}>
                <strong>Solicitud #{requestWithdrawal.withdrawal_id}: </strong> El usuario <strong>{requestWithdrawal.customer_name}</strong> ha solicitado un desembolso del crédito <strong>#{requestWithdrawal.id_assignment}</strong> por el monto de <strong><FormatPrice price={requestWithdrawal.withdrawal_value} /></strong>
              </Typography>
            </Grid>
          </Box>

          <Box sx={{ width: "97%", height: 'calc(60vh - 50px)', display: "flex", flexDirection: "column", padding: "10px" }}>
            <PDFViewer style={{ height: "100%" }}>
              <PdfRequestWithdrawal requestWithdrawal={requestWithdrawal} />
            </PDFViewer>
          </Box>
          {requestWithdrawal.status === ('pendiente') && (
            <Grid container item className='bodyCard' xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} mt={5}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={4}>
                <ButtonActiosn360 label="Rechazar solicitud" onClick={declineWithdrawal} backgroundColor={"red"} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={2}>
                <PDFDownloadLink document={<PdfRequestWithdrawal requestWithdrawal={requestWithdrawal} />} fileName={`Solicitud #${requestWithdrawal.withdrawal_id} confirmada.pdf`}>

                  {({ loading }) =>
                    loading ? (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={2}>
                        <ButtonActiosn360 label="Cargando documento" backgroundColor={"#035533"} />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={2}>
                        <ButtonActiosn360 label="Confirmar solicitud" onClick={confirmWithdrawal} backgroundColor={"#035533"} />
                      </Grid>
                    )
                  }

                </PDFDownloadLink>
              </Grid>
            </Grid>
          )}
          {requestWithdrawal.status === ('pendiente por desembolso') && (
            <Grid container item className='bodyCard' xs={11.5} sm={11.5} md={11.5} lg={11.5} xl={11.5} mt={5}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={4}>
                <ButtonActiosn360 label="Rechazar solicitud" onClick={declineWithdrawal} backgroundColor={"red"} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mb={2}>
                <PDFDownloadLink document={<PdfRequestWithdrawal requestWithdrawal={requestWithdrawal} />} fileName={`Solicitud #${requestWithdrawal.withdrawal_id} desembolsada.pdf`}>

                  {({ loading }) =>
                    loading ? (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={2}>
                        <ButtonActiosn360 label="Cargando documento" backgroundColor={"#035533"} />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mb={2}>
                        <ButtonActiosn360 label="Confirmar desembolso" onClick={confirmDisbursement} backgroundColor={"#035533"} />
                      </Grid>
                    )
                  }
                </PDFDownloadLink>
              </Grid>
            </Grid>
          )}
        </DivModal>
      </ModalView>
    </Modal>
  );
}

export default ModalInfoWithdrawal;