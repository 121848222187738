import React from "react";
import { Link } from "react-router-dom";

import { ContainerBody, MainDiv } from "../store/forms/Styled";
import notFound from '../../Assets/images/fondos svg/notFound.svg'
import ButtonActiosn360 from "../../components/elements/Buttons/ButtonActions360";

export default function NotFound404() {
  return (
    <MainDiv>
      <ContainerBody>
        <img src={notFound} alt="" />
        <Link to={'/'}>
          <ButtonActiosn360 backgroundColor={'#035533;'} label={'Volver al inicio'}/>
        </Link>
      </ContainerBody>
    </MainDiv>
  );
};
