import styled from "styled-components";

export const DivLogo = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: white;

  .loading {
    width: 300px;
    margin-top: 2rem;
  }

  .loading.zoom {
    animation: zoom 3s infinite alternate;
  }

  @keyframes zoom {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }

  .loader {
    width: 86px;
    height: 50px;
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50px;
    background: radial-gradient(
          farthest-side,
          #0000 calc(100% - 10px),
          #fff calc(100% - 10px) 100%,
          #0000
        )
        left,
      radial-gradient(
          farthest-side,
          #0000 calc(100% - 10px),
          #fff calc(100% - 10px) 100%,
          #0000
        )
        right;
    background-size: calc(50% + 5px) 100%;
    background-repeat: no-repeat;
    position: relative;
    animation: flipX 1s infinite linear;
  }
  .loader:before {
    content: "";
    position: absolute;
    inset: 0;
    margin: auto;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #035533;
    transform-origin: -14px 50%;
    animation: rotate 0.5s infinite linear;
  }

  @keyframes flipX {
    0%,
    49.99% {
      transform: scaleX(1);
    }
    50%,
    100% {
      transform: scaleX(-1);
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;
