import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';

export default function SelectCity() {
  const [city, setCity] = useState([]);
  const [citySelected, setCitySelected] = useState('');

  const getCitiesEstablishment = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCitys`);
      if (response.data) {
        setCity(response.data);
      } else {
        setCity([]);
      }
    } catch (error) {
    }
  };

  const handleLocalChange = (event) => {
    const selectedCity = event.target.value;
    setCitySelected(selectedCity); 
    if (selectedCity !== '') {
      localStorage.setItem('ciudad', selectedCity); 
    } else {
      localStorage.removeItem('ciudad'); 
    }
  };

  useEffect(() => {
    getCitiesEstablishment();
    const localStorageCity = localStorage.getItem('ciudad');
    if (localStorageCity !== null) {
      setCitySelected(localStorageCity);
    }
  }, []);

  const citiesWithNoneOption = ['Todas', ...city];

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Ciudad</InputLabel>
      <Select value={citySelected} onChange={handleLocalChange} label="Ciudad" labelId="demo-select-small-label" id="demo-select-small">
        {citiesWithNoneOption.map((cityItem, index) => (
          <MenuItem key={index} value={cityItem.city || ''}>
            {cityItem.city || 'Todas'}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
