import React from "react";
import { StyledButtonActiosn360 } from "./Styled.jsx";

const ButtonActiosn360 = ({ label, onClick, backgroundColor, startIcon, disabled, }) => {
  return (
    <>
      {disabled ? (
        <StyledButtonActiosn360 disabled sx={{ backgroundColor: backgroundColor, color: "#fff !important" }}>{label}</StyledButtonActiosn360>
      ) : (
        <StyledButtonActiosn360 variant="contained" onClick={onClick} sx={{ backgroundColor: backgroundColor }} startIcon={startIcon} >
          {label}
        </StyledButtonActiosn360>
      )}
    </>
  );
};

export default ButtonActiosn360;
