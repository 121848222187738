import React from "react";
import { StyledInputText } from "./Styled";

const InputText = ({ label, value, onChange, multiLine }) => {
  return (
    <>
      {multiLine === 'disabled' ? (
        <StyledInputText id="outlined-basic" variant="outlined" label={label} value={value} onChange={onChange} />
      ) : (
        <StyledInputText id="outlined-basic" multiline variant="outlined" label={label} value={value} onChange={onChange} />
      )}
    </>
  );
};

export default InputText;
