import React, { useState, useEffect } from "react";
import axios from "axios";

import TextField from "@mui/material/TextField";
import { StyleComboBox } from "./StyleComboBox";
import { useAuthContext } from "../../../Contexts/AuthContext";

const departamentosColombia = [
  { label: "Amazonas" },
  { label: "Antioquia" },
  { label: "Arauca" },
  { label: "Atlántico" },
  { label: "Bolívar" },
  { label: "Boyacá" },
  { label: "Caldas" },
  { label: "Caquetá" },
  { label: "Casanare" },
  { label: "Cauca" },
  { label: "Cesar" },
  { label: "Chocó" },
  { label: "Córdoba" },
  { label: "Cundinamarca" },
  { label: "Guainía" },
  { label: "Guaviare" },
  { label: "Huila" },
  { label: "La Guajira" },
  { label: "Magdalena" },
  { label: "Meta" },
  { label: "Nariño" },
  { label: "Norte de Santander" },
  { label: "Putumayo" },
  { label: "Quindío" },
  { label: "Risaralda" },
  { label: "San Andrés y Providencia" },
  { label: "Santander" },
  { label: "Sucre" },
  { label: "Tolima" },
  { label: "Valle del Cauca" },
  { label: "Vaupés" },
  { label: "Vichada" },
];

const tiposDocumentos = [
  { label: "Cédula de Ciudadanía" },
  { label: "Tarjeta de Identidad" },
  { label: "Pasaporte" },
  { label: "Cédula de Extranjería" },
  { label: "Registro Civil" },
  { label: "Licencia de Conducir" },
  { label: "Tarjeta de Residencia" },
  { label: "Documento Nacional de Identidad (DNI)" },
  { label: "Pasaporte Diplomático" },
  { label: "Carné de Estudiante" },
  { label: "Carné de Trabajo" },
  { label: "Certificado de Nacimiento" },
  { label: "Certificado de Matrimonio" },
  { label: "Tarjeta Sanitaria" },
  { label: "Carné de Biblioteca" },
  { label: "Certificado de Antecedentes Penales" },
  { label: "Visa de Turista" },
  { label: "Visa de Trabajo" },
  { label: "Carné de Afiliación a Seguro Médico" },
  { label: "Carné de Socio de Club" },
];

const roles = [
  { label: "Administrador de establecimiento" },
  { label: "Administrador de sucursal" },
  { label: "Cajero" },
];

const bancos = [
  "Bancolombia",
  "Davivienda",
  "Banco de Bogotá",
  "BBVA Colombia",
  "Banco Popular",
  "Banco AV Villas",
  "Banco de Occidente",
  "Citibank Colombia",
  "Banco GNB Sudameris",
  "Banco Caja Social",
  "Nequi",
  "Daviplata",
];

const tipoCuenta = ["Ahorro", "Corriente"];

export default function ComboBox360({ label, selectedValue, setSelectedValue, setSelectedEstablishment, disabled, departamento, type }) {
  const [establishment, setEstablishment] = useState([])
  const [categories, setCategories] = useState([])
  const [city, setCity] = useState([])
  const [/* error */, setError] = useState('')
  const { token, role } = useAuthContext();

  
  const getEstablishments = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getEstablishments/${type}`, {
        headers: {
          authorization: token,
        },
      });
      setEstablishment(response.data);
    } catch (error) {
      setError(error);
    }
  };

  const getEstablishmentsAndBranch = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getEstablishmentForSelect`, {
        headers: {
          authorization: token,
        },
      });
      setEstablishment(response.data);
    } catch (error) {
      setError(error);
    }
  };

  const getCities = async (departament) => {
    const departamentoString = departament && typeof departament === 'object' ? departament.label : departament;
    if (departamentoString) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/APIColombia2/${departamentoString}`);
        if (response.data) {
          setCity(response.data);
        } else {
          setCity([]);
        }
      } catch (error) { }
    } else {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/APIColombia2`);
        if (response.data) {
          setCity(response.data);
        } else {
          setCity([]);
        }
      } catch (error) {
      }
    }
  };

  const getCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/getCategories/${role}`, {
        headers: {
          authorization: token,
        },
      });
      setCategories(response.data);
    } catch (error) { }
  };

  useEffect(() => {
    if (label === "Establecimiento") {
      getEstablishments(type);
    }
    if (label === "Sucursal") {
      getEstablishments(type);
    }
    if (label === "Establecimiento o sucursal") {
      getEstablishmentsAndBranch();
    }
    if (departamento) {
      getCities(departamento);
    }
    if (label === "Categorias") {
      getCategories();
    }
  }, [departamento, label]);

  const optionsToDisplay =
    label === "Departamento"
      ? departamentosColombia
      : label === "Ciudad"
        ? city || []
        : label === "Rol"
          ? roles
          : label === "Establecimiento" || label === "Establecimiento o sucursal" || label === "Sucursal"
            ? establishment.map((est) => ({ label: `${est.business_name}` })) || []
            : label === "Banco"
              ? bancos
              : label === "Tipo de cuenta"
                ? tipoCuenta
                : label === "Categorias"
                  ? categories.map((cat) => ({ label: `${cat.category_name}` })) || []
                  : tiposDocumentos;

  return (
    <StyleComboBox
      disablePortal
      size="small"
      id="combo-box-demo"
      options={optionsToDisplay}
      disabled={disabled}
      value={selectedValue}
      onChange={(event, newValue) => {
        let selectedNIT = null;
        let selectedCategoryID = null;
        if (newValue !== null) {
          if (label === "Establecimiento") {
            const selectedEst = establishment.find((est) => est.business_name === newValue.label);
            selectedNIT = selectedEst ? selectedEst.NIT : "";
            setSelectedValue(newValue ? selectedNIT : null);
            if (typeof setSelectedEstablishment === 'function') {
              setSelectedEstablishment(selectedEst);
            }
          } else if (label === "Sucursal" || label === "Establecimiento o sucursal") {
            const selectedEst = establishment.find((est) => est.business_name === newValue.label);
            selectedNIT = selectedEst ? selectedEst.establishment_id : "";
            setSelectedValue(newValue ? selectedNIT : null);
            if (typeof setSelectedEstablishment === 'function') {
              setSelectedEstablishment(selectedEst);
            }

          } else if (label === "Categorias") {
            const selectedCategory = categories.find((cat) => cat.category_name === newValue.label);
            selectedCategoryID = selectedCategory ? selectedCategory.category_id : "";
            setSelectedValue(newValue ? selectedCategoryID : null);
          } else {
            setSelectedValue(newValue ? newValue : null);
          }
        } else {
          setSelectedValue('');
        }
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}