import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

import { Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { GridHeaderII } from '../moduleDashboard/Styled';
import ProfileMenu from '../../../Components/menu/Profile';
import { useAuthContext } from '../../../../Contexts/AuthContext';
import { TittleModule } from '../registrationEstablishment/Styled';
import { GridCard, CardPublication, ContainerCard } from './Styled';
import CardOffertPublication from '../../../Components/card/CardPublication';

export default function OffertPublication() {
  const tokenDec = localStorage.getItem('token');
  const decodedToken = jwtDecode(tokenDec);
  const [cards, setCards] = useState([]);
  const { token } = useAuthContext();
  const maxCards = 10;

  const createNewCard = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/createPromotion`, {
        establishment_id: decodedToken.establishment_id,
      }, {
        headers: {
          authorization: token,
        },
      }
      );

      window.localStorage.setItem("setResetPublication", true);
      window.location.reload()
      setTimeout(() => {
        window.localStorage.removeItem("setResetPublication", false);
      }, 2000)
      setCards((prevCards) => [...prevCards, response.data]);
      setCards(response.data[0].CardData)
    } catch (error) {
    }
  };

  const getPromotionEstablishment = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/GetPromotionByEstablishmentNIT/${decodedToken.establishment_id}`, {
        headers: {
          "authorization": token,
        },
      })
      setCards(response.data[0].CardData)
    } catch (error) { }
  }

  useEffect(() => {
    getPromotionEstablishment()
  }, [])

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <GridHeaderII container item xl={12} mt={-7} mb={2}>
        <TittleModule >Registro de publicidad</TittleModule>
        <ProfileMenu />
      </GridHeaderII>
      <GridCard >

        {cards.map((card, index) => (
          <CardOffertPublication key={index} idPubli={index} card={card} disabled={card.status === "guardada" ? true : false} />
        ))}

        <CardPublication>
          <ContainerCard>
            {cards.length < maxCards && (
              <IconButton className='IconButton' onClick={createNewCard}>
                <AddIcon className='Icon' />
              </IconButton>
            )}
          </ContainerCard>
        </CardPublication>
      </GridCard>
    </Grid>

  );
}
