import styled from "styled-components";
import { CardContent, Box } from "@mui/material";

export const ModalEdit = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 42%;
  height: auto;
  padding-bottom: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 2% 2% 0% 2%;
  }
  /* Dimensiones 125% */
  @media (max-width: 1452px){
    width: 70%;
  }
  @media (max-width: 899px){
    width: 70%;
  }

`;

export const ModalActions = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: auto;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 2% 2% 0% 2%;
  }


  @media (max-width: 1200px){
    width: 60%;
  }

  @media (max-width: 900px){
    width: 70%;
  }

  @media (max-width: 600px){
    width: 80%;
  }
`;

export const BodyCard = styled(CardContent)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2%;

  .Grid {
    border: 1px solid gray;
    border-radius: 0.3rem;
    width: 22%;
    display: flex;
    justify-content: center;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .containerFormPublication{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .containerLabel {
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon {
    color: #6b6b6b;
    font-size: 50px;
  }

  .img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }


  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: 4%;
  }
`;