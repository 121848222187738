import styled from "styled-components";
import { Box, Card } from "@mui/material";

export const ModalView = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: auto;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 1% 2% 0% 2%;
  }

  .bodyCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1% 0% 1.5% 2%;
  }

  .blockItem{
    display: flex;
  }

  .containerItem{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 1200px){
    width: 70%;
  }
  @media (max-width: 900px){
    width: 80%;
  }
  @media (max-width: 600px){
    width: 90%;
  }

`;

export const ModalView2 = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: auto;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 24;
  padding: 4;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 1% 2% 0% 2%;
  }

  .bodyCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1% 0% 1.5% 2%;
  }

  .blockItem{
    display: flex;
  }

  .containerItem{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 1305px) and (max-height: 931px),
    (max-width: 1200px) and (max-height: 931px) {
    width: 70%;
  }

  @media (max-width: 900px) and (max-height: 931px),
    (max-width: 414) and (max-height: 931px) {
    width: 70%;
    height: auto;
  }
`;

export const DivModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 1.5% 2% 0% 1%;
  }

  .bodyCard {
    display: flex;
    align-items: center;
  }


`

export const CardBody = styled(Card)`
  margin-bottom: 2%;
  height: 28vh;
  width: 100%;

  .GridHeader {
    display: flex;
    flex-direction: column;
  }

  .TypographyHeader {
    color: #035533;
    font-weight: 550;
    margin: 0.5% 2% 0% 1%;
  }

  .bodyCard {
    display: flex;
    align-items: center;
  }

  /* Dimensiones 100% */
  @media (max-width: 1535px) and (max-height: 931px),
    (max-width: 1200px) and (max-height: 931px) {
    width: 100%;
    height: 30vh;
  }
  @media (max-width: 899px) and (max-height: 931px) {
    width: 100%;
    height: 41vh;
  }
  @media (max-width: 730px) and (max-height: 931px) {
    width: 100%;
    height: 50vh;
  }
  @media (max-width: 599px) and (max-height: 931px) {
    width: 100%;
    height: 75vh;
  }

  /* Dimensiones 125% */
  @media (max-width: 1467px) and (max-height: 668px) {
    width: 100%;
    height: 45vh;
  }
  @media (max-width: 899px) and (max-height: 668px) {
    width: 100%;
    height: 55vh;
  }
  @media (max-width: 715px) and (max-height: 668px) {
    width: 100%;
    height: 60vh;
  }
  @media (max-width: 599px) and (max-height: 668px) {
    width: 100%;
    height: 100vh;
  }

  /* Dimensiones 150% */
  @media (max-width: 1223px) and (max-height: 556px) {
    width: 100%;
    height: 55vh;
  }
  @media (max-width: 899px) and (max-height: 556px) {
    width: 100%;
    height: 70vh;
  }
  @media (max-width: 715px) and (max-height: 556px) {
    width: 100%;
    height: 80vh;
  }
  @media (max-width: 599px) and (max-height: 556px) {
    width: 100%;
    height: 130vh;
  }
`;
